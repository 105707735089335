import { RowSizes } from '@discngine/moosa-models';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';

import style from './SizeSelector.module.less';

export interface IRowSizeSelectorProps {
  rowSize: RowSizes;
  onChangeRowSize: (size: RowSizes) => void;
}

export const RowSizeSelector: React.FC<IRowSizeSelectorProps> = ({
  rowSize,
  onChangeRowSize,
}) => {
  const handleChange = (event: RadioChangeEvent) => onChangeRowSize(event.target.value);

  return (
    <div className={style.root}>
      <Radio.Group className={style.radioGroup} value={rowSize} onChange={handleChange}>
        <Radio className={style.radio} value={RowSizes.SMALL}>
          <div className={style.label}>small</div>
        </Radio>
        <Radio className={style.radio} value={RowSizes.MEDIUM}>
          <div className={style.label}>medium</div>
        </Radio>
        <Radio className={style.radio} value={RowSizes.BIG}>
          <div className={style.label}>wide</div>
        </Radio>
      </Radio.Group>
    </div>
  );
};
