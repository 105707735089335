import { IScoringTemplateData, IScoringTemplateSubRoot } from '@discngine/moosa-models';
import { createSelector } from '@reduxjs/toolkit';

export const selectScoringTemplate = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate;

export const selectScoringFunction = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate.func;

export const selectScoringOpenPropertyPanels = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate.openPropertyPanels;

export const selectScoringColumnOrder = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate.order;

export const selectScoringTemplateChanged = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate.changed;

export const selectScoringFunctionByColumn = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column]
  );

export const selectPropertyPanelOpen = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate,
    (scoringTemplate) => !!scoringTemplate.openPropertyPanels[column]
  );

export const selectScoringWeight = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].weight
  );

export const selectMissingValue = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].missingValue?.replaceWith
  );

export const selectMeasurementError = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].measurementError
  );

export const selectErrorsAvailable = (state: IScoringTemplateSubRoot) =>
  Object.values(state.scoringTemplate.func).some(
    (col) => col.measurementError.type !== 'none'
  );

export const selectMissingValueY = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].missingValue?.replaceWithY
  );

export const selectMissingValueReplacementColumn = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].missingValue?.replaceWithColumn
  );

export const selectFilterFrom = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].filter?.from
  );

export const selectFilterTo = (column: string) =>
  createSelector(
    (state: IScoringTemplateSubRoot) => state.scoringTemplate.func,
    (func) => func[column].filter?.to
  );

export const selectCustomFunctions = (state: IScoringTemplateSubRoot) =>
  state.scoringTemplate.customFunctions;

export const selectScoringTemplateData = createSelector(
  selectScoringColumnOrder,
  selectScoringFunction,
  (order, func): IScoringTemplateData => ({ order, func })
);
