import React from 'react';

import { MMPChartBaseMolecule } from '../../types';
import { IMMPChartBase } from '../MMPChartBase/MMPChartBase';

import { SectorSVG } from './SectorSVG';

export interface ISectorProps {
  molecule: MMPChartBaseMolecule;
  r0: number;
  r1: number;
  r2: number;
  index: number;
  stepAngle: number;
  color: string;
  opacity: number;
  getColorString: IMMPChartBase['getColorString'];
  onEnterSector: () => void;
  onLeaveSector: () => void;
}

export const Sector = React.memo(
  ({
    molecule,
    r2,
    r1,
    r0,
    index,
    stepAngle,
    color,
    opacity,
    getColorString,
    onEnterSector,
    onLeaveSector,
  }: ISectorProps) => {
    let smallRadius = 0;
    let bigRadius = 0;

    if (molecule.activity && Math.abs(molecule.activity) > 1) {
      console.error(
        `Pair.activity should be from -1 to 1, but it equals ${molecule.activity}`
      );
    }

    if (molecule.activity === null) {
      bigRadius = r2;
      smallRadius = r0;
    } else if (molecule.activity < 0) {
      bigRadius = r1;
      smallRadius = r1 - (r1 - r0) * Math.abs(molecule.activity);
    } else {
      bigRadius = r1 + (r2 - r1) * Math.abs(molecule.activity);
      smallRadius = r1;
    }

    if (Math.abs(bigRadius - smallRadius) < 10) {
      bigRadius = smallRadius + 5;
      smallRadius = smallRadius - 5;
    }

    return (
      <SectorSVG
        angle={stepAngle}
        bigRadius={bigRadius}
        fillColor={color}
        fillOpacity={opacity}
        order={index}
        smallRadius={smallRadius}
        onEnterSector={onEnterSector}
        onLeaveSector={onLeaveSector}
      />
    );
  }
);
