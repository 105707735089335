import { JSXElementConstructor } from 'react';

export type TagId = string;
export type TagsPanel = TagId[][];

export interface TagRendererProps {
  tagId: TagId;
  onRemove: () => void;
  className?: string;
}
export type TagRenderer = JSXElementConstructor<TagRendererProps>;

export type TagRowCol = { row: number; col: number };

export const TYPE_ROW = 'ROW';
export const TYPE_TAG = 'TAG';
export const NEW_LINE_ID = 'newLine';
