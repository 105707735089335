// spec for MOLFILE http://c4.cabrillo.edu/404/ctfile.pdf

export interface MolObjectAtom {
  id: number; // index
  x: number;
  y: number;
  z: number;
  elname: string;
  massDiff: number;
  chargeCode: number;
  valenceCode: number;
}

export interface MolObjectBond {
  id: number; // index
  from: number;
  to: number;
  bondType: number;
  bondStereo: number;
}

export interface MolObject {
  initialHeader: string;
  countLine: {
    atoms: number;
    bonds: number;
    chiral: number;
    mLines: number;
    version: string;
  };
  atoms: Array<MolObjectAtom>;
  bonds: Array<MolObjectBond>;
  header: {
    name: string;
    date: string;
    initials: string;
    software: string;
    comment: string;
  };
}

export class MolFileParser {
  static parseCountLine(line: string) {
    const parsed: MolObject['countLine'] = {
      atoms: parseInt(line.slice(0, 3), 10),
      bonds: parseInt(line.slice(3, 6), 10),
      // atom lists: 6-9,
      // obsolete:   9-12
      chiral: parseInt(line.slice(12, 15), 10),
      // stexts:    15-18
      // obsolete:  18-21
      // obsolete:  21-24
      // obsolete:  24-27
      // obsolete:  27-30
      mLines: parseInt(line.slice(30, 33), 10),
      version: line.slice(33, 39),
    };

    if (parsed.version !== ' V2000') {
      throw new Error("Unsupported molfile version '" + parsed.version + "'");
    }

    return parsed;
  }

  static parseAtomLine(index: number, line: string): MolObjectAtom {
    return {
      id: index,
      x: parseFloat(line.slice(0, 10)),
      y: parseFloat(line.slice(10, 20)),
      z: parseFloat(line.slice(20, 30)),
      // 31 is declared as a space
      elname: line.slice(31, 34).trim(),
      massDiff: parseInt(line.slice(34, 36), 10),
      chargeCode: parseInt(line.slice(36, 39), 10),
      // several obsolete fields
      valenceCode: parseInt(line.slice(48, 51), 10),
    };
  }

  static parseBondLine(index: number, line: string): MolObjectBond {
    return {
      id: index,
      from: parseInt(line.slice(0, 3), 10),
      to: parseInt(line.slice(3, 6), 10),
      bondType: parseInt(line.slice(6, 9), 10),
      bondStereo: parseInt(line.slice(9, 12), 10),
    };
    // several other fields, all obsolete
  }

  static addKeyValue(values: any, part: string): void {
    const key = parseInt(part.slice(0, 4), 10);

    values[key] = parseInt(part.slice(4, 8), 10);
  }

  static parseProperty(line: string) {
    if (/^M {2}END$/.test(line)) {
      return null;
    }

    const parsed: any = {};
    const values = {};
    let i;
    const slices = [];
    let offset = 0;

    parsed.mode = line.slice(3, 6);
    parsed[parsed.mode] = values;
    parsed.count = parseInt(line.slice(6, 9), 10);

    for (i = 0; i < parsed.count; i += 1) {
      offset = 9 + i * 8;
      slices.push(line.slice(offset, offset + 8));
    }

    slices.forEach(function (slice) {
      MolFileParser.addKeyValue(values, slice);
    });

    return parsed;
  }

  static makeDate(line: string) {
    return line;
    // try {
    //     const month = parseInt(line.slice(0, 2), 10);
    //     const day = Number(line.slice(2, 4));
    //     let year = parseInt(line.slice(4, 6), 10);
    //     const hour = Number(line.slice(6, 8));
    //     const minute = Number(line.slice(8, 10));
    //
    //     if (year < 80) {
    //         year += 2000;
    //     }
    //
    //     const result = new Date(year, month - 1, day, hour, minute);
    //
    //     return result
    // } catch (e) {
    //     return '';
    // }
  }

  static parseMolHeader(header: string) {
    let parsed: any = {};
    let lines = header.split('\n');

    parsed.name = lines[0];
    parsed.initials = lines[1].slice(0, 2);
    parsed.software = lines[1].slice(2, 10);
    parsed.date = MolFileParser.makeDate(lines[1].slice(10, 20));
    parsed.comment = lines[2];

    return parsed;
  }

  static parseDataItem(string: string) {
    const parsed: Record<string, unknown> = {};

    const match = /<([-A-Za-z_.]+)>/.exec(string);

    parsed.name = match ? match[1] : '';

    const from = string.indexOf('\n');
    const to = string.length;

    parsed.value = string.slice(from + 1, to);

    return parsed;
  }

  static prescanMol(mol: string) {
    const scan: any = { newlines: [] };
    let start = 0;
    let len = mol.length;
    let found = -1;
    let line: string;

    while (start < len) {
      found = mol.indexOf('\n', start);

      if (found === -1) {
        break;
      }
      scan.newlines.push(found);

      line = mol.slice(start, found);

      if (line.match(/^M {2}END/)) {
        scan.lastM = start;
      }

      if (line.match(/^M/) && !scan.firstM) {
        scan.firstM = start;
      }

      if (line.match(/^>/) && !scan.firstAngle) {
        scan.firstAngle = start;
      } else if (line.match(/^\$\$\$/)) {
        scan.sectionEnd = start;
      }

      start = found + 1;
    }

    return scan;
  }

  static getAtoms(mol: string, scan: any, parsed: any) {
    const begin = scan.newlines[3] + 1;
    const end = scan.newlines[3 + parsed.countLine.atoms];
    const atomLines = mol.slice(begin, end);

    return atomLines.split('\n');
  }

  static getBonds(mol: string, scan: any, parsed: any) {
    if (parsed.countLine.bonds === 0) {
      return [];
    }

    const startLine = 3 + parsed.countLine.atoms;
    const begin = scan.newlines[startLine] + 1;
    const end = scan.newlines[startLine + parsed.countLine.bonds];

    return mol.slice(begin, end).split('\n');
  }

  static getProperties(mol: string, scan: any) {
    const begin = scan.firstM;
    const end = scan.lastM;

    return mol.slice(begin, end).split('\n');
  }

  static getData(mol: string, scan: any) {
    const begin = scan.firstAngle;
    const end = scan.sectionEnd;

    return mol.slice(begin, end).split('\n\n');
  }

  static squashProperty(accum: any, property: any) {
    const mode = property.mode;
    let source: any;
    let target: any;

    if (!accum[mode]) {
      accum[mode] = property[mode];
    } else {
      target = accum[mode];
      source = property[mode];

      Object.keys(source).forEach(function (key) {
        target[key] = source[key];
      });
    }

    return accum;
  }

  static squashData(accum: any, data: any) {
    if (data.name) {
      accum[data.name] = data.value;
    }

    return accum;
  }

  static parseMol(mol: string) {
    const parsed: any = {};
    const scan = MolFileParser.prescanMol(mol);
    const headerEnd = scan.newlines[2] + 1;
    const countLineEnd = scan.newlines[3] + 1;

    parsed.header = MolFileParser.parseMolHeader(mol.slice(0, headerEnd));
    parsed.initialHeader = mol.slice(0, headerEnd);

    parsed.countLine = MolFileParser.parseCountLine(mol.slice(headerEnd, countLineEnd));

    parsed.atoms = MolFileParser.getAtoms(mol, scan, parsed).map(
      (atomLine: string, index: number) => {
        const atomId = index + 1; // 1-based array

        return MolFileParser.parseAtomLine(atomId, atomLine);
      }
    );

    parsed.bonds = MolFileParser.getBonds(mol, scan, parsed).map((bondLine, index) => {
      const bondId = index + 1; // 1-based array

      return MolFileParser.parseBondLine(bondId, bondLine);
    });

    // now read properties
    parsed.properties = MolFileParser.getProperties(mol, scan)
      .map(MolFileParser.parseProperty)
      .reduce(MolFileParser.squashProperty, {});

    parsed.data = MolFileParser.getData(mol, scan)
      .map(MolFileParser.parseDataItem)
      .reduce(MolFileParser.squashData, {});

    return parsed;
  }

  static trim = (molObject: MolObject, atoms: number[], bonds: number[]): MolObject => {
    const atomsSet = new Set(atoms);
    const bondsSet = new Set(bonds);

    molObject.atoms = molObject.atoms.filter((atom) => atomsSet.has(atom.id));
    molObject.countLine.atoms = molObject.atoms.length;

    molObject.bonds = molObject.bonds.filter((bond) => bondsSet.has(bond.id));
    molObject.countLine.bonds = molObject.bonds.length;

    const atomIdsMap = new Map<number, number>();

    molObject.atoms.forEach((atom, index) => {
      atomIdsMap.set(atom.id, index + 1);
    });

    const bondIdsMap = new Map<number, number>();

    molObject.bonds.forEach((bond, index) => {
      bondIdsMap.set(bond.id, index + 1);
    });

    molObject.atoms.forEach((atom) => {
      atom.id = atomIdsMap.get(atom.id) || -1;
    });

    molObject.bonds.forEach((bond) => {
      bond.id = bondIdsMap.get(bond.id) || -1;
      bond.from = atomIdsMap.get(bond.from) || -1;
      bond.to = atomIdsMap.get(bond.to) || -1;
    });

    return molObject;
  };

  static toHeaderLine = (initialHeader: string): string => {
    return initialHeader;
    // const headerLine =
    //     header.name + '\n' +
    //     header.initials +
    //     header.software +
    //     header.date + '\n' +
    //     header.comment;
    // return headerLine;
  };

  static toCountLine = (countLine: MolObject['countLine']): string => {
    const atomsCount = countLine.atoms.toString().padStart(3, ' ');
    const bondsCount = countLine.bonds.toString().padStart(3, ' ');
    const notImportant = '0'.padStart(3, ' ');
    const chiral = countLine.chiral.toString().padStart(3, ' ');
    const mLines = countLine.mLines.toString().padStart(3, ' ');
    const version = countLine.version.toString().padStart(6, ' ');

    return (
      atomsCount +
      bondsCount +
      notImportant +
      notImportant +
      chiral +
      notImportant +
      notImportant +
      notImportant +
      notImportant +
      notImportant +
      mLines +
      version
    );
  };

  static toAtomLine = (atom: MolObjectAtom): string => {
    const x = atom.x.toFixed(4).toString().padStart(10, ' ');
    const y = atom.y.toFixed(4).toString().padStart(10, ' ');
    // eslint-disable-next-line id-length
    const z = atom.z.toFixed(4).toString().padStart(10, ' ');
    const space = ' ';
    const elName = atom.elname.padEnd(3, ' ');
    const massDiff = atom.massDiff.toString().padStart(2, ' ');
    const chargeCode = atom.chargeCode.toString().padStart(3, ' ');
    const obsoleteZeros = [0, 0, 0, 0, 0, 0, 0, 0, 0]
      .map((x) => x.toString().padStart(3, ' '))
      .join('');
    const valenceCode = atom.valenceCode.toString().padStart(3, ' ');

    return (
      x + y + z + space + elName + massDiff + chargeCode + obsoleteZeros + valenceCode
    );
  };
  static toAtomsTable = (atoms: MolObject['atoms']): string => {
    let result = '';

    atoms.forEach((atom, index) => {
      result += MolFileParser.toAtomLine(atom);

      if (index + 1 !== atoms.length) {
        result += `\n`;
      }
    });

    return result;
  };

  static toBondLine = (bond: MolObjectBond): string => {
    const from = bond.from.toString().padStart(3, ' ');
    const to = bond.to.toString().padStart(3, ' ');
    const bondType = bond.bondType.toString().padStart(3, ' ');
    const bondStereo = bond.bondStereo.toString().padStart(3, ' ');

    return from + to + bondType + bondStereo;
  };
  static toBondTable = (bonds: MolObject['bonds']): string => {
    let result = '';

    bonds.forEach((bond) => {
      result += MolFileParser.toBondLine(bond) + '\n';
    });

    return result;
  };

  static toMOLFILE = (molObject: MolObject): string => {
    const headerLine = MolFileParser.toHeaderLine(molObject.initialHeader);
    const countLine = MolFileParser.toCountLine(molObject.countLine);

    const atomsTable = MolFileParser.toAtomsTable(molObject.atoms);
    const bondsTable = MolFileParser.toBondTable(molObject.bonds);

    return headerLine + countLine + '\n' + atomsTable + '\n' + bondsTable + 'M  END\n';
  };

  static removeRGroups = (molObject: MolObject): MolObject => {
    function fixBondsArray(bonds: Array<MolObjectBond>) {
      for (let i = 0; i < bonds.length; i++) {
        bonds[i].id = i + 1;
      }
    }

    function fixAtomsArray(molObject: MolObject) {
      const newAtomsMap = new Map<number, number>();

      for (let i = 0; i < molObject.atoms.length; i++) {
        const prevAtomId = molObject.atoms[i].id;
        const newAtomId = i + 1;

        newAtomsMap.set(prevAtomId, newAtomId);
      }

      molObject.atoms = molObject.atoms.map((atom) => {
        return {
          ...atom,
          id: newAtomsMap.get(atom.id) || -1,
        };
      });

      molObject.bonds = molObject.bonds.map((bond) => {
        return {
          ...bond,
          from: newAtomsMap.get(bond.from) || -1,
          to: newAtomsMap.get(bond.to) || -1,
        };
      });
    }

    function fixCountLine(numAtoms: number, numBonds: number, molObject: MolObject) {
      molObject.countLine.atoms = numAtoms;
      molObject.countLine.bonds = numBonds;
    }

    const result = molObject;
    const rGroupIDs = molObject.atoms
      .filter((atom) => ['R', '*'].includes(atom.elname)) // to understand this see spec for ctfile (link on the top)
      .map((atom) => atom.id)
      .sort((first, second) => {
        return second - first;
      });

    const prevAtomsNumber = molObject.atoms.length;
    const newAtomsNumber = prevAtomsNumber - rGroupIDs.length;

    let rGroupBondsNumber = 0;
    const prevBondsNumber = molObject.bonds.length;
    let newBondsNumber = prevBondsNumber;

    for (const atomIDtoDelete of rGroupIDs) {
      rGroupBondsNumber = result.bonds.filter(
        (bond) => bond.from === atomIDtoDelete || bond.to === atomIDtoDelete
      ).length;
      newBondsNumber = newBondsNumber - rGroupBondsNumber;

      result.bonds = result.bonds.filter(
        (bond) => bond.from !== atomIDtoDelete && bond.to !== atomIDtoDelete
      );

      fixBondsArray(result.bonds);
      result.atoms = result.atoms.filter((atom) => atom.id !== atomIDtoDelete);
    }
    fixAtomsArray(result);

    fixCountLine(newAtomsNumber, newBondsNumber, result);

    return result;
  };
}
