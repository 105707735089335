import { CoreSelector } from './CoreSelector/CoreSelector';
import { JobControlPanel } from './JobControlPanel/JobControlPanel';
import { RequestConfigEditor } from './RequestConfigEditor/RequestConfigEditor';
import { IMMPChartConfig } from './types';

export const MoosaMMPChartConfig = (props: IMMPChartConfig) => {
  return (
    <>
      <CoreSelector
        coreID={props.coreID}
        cores={props.cores}
        structure={props.structure}
        StructureEditor={props.StructureEditor}
        StructureRenderer={props.StructureRenderer}
        onChangeCore={props.onChangeCore}
        onSelectionChange={props.onSelectionChange}
      />
      {/*<ColorLegend />*/}
      <RequestConfigEditor
        config={props.config}
        maxCompounds={props.maxCompounds}
        onChangeConfig={props.onChangeConfig}
        onChangeMaxCompounds={props.onChangeMaxCompounds}
      />
      <JobControlPanel
        jobStatus={props.jobStatus}
        requestInProgress={props.requestInProgress}
        onStartJob={props.onStartJob}
        onStopJob={props.onStopJob}
      />
    </>
  );
};
