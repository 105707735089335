import { CellBackgroundMode } from '@discngine/moosa-models';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { FC, useCallback } from 'react';

import { ReactComponent as CellModeHeatMap } from '../../icons/cell-mode-heatmap.svg';
import { ReactComponent as CellModeHistogram } from '../../icons/cell-mode-histgram.svg';
import { ReactComponent as CellModeNone } from '../../icons/cell-mode-none.svg';

import style from './CellViewMode.module.less';

export interface ICellViewModeProps {
  backgroundMode: CellBackgroundMode;
  onChangeBackgroundMode: (mode: CellBackgroundMode) => void;
}

export const CellViewMode: FC<ICellViewModeProps> = ({
  backgroundMode,
  onChangeBackgroundMode,
}) => {
  const onChange = useCallback(
    (event: RadioChangeEvent) => {
      onChangeBackgroundMode(event.target.value as CellBackgroundMode);
    },
    [onChangeBackgroundMode]
  );

  return (
    <div className={style.root}>
      <Radio.Group
        className={style.radioGroup}
        value={backgroundMode}
        onChange={onChange}
      >
        <Radio className={style.radio} value={CellBackgroundMode.None}>
          <CellModeNone />
          <div className={style.label}>none</div>
        </Radio>
        <Radio className={style.radio} value={CellBackgroundMode.Histogram}>
          <CellModeHistogram />
          <div className={style.label}>histogram</div>
        </Radio>
        <Radio className={style.radio} value={CellBackgroundMode.Heatmap}>
          <CellModeHeatMap />
          <div className={style.label}>heatmap</div>
        </Radio>
      </Radio.Group>
    </div>
  );
};
