import { FC, useCallback } from 'react';

import styles from './ColorPickerElement.module.less';

type ColorPickerElementProps = {
  color: string;
  onColorSelect: (color: string) => void;
  isActive: boolean;
};
export const ColorPickerElement: FC<ColorPickerElementProps> =
  function ColorPickerElement({ color, onColorSelect, isActive }) {
    const handleClick = useCallback(() => onColorSelect(color), [onColorSelect, color]);

    return (
      <button
        className={styles.pickerElement}
        style={{ backgroundColor: color }}
        onClick={handleClick}
      >
        {isActive && <div className={styles.active}></div>}
      </button>
    );
  };
