import { IStructureRenderer } from '@discngine/moosa-models';
import React, { FC } from 'react';

import { MMPChartHoveredMolecule, MMPChartCentralMolecule } from '../../types';

import styles from './PopupMoleculeComparison.module.less';

export interface IPopupMoleculeComparison {
  centralMolecule: MMPChartCentralMolecule;
  hoveredMolecule: MMPChartHoveredMolecule;
  StructureRenderer: IStructureRenderer;
  showParameter?: boolean;
  noValues?: boolean;
}

export const PopupMoleculeComparison: FC<IPopupMoleculeComparison> = ({
  centralMolecule,
  hoveredMolecule,
  StructureRenderer,
  showParameter,
  noValues,
}: IPopupMoleculeComparison) => {
  const centralMoleculeCoreStructure = [
    { core: hoveredMolecule.core, color: 'transparent' },
  ];
  const coreSmiles = [{ core: hoveredMolecule.core, color: 'transparent' }];
  const alignStructures = [hoveredMolecule.core];

  return (
    <div className={styles.popupContainer}>
      {showParameter && (
        <div className={styles.parameter}>{hoveredMolecule.parameter}</div>
      )}
      <div className={styles.molecules}>
        <div className={styles.molecule}>
          <StructureRenderer
            alignSmiles={[]}
            coreSmiles={centralMoleculeCoreStructure}
            disableQueue={true}
            smiles={centralMolecule.structureMOLFILE}
          />
          {!noValues && (
            <div className={styles.value}>
              {(centralMolecule && !noValues && centralMolecule.initialValue) || 'N/A'}
            </div>
          )}
          <div className={styles.text}>Central molecule</div>
        </div>
        <div className={styles.molecule}>
          <StructureRenderer
            alignSmiles={alignStructures}
            coreSmiles={coreSmiles}
            smiles={hoveredMolecule.structure}
          />
          {!noValues && (
            <div className={styles.value} style={{ color: hoveredMolecule.color }}>
              {hoveredMolecule.core && hoveredMolecule.value
                ? hoveredMolecule.value
                : 'N/A'}
            </div>
          )}
          <div className={styles.text}>Hovered molecule</div>
        </div>
      </div>
    </div>
  );
};
