import { SAR_IMAGE_SIZE_OPTIONS } from '@discngine/moosa-common';
import { EMoosaSarConfigImageSize } from '@discngine/moosa-models';
import { Radio, RadioChangeEvent } from 'antd';
import React from 'react';

import styles from '../MoosaSarTableConfig.module.less';

interface IMoosaSarConfigImageSizeProps {
  currentSize: EMoosaSarConfigImageSize;
  onChange: (value: EMoosaSarConfigImageSize) => void;
}

export const MoosaSarConfigImageSize: React.FC<IMoosaSarConfigImageSizeProps> = ({
  currentSize,
  onChange,
}) => {
  const changeHandle = ({ target: { value } }: RadioChangeEvent) => {
    onChange(value);
  };

  return (
    <div className={styles.imageSize}>
      <Radio.Group
        options={Object.values(SAR_IMAGE_SIZE_OPTIONS)}
        value={currentSize}
        onChange={changeHandle}
      />
    </div>
  );
};
