import { IStructureRenderer } from '@discngine/moosa-models';
import React, { useMemo } from 'react';

import { MMPChartBaseMolecule } from '../../types';
import styles from '../MoosaMMPChartMultiple.module.less';

type IListMolecule = {
  index: number;
  hoveredSectorMoleculeId: string;
  molecule: MMPChartBaseMolecule;
  StructureRenderer: IStructureRenderer;
  onHoverMolecule: Function;
};

export const ListMolecule = React.memo(
  ({
    index,
    hoveredSectorMoleculeId,
    molecule,
    StructureRenderer,
    onHoverMolecule,
  }: IListMolecule) => {
    const { coreSmiles, alignStructures } = useMemo(() => {
      return {
        alignStructures: [molecule.core],
        coreSmiles: [{ core: molecule.core, color: 'transparent' }],
      };
    }, [molecule.core]);

    if (!StructureRenderer) {
      return null;
    }

    return (
      <div
        key={index}
        className={`${styles.molecule} ${
          molecule.id === hoveredSectorMoleculeId && styles.sectorHovered
        }`}
        onMouseEnter={() => onHoverMolecule(molecule.id)}
        onMouseLeave={() => onHoverMolecule('')}
      >
        <StructureRenderer
          alignSmiles={alignStructures}
          coreSmiles={coreSmiles}
          disableQueue={true}
          smiles={molecule.structure}
        />
      </div>
    );
  }
);
