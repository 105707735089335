import { IScoringTemplate } from '@discngine/moosa-models';
import { IColumnMetaInfo } from '@discngine/moosa-store/tableInfo';
import React from 'react';

import { MoosaTemplateSelector } from './components/TemplateSelector';

export type MoosaTemplateSelectorWidgetProps = {
  template?: IScoringTemplate;
  datasetColumns: Record<string, IColumnMetaInfo>;
  onSelectTemplate: (template: IScoringTemplate) => Promise<void>;
};
export const MoosaTemplateSelectorWidget: React.FC<MoosaTemplateSelectorWidgetProps> =
  React.memo(({ template, datasetColumns, onSelectTemplate }) => {
    return (
      <MoosaTemplateSelector
        datasetColumns={datasetColumns}
        template={template}
        onSelectTemplate={onSelectTemplate}
      />
    );
  });
