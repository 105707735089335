import { IChartsSubRoot } from './IChartsState';

export const selectIsChartShown = (state: IChartsSubRoot) => state.chartsData.isShown;
export const selectIsChartLoading = (state: IChartsSubRoot) => state.chartsData.isLoading;
export const selectIsFirstChartOpen = (state: IChartsSubRoot) =>
  state.chartsData.isFirstOpen;
export const selectCurrentChartType = (state: IChartsSubRoot) =>
  state.chartsData.currentChartType;

export const selectSmartChartState = (state: IChartsSubRoot) =>
  state.chartsData.smartChartState;

export const selectScatterPlotState = (state: IChartsSubRoot) =>
  state.chartsData.scatterPlotState;

export const selectFullDataset = (state: IChartsSubRoot) => state.chartsData.fullDataset;
