import {
  showComparisonTemplateSelector,
  hideComparisonTemplateSelector,
} from '@discngine/moosa-store/tableConfig';
import {
  selectIsTemplatesComparisonMode,
  setIsTemplatesComparisonMode,
} from '@discngine/moosa-store/tableInfo';
import { Switch } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataViewMode } from '../MultiDimensionsAnalysis';

import styles from './CompareTemplateModeSwitch.module.less';

export const CompareTemplateModeSwitch: React.FC<{ dataViewMode: DataViewMode }> = ({
  dataViewMode,
}) => {
  const isComparisonMode = useSelector(selectIsTemplatesComparisonMode);
  const dispatch = useDispatch();

  const toggleComparisonMode = useCallback(() => {
    dispatch(setIsTemplatesComparisonMode(!isComparisonMode));

    if (isComparisonMode) {
      dispatch(hideComparisonTemplateSelector());
    } else {
      dispatch(showComparisonTemplateSelector());
    }
  }, [dispatch, isComparisonMode]);

  const disabled = dataViewMode !== DataViewMode.TABLE;
  const disabledReason = 'Available only in Table mode';

  useEffect(() => {
    if (disabled) {
      dispatch(setIsTemplatesComparisonMode(false));
    }
  }, [dispatch, disabled]);

  return (
    <div className={styles.compareSwitch}>
      <Switch
        checked={isComparisonMode}
        className={styles.controlElement}
        disabled={disabled}
        size="small"
        title={disabled ? disabledReason : undefined}
        onChange={toggleComparisonMode}
      />
      Compare template
    </div>
  );
};
