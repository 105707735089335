import { Point } from '@visx/point';
import { TextProps } from '@visx/text';

export const getCircleStep = (size: number) => (Math.PI * 2) / size;

interface GenPolygonPointsParams {
  data: number[];
  scale: (value: number) => number;
  startPoint?: number;
}

export const genPolygonPoints = (args: GenPolygonPointsParams) => {
  const { data, scale, startPoint = 0 } = args;

  const step = getCircleStep(data.length);

  const points: { x: number; y: number }[] = new Array(data.length).fill({ x: 0, y: 0 });

  const pointString: string = data.reduce((res, item, idx) => {
    const xVal = scale(item) * Math.sin(idx * step + startPoint);
    const yVal = scale(item) * Math.cos(idx * step + startPoint);

    points[idx] = { x: xVal, y: yVal };

    return res + `${xVal},${yVal} `;
  }, '');

  return { points, pointString };
};

interface GenPointsParams {
  size: number;
  radius: number;
  startPoint?: number;
}

export const genPoints = ({ size, startPoint = 0, radius }: GenPointsParams) => {
  const step = getCircleStep(size);

  return [...new Array(size)].map((_, i) => {
    return {
      x: radius * Math.sin(i * step + startPoint),
      y: radius * Math.cos(i * step + startPoint),
    };
  });
};

export const zeroPoint = new Point({ x: 0, y: 0 });

export const genAngles = (size: number, degrees: number = 360) =>
  [...new Array(size + 1)].map((_, i) => ({
    angle: i * (degrees / size) + (size % 2 === 0 ? 0 : degrees / size / 2),
  }));

let canvas: HTMLCanvasElement | null = null;

export const getTextWidth = (text: string, font = 'Arial') => {
  // re-use canvas object for better performance
  if (!canvas) {
    canvas = document.createElement('canvas');
  }

  const context = canvas.getContext('2d');

  if (!context) {
    return 0;
  }

  context.font = font;
  const metrics = context.measureText(text);

  return metrics.width;
};

const LABEL_PADDING = 10;

interface GetLabelsParams {
  labels: { label: string; props?: TextProps }[];

  startingAngle?: number;
  radius: number;
}

export const getLabels = (params: GetLabelsParams) => {
  const { labels, startingAngle = 0, radius } = params;

  const step = getCircleStep(labels.length);

  return labels.map((item, index) => {
    const angle = index * step + startingAngle;

    const y = radius * Math.cos(angle);
    const width = Math.max(...item.label.split(' ').map((word) => getTextWidth(word)));

    return {
      x: radius * Math.sin(angle),
      y: y > 0 ? y + LABEL_PADDING : y - LABEL_PADDING,
      width,
      ...item,
    };
  });
};
