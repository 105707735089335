import { useIsOverflow } from '@discngine/moosa-common';
import { Tooltip } from 'antd';
import React, { FC } from 'react';

import styles from '../MoosaSarTable.module.less';

import { ItemProps } from './MoosaSarTableItem';

export type IItemValueProps = {
  value: string;
};

export const ItemValue: FC<IItemValueProps> = ({ value }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(ref);

  return (
    <div ref={ref} className={styles.value}>
      {isOverflow ? (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ) : (
        value
      )}
    </div>
  );
};

type MoosaSarTableItemValuesProps = {
  columns: ItemProps['columns'];
  itemData: ItemProps['itemData'];
  isColorizingTextMode: ItemProps['isColorizingTextMode'];
  handleColumnMouseLeave: () => void;
  handleColumnMouseEnter: (columnId: string) => void;
};

export const MoosaSarTableItemValues = ({
  columns,
  itemData,
  isColorizingTextMode,
  handleColumnMouseEnter,
  handleColumnMouseLeave,
}: MoosaSarTableItemValuesProps) => {
  const colorizingProperty = isColorizingTextMode ? 'color' : 'background';
  let i = -1;

  return (
    <div>
      {columns.map((labels, lineIndex) => {
        return (
          <div key={lineIndex} className={styles.line}>
            {labels.map((label) => {
              i++;

              const value = itemData.data[i]?.value ?? '';

              return (
                <div
                  key={label.columnId}
                  className={styles.itemValueWrap}
                  style={{
                    [`${colorizingProperty}`]: itemData.data[i].color ?? '',
                  }}
                  onBlur={handleColumnMouseLeave}
                  onMouseEnter={() => {
                    handleColumnMouseEnter(label.columnId);
                  }}
                  onMouseLeave={handleColumnMouseLeave}
                >
                  <ItemValue value={value.toString()} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
