import { IColumnLabelMap, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { Tooltip } from 'antd';
import React, { FC, useCallback } from 'react';

import { ISarLabelCallbacks } from '../../MoosaSarTablePanelProps';
import styles from '../MoosaSarTable.module.less';
interface ISarLabelsProps extends ISarLabelCallbacks {
  columns: IMoosaSarConfigTag[][];
  columnLabelMap?: IColumnLabelMap;
}

export const MoosaSarTableLabels: FC<ISarLabelsProps> = ({
  columns,
  columnLabelMap,
  onLabelClick,
  onLabelHover,
}) => {
  const handleTagClick = useCallback(
    (tag: IMoosaSarConfigTag) => {
      if (onLabelClick) {
        const label = columnLabelMap?.[tag.columnId]?.label || tag.columnId;

        onLabelClick(label, tag.columnId);
      }
    },
    [columnLabelMap, onLabelClick]
  );

  const handleTagHover = useCallback(
    (tag: IMoosaSarConfigTag) => {
      if (onLabelHover) {
        const label = columnLabelMap?.[tag.columnId]?.label || tag.columnId;

        onLabelHover(label, tag.columnId);
      }
    },
    [columnLabelMap, onLabelHover]
  );

  return (
    <div
      className={`${styles.labels} ${columns.length === 0 ? styles.withoutLabels : ''}`}
    >
      {columns.map((labels, index) => {
        return (
          <div key={index} className={styles.line}>
            {labels.map((tag, index2) => {
              const label = columnLabelMap?.[tag.columnId]?.label || tag.columnId;
              const tagNameTooltip =
                tag.columnId === label ? tag.columnId : `${tag.columnId} / ${label}`;

              return (
                <Tooltip key={index2} title={tagNameTooltip}>
                  <div
                    className={styles.label}
                    onClick={() => handleTagClick(tag)}
                    onMouseEnter={() => handleTagHover(tag)}
                  >
                    {label}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
