import { Popover, Checkbox } from 'antd';
import React from 'react';

import styles from './MultiDimensionsAnalysis.module.less';

interface Props {
  showErrors: boolean;
  showErrosAvailable: boolean;
  showCheckbox: boolean;
  handleShowErrorsChange: (evt: { target: { checked: boolean } }) => void;
}
export const ShowErrorsCheckbox: React.FC<Props> = ({
  showCheckbox,
  handleShowErrorsChange,
  showErrosAvailable,
  showErrors,
}) => {
  const className = showCheckbox ? '' : styles.hidden;

  if (showErrosAvailable)
    return (
      <Checkbox
        checked={showErrors}
        className={className}
        onChange={handleShowErrorsChange}
      >
        Show Errors
      </Checkbox>
    );

  return (
    <Popover
      content="Current template doesn't have any columns with errors mode set"
      placement="bottom"
    >
      <Checkbox className={className} disabled>
        Show Errors
      </Checkbox>
    </Popover>
  );
};
