import {
  IStructureEditor,
  IStructureEditorAPI,
  IStructureRenderer,
  StructureType,
} from '@discngine/moosa-models';
import { Input, Modal, Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import { Core } from '../types';

import { CoreItem } from './CoreItem/CoreItem';
import styles from './CoreSelector.module.less';

export interface ICoreSelectorProps {
  coreID?: string;
  onChangeCore: (id?: string) => void;
  cores: Array<Core>;
  StructureEditor: IStructureEditor;
  structure: string;
  onSelectionChange: (
    selection: ReturnType<IStructureEditorAPI['getSelection']>,
    molFile: string
  ) => void;
  StructureRenderer: IStructureRenderer;
}

const DEBOUNCE_DELAY_MS = 500;

const debouncedOnChange = debounce(
  (value: string, cores: Core[], onChangeCore: (id?: string) => void, isCid: boolean) => {
    let compoundId = value;

    if (isCid) {
      compoundId = cores.find((core) => core.cid === value)?.id || '';
    }

    onChangeCore(compoundId);
  },
  DEBOUNCE_DELAY_MS
);

export const CoreSelector = ({
  coreID,
  onChangeCore,
  cores,
  StructureEditor,
  structure,
  StructureRenderer,
  onSelectionChange,
}: ICoreSelectorProps) => {
  const [isEditorVisible, setEditorVisibility] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editorApi, setEditorApi] = useState<IStructureEditorAPI>();

  const handlePaste = useCallback(
    async (event: any) => {
      event.persist();
      // @ts-ignore
      const clipboardContents = await navigator.clipboard.read();

      if (clipboardContents[0].types.length <= 2) {
        for (const item of clipboardContents) {
          if (item.types.includes('text/plain')) {
            const blob = await item.getType('text/plain');
            const cid = await blob.text();

            const compoundId = cores.find((core) => core.cid === cid)?.id || '';

            if (!compoundId) {
              return;
            }

            setInputValue(cid);
            onChangeCore(compoundId);

            return;
          }
        }
      }

      for (const item of clipboardContents) {
        let blob;

        if (item.types.includes('text/html')) {
          blob = await item.getType('text/html');
          const text = await blob.text();
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'text/html');

          if (!doc) {
            return;
          }

          const el = doc.querySelector('[data-compound-id]');

          if (!el) {
            return;
          }

          const compoundId = el.getAttribute('data-compound-id');

          if (!compoundId) {
            return;
          }

          event.preventDefault();
          debouncedOnChange(compoundId, cores, onChangeCore, false);
          onChangeCore(compoundId);
          const cid = cores.find((core) => core.id === compoundId)?.cid || '';

          setInputValue(cid);
        }
      }
    },
    [onChangeCore, cores]
  );

  const handleEditorCancel = useCallback(() => {
    setEditorVisibility(false);
  }, []);

  const handleShowEditor = useCallback(() => {
    setEditorVisibility(true);
  }, []);

  const handleEditorOk = useCallback(async () => {
    if (editorApi) {
      const selection = editorApi.getSelection();

      const molFile = await editorApi.getStructure(StructureType.MOLFILE);

      if (molFile) {
        onSelectionChange(selection, molFile);
      }
    }
    setEditorVisibility(false);
  }, [editorApi, onSelectionChange]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const compoundId = cores.find((core) => core.cid === inputValue)?.id || '';

        onChangeCore(compoundId);
      }
    },
    [cores, inputValue, onChangeCore]
  );

  const onChange = useCallback(
    (event) => {
      setInputValue(event.currentTarget.value);
      debouncedOnChange(event.currentTarget.value, cores, onChangeCore, true);
    },
    [cores, onChangeCore]
  );

  useEffect(() => {
    const core = cores.find((core) => core.id === coreID);
    const cid = core ? core.cid ?? `No CID [#${core.index}]` : '';

    setInputValue(cid);
  }, [coreID, cores, setInputValue]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.title}>The molecule to compare with</h3>
        </div>
        <div className={styles.editorPreview}>
          {structure && (
            <CoreItem
              structure={structure}
              StructureRenderer={StructureRenderer}
              onShowEditor={handleShowEditor}
            />
          )}
        </div>

        <Space direction="vertical" style={{ width: '100%' }}>
          <Input
            placeholder="INSERT CID HERE"
            value={inputValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onPaste={handlePaste}
          />
          <div style={{ textAlign: 'center' }}>OR</div>
          <Select
            options={cores.map(({ id, cid, index }) => ({
              value: id,
              label: cid ? cid : `No CID [#${index}]`,
            }))}
            placeholder="SELECT CORE FROM LIST"
            value={null}
            onChange={onChangeCore}
          />
        </Space>
      </div>

      <Modal
        open={isEditorVisible}
        title="Structure Editor"
        width={1280}
        onCancel={handleEditorCancel}
        onOk={handleEditorOk}
      >
        <div style={{ overflow: 'hidden' }}>
          <StructureEditor
            disableEditing={true}
            structure={structure ?? ''}
            onInit={setEditorApi}
          />
        </div>
      </Modal>
    </>
  );
};
