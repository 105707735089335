import { ParentSize } from '@visx/responsive';
import { FC } from 'react';

type Props<T> = {
  component: FC<Omit<T, 'component'> & { width: number; height: number }>;
} & T;

export const ResponsiveContainer = <T extends { [K in keyof T]: T[K] }>(
  props: Props<T>
) => {
  const { component: Component, ...rest } = props;

  return (
    <ParentSize>
      {({ width, height }) => <Component height={height} width={width} {...rest} />}
    </ParentSize>
  );
};
