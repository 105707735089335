import {
  EMoosaSarConfigImageSize,
  IColumnLabelMap,
  IMoosaSarConfigTag,
} from '@discngine/moosa-models';
import React, { FC, useRef, useMemo, JSXElementConstructor } from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

import {
  ISarItemCallbacks,
  ISarTableItem,
  ISarLabelCallbacks,
} from '../../MoosaSarTablePanelProps';
import styles from '../MoosaSarTable.module.less';

import { CustomizedCellRendererProps } from './CellRenderer';
import { MoosaSarTableItem } from './MoosaSarTableItem';
import { MoosaSarTableLabels } from './MoosaSarTableLabels';

interface IMoosaSarTableRowProps extends ISarItemCallbacks, ISarLabelCallbacks {
  columns: IMoosaSarConfigTag[][];
  columnLabelMap?: IColumnLabelMap;
  coreStructure: string[];
  onPinChange: (rowId: string) => void;
  imageSize: EMoosaSarConfigImageSize;
  rowItems: ISarTableItem[];
  rowIndex: number;
  isColorizingTextMode: boolean;
  cellRenderer: JSXElementConstructor<CustomizedCellRendererProps>;
  itemsPerRow: number;
}

interface RowProps extends ISarItemCallbacks {
  provided?: DroppableProvided;
  columns: IMoosaSarConfigTag[][];
  coreStructure: string[];
  onPinChange: (rowId: string) => void;
  rowItems: ISarTableItem[];
  imageSize: EMoosaSarConfigImageSize;
  width: string;
  isColorizingTextMode: boolean;
  cellRenderer: JSXElementConstructor<CustomizedCellRendererProps>;
}

const Row: FC<RowProps> = ({
  provided,
  columns,
  rowItems,
  coreStructure,
  imageSize,
  onPinChange,
  width,
  isColorizingTextMode,
  cellRenderer,
  onMoleculeHover,
  onMoleculeClick,
  onCellHover,
  onCellClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={provided ? provided.innerRef : ref}
      className={styles.row}
      {...(provided?.droppableProps ?? {})}
      style={{ width: width }}
    >
      {rowItems.map((item, index) => {
        return (
          <div key={item.rowId} style={{ width: `${100 / rowItems.length}%` }}>
            <MoosaSarTableItem
              key={item.rowId}
              cellRenderer={cellRenderer}
              columns={columns}
              coreStructure={coreStructure}
              imageSize={imageSize}
              isColorizingTextMode={isColorizingTextMode}
              itemData={item}
              itemIndex={index}
              onCellClick={onCellClick}
              onCellHover={onCellHover}
              onMoleculeClick={onMoleculeClick}
              onMoleculeHover={onMoleculeHover}
              onPinChange={onPinChange}
            />
          </div>
        );
      })}

      {provided?.placeholder}
    </div>
  );
};

export const MoosaSarTableRow: FC<IMoosaSarTableRowProps> = (props) => {
  const pinned = useMemo(() => {
    return props.rowItems.filter((item) => item && item.isPinned);
  }, [props.rowItems]);

  const notPinned = useMemo(() => {
    return props.rowItems.filter((item) => item && !item.isPinned);
  }, [props.rowItems]);

  return (
    <div className={styles.rowWrapper}>
      <MoosaSarTableLabels
        columnLabelMap={props.columnLabelMap}
        columns={props.columns}
        onLabelClick={props.onLabelClick}
        onLabelHover={props.onLabelHover}
      />
      <div className={styles.rowData}>
        {pinned.length > 0 && (
          <Droppable direction="horizontal" droppableId={String(props.rowIndex)}>
            {(provided) => {
              return (
                <Row
                  cellRenderer={props.cellRenderer}
                  columns={props.columns}
                  coreStructure={props.coreStructure}
                  imageSize={props.imageSize}
                  isColorizingTextMode={props.isColorizingTextMode}
                  provided={provided}
                  rowItems={pinned}
                  width={(pinned.length / props.itemsPerRow) * 100 + '%'}
                  onCellClick={props.onCellClick}
                  onCellHover={props.onCellHover}
                  onMoleculeClick={props.onMoleculeClick}
                  onMoleculeHover={props.onMoleculeHover}
                  onPinChange={props.onPinChange}
                />
              );
            }}
          </Droppable>
        )}
        {notPinned.length > 0 && (
          <Row
            cellRenderer={props.cellRenderer}
            columns={props.columns}
            coreStructure={props.coreStructure}
            imageSize={props.imageSize}
            isColorizingTextMode={props.isColorizingTextMode}
            rowItems={notPinned}
            width={(notPinned.length / props.itemsPerRow) * 100 + '%'}
            onCellClick={props.onCellClick}
            onCellHover={props.onCellHover}
            onMoleculeClick={props.onMoleculeClick}
            onMoleculeHover={props.onMoleculeHover}
            onPinChange={props.onPinChange}
          />
        )}
      </div>
    </div>
  );
};
