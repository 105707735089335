import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  IStructureRenderer,
  IMoosaMMPConfigTag,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { Col, Row, Button } from 'antd';
import React, { FC, useState, useRef, useMemo } from 'react';

import { PopupMoleculeComparison } from '../MoosaMMPChart/PopupMoleculeComparison/PopupMoleculeComparison';
import { Sorting, IGlobalSorting } from '../MoosaMMPChartConfig';
import { MMPChartMultipleMolecules, MMPChartHoveredMolecule } from '../types';

import { ChartMolecule } from './ChartMolecule/ChartMolecule';
import { ListMolecule } from './ListMolecule/ListMolecule';
import styles from './MoosaMMPChartMultiple.module.less';

export interface IMoosaMMPChartMultiple {
  StructureRenderer: IStructureRenderer;
  multipleMolecules: Record<string, MMPChartMultipleMolecules>;
  sortingM: Record<string, Sorting>;
  selectedColumns: IMoosaMMPConfigTag[][];
  globalSorting: IGlobalSorting;
  columnLabelMap: IColumnLabelMap;
  invertAxes?: boolean;
  getColorString: (affection: number) => string;
  onChangeGlobalSortingOrderM: (order: string[]) => void;
  onChangeSortingM: (parameterId: string, sorting: Sorting) => void;
}

export const MoosaMMPChartMultiple: FC<IMoosaMMPChartMultiple> = ({
  StructureRenderer,
  multipleMolecules,
  sortingM,
  selectedColumns,
  globalSorting,
  columnLabelMap,
  invertAxes,
  getColorString,
  onChangeSortingM,
}) => {
  const [hoveredMoleculeId, setHoveredMoleculeId] = useState<string>('');
  const [hoveredSectorMolecule, setHoveredSectorMolecule] =
    useState<MMPChartHoveredMolecule>({
      id: '',
      core: '',
      structure: '',
      value: null,
      parameter: '',
      color: '',
    });

  function handleMoleculeHover(id: string) {
    setHoveredMoleculeId(id);
  }

  function handleSectorHover(hoveredMolecule: MMPChartHoveredMolecule) {
    setHoveredSectorMolecule(hoveredMolecule);
    setHoveredMoleculeId(hoveredMolecule.id);
  }

  // RENDER MMP CHARTS FOR EACH PARAMETER
  const charts = selectedColumns.map((column, index) => {
    return (
      <ChartMolecule
        key={index}
        centralMolecule={multipleMolecules[column[0].columnId].centralMolecule}
        columnLabelMap={columnLabelMap}
        getColorString={getColorString}
        globalSorting={globalSorting}
        hoveredMoleculeId={hoveredMoleculeId}
        invertAxes={invertAxes}
        numberOfElements={multipleMolecules[column[0].columnId].numberOfElements}
        parameter={column[0].columnId}
        radialMolecules={multipleMolecules[column[0].columnId].radialMolecules}
        sorting={sortingM[column[0].columnId]}
        StructureRenderer={StructureRenderer}
        onChangeSortingM={onChangeSortingM}
        onHoverSector={handleSectorHover}
      />
    );
  });

  // RENDER THE RADIAL MOLECULES IN A LIST
  const orderListBy = useMemo(() => {
    return globalSorting.mainParameter
      ? globalSorting.mainParameter
      : Object.keys(multipleMolecules).length > 0
      ? Object.keys(multipleMolecules.radialMolecules)[0]
      : '';
  }, [globalSorting.mainParameter, multipleMolecules]);

  let list = useMemo(() => {
    if (Object.keys(multipleMolecules).length) {
      return multipleMolecules[orderListBy].radialMolecules.map((molecule, index) => {
        return (
          <ListMolecule
            key={index}
            hoveredSectorMoleculeId={hoveredSectorMolecule.id}
            index={index}
            molecule={molecule}
            StructureRenderer={StructureRenderer}
            onHoverMolecule={handleMoleculeHover}
          />
        );
      });
    }

    return [];
  }, [StructureRenderer, hoveredSectorMolecule.id, multipleMolecules, orderListBy]);

  const pixelStep = 200;
  const startingTopOffset = 40;
  const buttonHeight = 40;
  const moleculeListRef = useRef<HTMLDivElement | null>(null);
  const moleculeContainerRef = useRef<HTMLDivElement>(null);
  const [disableUp, setDisableUp] = useState<boolean>(true);
  const [disableDown, setDisableDown] = useState<boolean>(false);

  function disableSliderButton(direction: string): boolean {
    if (
      moleculeContainerRef.current &&
      direction === 'up' &&
      moleculeContainerRef.current.offsetTop === startingTopOffset
    )
      return true;

    if (
      moleculeContainerRef.current &&
      moleculeListRef.current &&
      direction === 'down' &&
      (Math.abs(moleculeContainerRef.current.offsetTop) >
        Math.abs(
          moleculeContainerRef.current.offsetHeight - moleculeListRef.current.offsetHeight
        ) ||
        moleculeListRef.current.offsetHeight >= moleculeContainerRef.current.offsetHeight)
    )
      return true;

    return false;
  }

  function step(direction: string): void {
    if (moleculeContainerRef.current && direction === 'up')
      moleculeContainerRef.current.style.top =
        moleculeContainerRef.current.offsetTop + pixelStep > startingTopOffset
          ? '40px'
          : moleculeContainerRef.current.offsetTop + pixelStep + 'px';

    if (moleculeListRef.current && moleculeContainerRef.current && direction === 'down')
      moleculeContainerRef.current.style.top =
        Math.abs(moleculeContainerRef.current.offsetTop - pixelStep) >
        Math.abs(
          moleculeContainerRef.current.offsetHeight - moleculeListRef.current.offsetHeight
        )
          ? '-' +
            (moleculeContainerRef.current.offsetHeight -
              moleculeListRef.current.offsetHeight +
              buttonHeight) +
            'px'
          : moleculeContainerRef.current.offsetTop - pixelStep + 'px';

    // wait for css transition to finish before checking disabled states
    // so 'offsetTop' property on ref has updated accordingly to styles.top
    // refactoring: consider a react hook to solve this timing issue
    setTimeout(() => {
      setDisableUp(disableSliderButton('up'));
      setDisableDown(disableSliderButton('down'));
    }, 150);
  }

  return (
    <>
      <div className={styles.moleculeCharts}>
        <Row>
          <Col span={24}>
            <div className={styles.grid}>{charts}</div>
          </Col>
        </Row>
      </div>
      <div ref={moleculeListRef} className={styles.moleculeList}>
        <Button
          className={styles.buttonTop}
          disabled={disableUp}
          icon={<CaretUpOutlined />}
          size="large"
          onClick={() => step('up')}
        ></Button>
        <div ref={moleculeContainerRef} className={styles.moleculesContainer}>
          {list}
        </div>
        <Button
          className={styles.buttonBottom}
          disabled={disableDown}
          icon={<CaretDownOutlined />}
          size="large"
          onClick={() => step('down')}
        ></Button>
        {hoveredSectorMolecule.parameter && orderListBy && (
          <div className={styles.moleculeTop}>
            <PopupMoleculeComparison
              centralMolecule={
                multipleMolecules[hoveredSectorMolecule.parameter].centralMolecule
              }
              hoveredMolecule={hoveredSectorMolecule}
              showParameter
              StructureRenderer={StructureRenderer}
            />
          </div>
        )}
      </div>
    </>
  );
};
