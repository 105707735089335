import { JobStatus } from '@discngine/moosa-models';

import { ISarRadiantChartSubRoot } from './ISarRadiantChartState';

export const selectParameter = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.parameter;

export const selectMMPColumns = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.columns;

export const selectPairs = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.pairs;

export const selectRequestConfig = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.requestConfig;

export const selectCoreID = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.coreID;

export const selectRequestInProgress = (state: ISarRadiantChartSubRoot) => {
  switch (state.sarRadiantChart.jobStatus.status) {
    case JobStatus.Failed:
    case JobStatus.Finished:
    case JobStatus.Deleted:
    case JobStatus.NotStarted:
      return false;
    case JobStatus.Started:
      return true;
    default:
      return false;
  }
};

export const selectJobStatus = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.jobStatus;

export const selectMaxCompounds = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.maxCompounds;

export const selectSorting = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.sorting;

export const selectAtomsSelection = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.selection;

export const selectMolFile = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.molFile;

export const selectScoredDataMode = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.isScoredDataMode;

export const selectInvertAxes = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.invertAxes;

export const selectShowRadialMolecules = (state: ISarRadiantChartSubRoot) =>
  state.sarRadiantChart.showRadialMolecules;
