import { IJobStatus } from '@discngine/moosa-models';
import { Button } from 'antd';
import React from 'react';

import styles from './JobControlPanel.module.less';

export interface IJopControlPanelProps {
  onStartJob: () => void;
  onStopJob: (jobID: string) => void;
  jobStatus: IJobStatus;
  requestInProgress: boolean;
}

export const JobControlPanel = ({
  jobStatus,
  requestInProgress,
  onStartJob,
  onStopJob,
}: IJopControlPanelProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.firstRow}>Job info:</div>
      <div className={styles.fieldName}>id</div>
      <div className={styles.fieldValue}>{jobStatus.id}</div>
      <div className={styles.fieldName}>status</div>
      <div className={styles.fieldValue}>{jobStatus.status}</div>
      {jobStatus.info && (
        <>
          <div className={styles.fieldName}>description</div>
          <div className={styles.fieldValue}>{jobStatus.info}</div>
        </>
      )}
      <div className={styles.buttonsGroup}>
        <Button disabled={requestInProgress} onClick={onStartJob}>
          Calculate
        </Button>
        <Button disabled={!requestInProgress} onClick={() => onStopJob(jobStatus.id)}>
          Terminate
        </Button>
      </div>
    </div>
  );
};
