import { DatasetRowId } from '../../serverModels/IDatasetModel';

import { ColumnId } from './datasetCommon';
import { IScore, IScoreMap } from './scores';

/**
 *
 * @returns
 *  value in [0, 1],
 *  or null - not calculated due to missing values,
 *  or undefined - yet not calculated
 */
export function getSubstanceScoreValue(
  scores: IScoreMap,
  rowId: DatasetRowId
): number | null | undefined {
  return scores[rowId]?.value;
}

/**
 * Factor = weight * desirability_function(property_value)
 * columnIndex -  the index of the column in the dataset columns array
 */
export function getScoringFactorByColumnId(
  scores: IScoreMap,
  columnIndexes: Record<ColumnId, number>,
  rowId: DatasetRowId,
  colId: ColumnId
): number | null | undefined {
  const columnIndex = columnIndexes[colId];

  return scores[rowId]?.factors[columnIndex];
}

/**
 * Raw Factor = desirability_function(property_value)
 * columnIndex -  the index of the column in the dataset columns array
 */
export function getRawScoringFactorByColumnId(
  scores: IScoreMap,
  columnIndexes: Record<ColumnId, number>,
  rowId: DatasetRowId,
  colId: ColumnId
): number | null | undefined {
  const columnIndex = columnIndexes[colId];

  return scores[rowId]?.rawValues[columnIndex];
}

/**
 * Convert column indexes in scoring factors to corresponding columnIds
 */
export function getRawScoringFactorsWithColumnIds(
  columnIds: ColumnId[],
  score?: IScore
): [ColumnId, number | null | undefined /*RawFactor*/][] {
  return Object.keys(score?.rawValues ?? {}).map((columnIndex) => [
    columnIds[+columnIndex],
    score?.rawValues[+columnIndex],
  ]);
}
