import { ISarTableDataState } from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';

const initialState: ISarTableDataState = {
  pinnedSubstances: [],
  tableId: '',
};

export const sarTableDataSlice = createSlice({
  name: 'sarTableData',
  initialState,
  reducers: {
    setPinnedSubstances(state, action: PayloadAction<string[]>) {
      state.pinnedSubstances = action.payload;
    },
    setSarTableId(state, action: PayloadAction<string>) {
      state.tableId = action.payload;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (state) => {
      state.pinnedSubstances = [];
    },
  },
});

export const { setPinnedSubstances, setSarTableId } = sarTableDataSlice.actions;
