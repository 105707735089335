import {
  IMoosaDataService,
  IScoringTemplate,
  IScoringTemplateNew,
  IScoringTemplateRequest,
  IScoringTemplateSubRoot,
  ITableInfoSubRoot,
} from '@discngine/moosa-models';
import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { selectTableId, setTableScoringTemplateId } from 'tableInfo';

import { selectScoringTemplate } from './scoringTemplate.selectors';
import { scoringFunctionToPayload } from './scoringTemplate.service';
import { setCustomFunctions, templateSaved } from './scoringTemplate.slice';

type ScoringTemplateThunk<T = void> = ThunkAction<
  T,
  IScoringTemplateSubRoot & ITableInfoSubRoot,
  unknown,
  Action<string>
>;

export function assignScoringTemplate(
  service: {
    assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
  },
  templateId: string
): ScoringTemplateThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const tableId = selectTableId(state);

    console.info('assign scoring template', templateId, ' to table', tableId);

    try {
      const assignResponse = await service.assignScoringTemplate(tableId, templateId!);

      console.info('COMPLETE assign scoring template', assignResponse);
    } catch (error) {
      console.error(error);
    }
  };
}

export function unassignScoringTemplate(service: {
  assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
}): ScoringTemplateThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const tableId = selectTableId(state);

    console.info('unassign scoring template to table', tableId);

    try {
      const assignResponse = await service.assignScoringTemplate(tableId, null);

      console.info('COMPLETE unassign scoring template', assignResponse);
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchDesirabilityFunctions(service: {
  getDesirabilityFunctionList: IMoosaDataService['getDesirabilityFunctionList'];
}): ScoringTemplateThunk {
  return async (dispatch) => {
    console.info('fetchDesirabilityFunctions');

    try {
      const { data: functions, total } = await service.getDesirabilityFunctionList();

      let allFunctions = [...functions];

      if (total > functions.length) {
        // it's the case, when we have more custom functions than initial requested limit
        const newLimit = total - functions.length;
        const newSkip = functions.length;
        const { data: restFunctions } = await service.getDesirabilityFunctionList({
          skip: newSkip,
          limit: newLimit,
        });

        allFunctions = [...functions, ...restFunctions];
      }

      dispatch(setCustomFunctions({ functions: allFunctions }));

      console.info('COMPLETE fetchDesirabilityFunctions', functions.length);
    } catch (error) {
      console.error(error);
    }
  };
}

function getPayloadForSavingTemplate(
  getState: () => IScoringTemplateSubRoot & ITableInfoSubRoot,
  name: string
): IScoringTemplateRequest {
  const state = getState();
  const template = selectScoringTemplate(state);

  return scoringFunctionToPayload(name, template);
}

async function afterSavingTemplate(
  dispatch: ThunkDispatch<
    IScoringTemplateSubRoot & ITableInfoSubRoot,
    unknown,
    Action<string>
  >,
  service: {
    assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
  },
  assignedTemplateId: string
): Promise<void> {
  await dispatch(assignScoringTemplate(service, assignedTemplateId));

  dispatch(templateSaved());
}

export function createScoringTemplate(
  service: {
    createScoringTemplate: IMoosaDataService['createScoringTemplate'];
    assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
  },
  name: string,
  onSaveCallback: (template: IScoringTemplateNew) => Promise<IScoringTemplate>
): ScoringTemplateThunk {
  return async (dispatch, getState) => {
    console.info('createScoringTemplate', name);

    try {
      const payload = getPayloadForSavingTemplate(getState, name);

      const response = await onSaveCallback({
        ...payload,
        name,
        columns: payload.columns ?? [],
      });

      console.info('COMPLETE save new scoring template', response);

      if (!response._id) {
        console.error(`Response doesn't contain '_id' field. Attention!`);

        return;
      }
      const parentId = response.parentId || response._id;

      dispatch(setTableScoringTemplateId({ parentId: parentId, id: response._id }));
      const assignedTemplateId = response._id;

      await afterSavingTemplate(dispatch, service, assignedTemplateId);
    } catch (error) {
      console.error(error);
      // let the app handle error
      throw error;
    }
  };
}

export function updateScoringTemplate(
  service: {
    updateScoringTemplate: IMoosaDataService['updateScoringTemplate'];
    assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
  },
  template: IScoringTemplate,
  onUpdateCallback: (template: IScoringTemplate) => Promise<IScoringTemplate>
): ScoringTemplateThunk {
  return async (dispatch, getState) => {
    try {
      const payload = getPayloadForSavingTemplate(getState, template.name);

      const response = await onUpdateCallback({
        ...template,
        ...payload,
        name: template.name,
      });

      if (!response._id) {
        console.error(`Response doesn't contain '_id' field. Attention!`);

        return;
      }
      const assignedTemplateId = response._id;

      await afterSavingTemplate(dispatch, service, assignedTemplateId);
    } catch (error) {
      console.error(error);
      // let the app handle error
      throw error;
    }
  };
}

export function createScoringTemplateVersion(
  service: {
    createScoringTemplate: IMoosaDataService['createScoringTemplate'];
    assignScoringTemplate: IMoosaDataService['assignScoringTemplate'];
  },
  onSaveCallback: (template: IScoringTemplateNew) => Promise<IScoringTemplate>,
  parentTemplateId: string,
  name: string,
  versionName: string,
  description?: string
): ScoringTemplateThunk {
  return async (dispatch, getState) => {
    try {
      const payload = getPayloadForSavingTemplate(getState, name);

      payload.parentId = parentTemplateId;

      payload.description = description;
      payload.versionName = `${versionName}`;

      const response = await onSaveCallback({
        ...payload,
        name,
        columns: payload.columns ?? [],
      });

      console.info('COMPLETE saving new version of scoring template', response);

      if (!response._id) {
        console.error(`Response doesn't contain '_id' field. Attention!`);

        return;
      }

      dispatch(
        setTableScoringTemplateId({ parentId: parentTemplateId, id: response._id })
      );
      const assignedTemplateId = response._id;

      await afterSavingTemplate(dispatch, service, assignedTemplateId);
    } catch (error) {
      console.error(error);
    }
  };
}
