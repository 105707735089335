export const useCopy = () => {
  return (structure: string, svg: string, compoundId: string) => {
    const items: Record<string, Blob> = {};

    items['text/plain'] = new Blob([structure], { type: 'text/plain' });

    items['text/html'] = new Blob(['<div data-compound-id="' + compoundId + '" />'], {
      type: 'text/html',
    });

    const svgBlob = new Blob([svg], {
      type: 'image/svg+xml;charset=utf-8',
    });

    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svgBlob);

    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = image.width * 2;
      canvas.height = image.height * 2;
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(image, 0, 0, image.width * 2, image.height * 2);

        canvas.toBlob((blob) => {
          if (!blob) {
            return;
          }

          items[blob.type] = blob;

          const data = [
            // @ts-ignore
            new ClipboardItem(items),
          ];

          navigator.clipboard
            // @ts-ignore
            .write(data)
            .then(
              () => {},
              () => {
                console.error("Can't write to clipboard");
              }
            )
            .catch((error: any) => {
              console.error(error);
            });
        });
      }
    };

    image.src = blobURL;
  };
};
