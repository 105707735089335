import { DatasetRowId, IDatasetPropertyValue } from '../../serverModels/IDatasetModel';

import { DTNodeId, DTNodeType } from './decisionTree';

export interface DTGroupOption {
  key: string;
  name: IDatasetPropertyValue;
  color: string;
}

export interface DecisionTreeGroups {
  options: DTGroupOption[];
  groups: Map<DTGroupOption['name'], Set<DatasetRowId>>;
}

export type DecisionTreeGroupedResult = Record<DTNodeId, DecisionTreeNodeGroups>;

export type DecisionTreeNodeOutputGroups = Map<DTGroupOption['name'], Set<DatasetRowId>>;

export type DecisionTreeNodeGroups =
  | DecisionTreePropertyNodeGroups
  | DecisionTreeAndNodeGroups
  | DecisionTreeOrNodeGroups;

export interface DecisionTreeCommonNodeGroups {
  id: DTNodeId;
  type: DTNodeType;

  inputGroups: DecisionTreeNodeOutputGroups;
  outputGroups: {
    [slotName: string]: DecisionTreeNodeOutputGroups;
  };
}

export interface DecisionTreePropertyNodeGroups extends DecisionTreeCommonNodeGroups {
  type: DTNodeType.Property;

  outputGroups: {
    yes: DecisionTreeNodeOutputGroups;
    no: DecisionTreeNodeOutputGroups;
    missingValues: DecisionTreeNodeOutputGroups;
  };
}

export interface DecisionTreeAndNodeGroups extends DecisionTreeCommonNodeGroups {
  type: DTNodeType.And;

  outputGroups: {
    combine: DecisionTreeNodeOutputGroups;
  };
}

export interface DecisionTreeOrNodeGroups extends DecisionTreeCommonNodeGroups {
  type: DTNodeType.Or;

  outputGroups: {
    combine: DecisionTreeNodeOutputGroups;
  };
}

export function isPropertyNodeGroups(
  node: DecisionTreeNodeGroups
): node is DecisionTreePropertyNodeGroups {
  return node.type === DTNodeType.Property;
}

export function isOrNodeGroups(
  node: DecisionTreeNodeGroups
): node is DecisionTreeOrNodeGroups {
  return node.type === DTNodeType.Or;
}

export function isAndNodeGroups(
  node: DecisionTreeNodeGroups
): node is DecisionTreeAndNodeGroups {
  return node.type === DTNodeType.And;
}
