import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import { ReactReduxContextValue } from 'react-redux';

import { rootReducer } from './rootReducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    });
  },
});

export const MoosaTemplateSelectorWidgetStoreContext =
  React.createContext<ReactReduxContextValue>({
    store: store,
    storeState: {},
  });
