import { RGroupDecompositionDataService } from '@discngine/moosa-cheminformatics-data';
import { MoosaSarMatrixRequestForm } from '@discngine/moosa-sar-matrix';
import { RDKitContext, StructureRenderer } from '@discngine/moosa-structure-renderer';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRDKitProvider } from '../../../components/useRDKitProvider';
import {
  selectFailedMoleculesQty,
  selectJobStatus,
  selectOnlyMatchAtRGroupsForCheckbox,
  selectRemoveAllHydrogenRGroupsForCheckbox,
  selectRequestInProgress,
  selectScaffoldData,
  selectUnmatchedMolIndicesQty,
} from '../../../store/sarMatrix/sarMatrix.selector';
import {
  setScaffoldData,
  setOnlyMatchAtRGroups,
  setRemoveAllHydrogenRGroups,
} from '../../../store/sarMatrix/sarMatrix.slice';
import {
  addRGroupCalculationJob,
  deleteRGroupJob,
} from '../../../store/sarMatrix/sarMatrix.thunk';

const StructureEditor = React.lazy(() => import('@discngine/moosa-structure-editor'));

const rGroupDecompositionDataService = new RGroupDecompositionDataService(
  'chemoinfo',
  'v1'
);

export const SarMatrixProperties: FC = () => {
  const RDKit = useRDKitProvider();
  const dispatch = useDispatch();
  const jobStatus = useSelector(selectJobStatus);
  const requestInProgress = useSelector(selectRequestInProgress);
  const scaffoldData = useSelector(selectScaffoldData);
  const onlyMatchAtRGroupsForCheckbox = useSelector(selectOnlyMatchAtRGroupsForCheckbox);
  const removeAllHydrogenRGroupsForCheckbox = useSelector(
    selectRemoveAllHydrogenRGroupsForCheckbox
  );
  const unmatchedIndicesQty = useSelector(selectUnmatchedMolIndicesQty);
  const failedMoleculesQty = useSelector(selectFailedMoleculesQty);

  const onStartJob = useCallback(() => {
    dispatch(addRGroupCalculationJob(rGroupDecompositionDataService));
  }, [dispatch]);

  const onStopJob = useCallback(() => {
    dispatch(deleteRGroupJob(rGroupDecompositionDataService, jobStatus.id));
  }, [dispatch, jobStatus]);

  const onSetScaffoldData = useCallback(
    (scaffoldData) => {
      if (scaffoldData) {
        dispatch(setScaffoldData(scaffoldData));
      }
    },
    [dispatch]
  );

  const onOnlyMatchAtRGroups = useCallback(
    (onlyMatchAtRGroups) => {
      dispatch(setOnlyMatchAtRGroups(onlyMatchAtRGroups));
    },
    [dispatch]
  );

  const onRemoveAllHydrogenRGroups = useCallback(
    (removeAllHydrogenRGroups) => {
      dispatch(setRemoveAllHydrogenRGroups(removeAllHydrogenRGroups));
    },
    [dispatch]
  );

  const hasEmptyCores =
    scaffoldData.find((scaffold) => !scaffold || scaffold.length === 0) !== undefined;

  const calculateBtnDisabled = hasEmptyCores || requestInProgress;

  return (
    <RDKitContext.Provider value={RDKit}>
      <MoosaSarMatrixRequestForm
        calculateBtnDisabled={calculateBtnDisabled}
        failedMoleculesQty={failedMoleculesQty}
        jobStatus={jobStatus}
        onlyMatchAtRGroups={onlyMatchAtRGroupsForCheckbox}
        removeAllHydrogenRGroups={removeAllHydrogenRGroupsForCheckbox}
        scaffoldData={scaffoldData}
        StructureEditor={StructureEditor}
        structureRenderer={StructureRenderer}
        unmatchedIndicesQty={unmatchedIndicesQty}
        onOnlyMatchAtRGroups={onOnlyMatchAtRGroups}
        onRemoveAllHydrogenRGroups={onRemoveAllHydrogenRGroups}
        onSetScaffoldData={onSetScaffoldData}
        onStartJob={onStartJob}
        onStopJob={onStopJob}
      />
    </RDKitContext.Provider>
  );
};
