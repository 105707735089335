import { PushpinFilled } from '@ant-design/icons';
import { RGroupId } from '@discngine/moosa-cheminformatics-data';
import { Tooltip } from 'antd';
import React, { useCallback } from 'react';

import { useSarMatrix } from '../../SarMatrixContext';

import styles from './styles.module.less';

export type ISarMatrixPinnedListItemProps = {
  rGroupId: RGroupId;
  structure: string;
  onClick: (rGroupId: RGroupId) => void;
};
export const SarMatrixPinnedListItem = React.memo(
  ({ rGroupId, structure, onClick }: ISarMatrixPinnedListItemProps) => {
    const handleClick = useCallback(() => {
      onClick(rGroupId);
    }, [rGroupId, onClick]);

    const { structureRenderer: StructureRenderer } = useSarMatrix();

    return (
      <Tooltip title={'Click to unpin'}>
        <div className={styles.listItem} onClick={handleClick}>
          <div className={styles.listItemHeader}>
            <span>{rGroupId}</span>
            <PushpinFilled height={17} style={{ color: '#004D67' }} width={17} />
          </div>
          <StructureRenderer smiles={structure} />
        </div>
      </Tooltip>
    );
  }
);
