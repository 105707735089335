import { SarMatrixCellView } from '@discngine/moosa-models';
import { Select } from 'antd';
import React from 'react';

import styles from './SarMatrixCellViewModeSelector.module.less';

export type ISarMatrixCellViewModeSelectorProps = {
  cellView: SarMatrixCellView;
  onChangeCellView: (cellView: SarMatrixCellView) => void;
};
export const SarMatrixCellViewModeSelector = ({
  cellView,
  onChangeCellView,
}: ISarMatrixCellViewModeSelectorProps) => {
  return (
    <div>
      <h3 className={styles.title}>Select view mode</h3>
      <Select value={cellView} onChange={onChangeCellView}>
        <Select.Option key={SarMatrixCellView.Circles}>Circle view</Select.Option>
        <Select.Option key={SarMatrixCellView.FactorsAbsolute}>
          Bar chart view
        </Select.Option>
        <Select.Option key={SarMatrixCellView.PieChart}>Pie chart view</Select.Option>
      </Select>
    </div>
  );
};
