import {
  IJobStatus,
  IStructureEditorSelection,
  JobStatus,
  MAX_DATASET,
} from '@discngine/moosa-models';
import { Sorting } from '@discngine/moosa-sar-radiant-chart';
import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IMatchedMolecule,
  IMoosaMMPConfigTag,
  ISarRadiantChartState,
} from './ISarRadiantChartState';

const initialState: ISarRadiantChartState = {
  parameter: '',
  columns: [],
  jobStatus: {
    id: '',
    info: '',
    status: JobStatus.NotStarted,
  },
  pairs: [],
  sorting: Sorting.DESC,
  coreID: '',
  maxCompounds: MAX_DATASET,
  selection: null,
  molFile: null,
  isScoredDataMode: false,
  invertAxes: false,
  showRadialMolecules: false,
  requestConfig: JSON.stringify({
    result_ttl: 500,
    job_timeout: 1200,
    job_priority: 'default',
    max_bonds_to_break_at_the_same_time: 1,
    min_bonds_to_break_at_the_same_time: 1,
    remove_duplicates: true,
    max_fragments_per_mol: -1,
    // set keep attachment points to false, because it may affect molecule's core highlight/alignment on MMP page
    keep_attachment_points: true,
    consider_hydrogen: false,
    minFragmentSize: -1,
    maxFragmentSize: 15,
    minCoreSize: 5,
    maxCoreSize: 999,
  }),
};

export const sarRadiantChartSlice = createSlice({
  name: 'sarRadiantChart',
  initialState,
  reducers: {
    setJobStatus(state, action: PayloadAction<IJobStatus>) {
      state.jobStatus = action.payload;
    },
    setParameter(state, action: PayloadAction<string>) {
      state.parameter = action.payload;
    },
    setMMPColumns(state, action: PayloadAction<IMoosaMMPConfigTag[][]>) {
      state.columns = action.payload;
    },
    setPairs(state, action: PayloadAction<Array<IMatchedMolecule>>) {
      state.pairs = action.payload;
    },
    setRequestConfig(state, action: PayloadAction<string>) {
      state.requestConfig = action.payload;
    },
    setCoreID(state, action: PayloadAction<string>) {
      state.coreID = action.payload;
    },
    setMaxCompounds(state, action: PayloadAction<number>) {
      state.maxCompounds = action.payload;
    },
    setSelection(state, action: PayloadAction<IStructureEditorSelection | null>) {
      state.selection = action.payload;
    },
    setSorting(state, action: PayloadAction<Sorting>) {
      state.sorting = action.payload;
    },
    setMolFile(state, action: PayloadAction<string>) {
      state.molFile = action.payload;
    },
    setScoredDataMode(state, action: PayloadAction<boolean>) {
      state.isScoredDataMode = action.payload;
    },
    setInvertAxes(state, action: PayloadAction<boolean>) {
      state.invertAxes = action.payload;
    },
    setShowRadialMolecules(state, action: PayloadAction<boolean>) {
      state.showRadialMolecules = action.payload;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (state) => {
      state.sorting = initialState.sorting;
      state.pairs = initialState.pairs;
      state.coreID = initialState.coreID;
      state.selection = initialState.selection;
      state.jobStatus = initialState.jobStatus;
      state.parameter = initialState.parameter;
      state.columns = initialState.columns;
      state.molFile = initialState.molFile;
      state.isScoredDataMode = initialState.isScoredDataMode;
    },
  },
});

export const {
  setJobStatus,
  setParameter,
  setRequestConfig,
  setPairs,
  setCoreID,
  setMaxCompounds,
  setSelection,
  setSorting,
  setMolFile,
  setScoredDataMode,
  setInvertAxes,
  setShowRadialMolecules,
  setMMPColumns,
} = sarRadiantChartSlice.actions;
