import { IRDKitContext } from '@discngine/moosa-structure-renderer';
import { useEffect, useMemo, useState } from 'react';

const getRDKit = async (): Promise<Record<string, any> | null> => {
  //@ts-ignore
  if (!window.initRDKitModule) {
    console.error(`RD Kit is not found`);

    return null;
  }

  //@ts-ignore
  return await window.initRDKitModule();
};

export function useRDKitProvider(): IRDKitContext {
  const [RDKit, setRDKit] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    (async function InitRDKit() {
      setRDKit(await getRDKit());
      console.info('RDKit was set');
    })();
  }, []);

  return useMemo<IRDKitContext>(
    () => ({
      RDKit: RDKit,
    }),
    [RDKit]
  );
}
