/* eslint-disable @typescript-eslint/no-use-before-define */
export enum ExpressionType {
  Identifier = 'Identifier',
  BinaryExpression = 'BinaryExpression',
  CallExpression = 'CallExpression',
  Literal = 'Literal',
  LogicalExpression = 'LogicalExpression',
  UnaryExpression = 'UnaryExpression',
}

export interface Identifier {
  type: ExpressionType.Identifier;
  name: string;
}

export interface Literal {
  type: ExpressionType.Literal;
  value: boolean | number | string | null;
}

export interface BinaryExpression {
  type: ExpressionType.BinaryExpression;
  left: Expression;
  right: Expression;
  operator: string;
}

export interface UnaryExpression {
  type: ExpressionType.UnaryExpression;
  argument: Expression;
  operator: string;
}

export interface LogicalExpression {
  type: ExpressionType.LogicalExpression;
  left: Expression;
  right: Expression;
  operator: string;
}

export interface CallExpression {
  type: ExpressionType.CallExpression;
  callee: Identifier;
  arguments: Expression[];
}

export type Expression =
  | BinaryExpression
  | CallExpression
  | Identifier
  | Literal
  | LogicalExpression
  | UnaryExpression;

export interface Formula {
  expression: Expression;
}
