import { FC } from 'react';

import styles from './ColorView.module.less';

type ColorViewProps = {
  color: string;
};

export const ColorView: FC<ColorViewProps> = function ColorView({ color }) {
  return <div className={styles.color} style={{ backgroundColor: color }}></div>;
};
