import { Select, Space } from 'antd';
import React, { FC } from 'react';

import styles from './SarMatrixAxisSorter.module.less';
export interface ISarMatrixAxisSelectorProps {
  caption: string;
  properties: string[];
  property: string;
  order: 'asc' | 'desc';
  onSelectProperty: (property: string) => void;
  onSelectOrder: (order: 'asc' | 'desc') => void;
}

export const SarMatrixAxisSorter: FC<ISarMatrixAxisSelectorProps> = ({
  caption,
  properties,
  property,
  order,
  onSelectProperty,
  onSelectOrder,
}) => (
  <>
    <h5 className={styles.caption}>{caption}</h5>
    <Space direction={'vertical'}>
      <Select value={property} onChange={onSelectProperty}>
        {properties.map((property) => (
          <Select.Option key={property}>{property}</Select.Option>
        ))}
      </Select>
      <Select value={order} onChange={onSelectOrder}>
        {['asc', 'desc'].map((sortOrder) => (
          <Select.Option key={sortOrder}>{sortOrder}</Select.Option>
        ))}
      </Select>
    </Space>
  </>
);
