import React, { useCallback, useMemo } from 'react';

import { useCellRenderer } from '../../SarMatrixContext';
import { SarMatrixCellRendererProps } from '../../SarMatrixTable/types';
import { formatFactor } from '../helpers';
import { TableCellPopover } from '../TableCellPopover/TableCellPopover';
import { TooltipContent } from '../TableCellPopover/TooltipContent';
import { useStructurePopover } from '../TableCellPopover/useStructurePopover';
import { CellFactor, IFactorBar } from '../types';

import { absoluteValuesBarChartBuilder } from './absoluteValuesBarChartBuilder';
import { BarChart } from './BarChart';
import styles from './FactorCell.module.less';

const BASIC_HEIGHT = 200;
const ITEM_HEIGHT = 30;

export const FactorCell = (props: SarMatrixCellRendererProps) => {
  const {
    getSubstances,
    structureColumnName,
    rawDataset,
    selectedScoringColumns,
    tableColumnsMap,
    isScoringAverage,
    columnLabelMap,
    scoredDataset,
    columnIndices,
  } = useCellRenderer();

  const cellFactors = useMemo<CellFactor<IFactorBar>[]>(() => {
    const substances = getSubstances(props);

    const result = substances.map(({ substanceId }) => {
      return {
        charts: absoluteValuesBarChartBuilder({
          columnsFactorsAbsolute: selectedScoringColumns,
          columnsMap: tableColumnsMap,
          rawDataset,
          substanceId,
          substances: substances.map((x) => x.substanceId),
          isAverageMode: isScoringAverage,
          scoredDataset,
          columnIndices,
        }),
        smiles: String(rawDataset[substanceId]?.[structureColumnName] || ''),
        substanceId,
      };
    });

    if (isScoringAverage) {
      return result.slice(0, 1);
    }

    return result;
  }, [
    columnIndices,
    getSubstances,
    isScoringAverage,
    props,
    rawDataset,
    scoredDataset,
    selectedScoringColumns,
    structureColumnName,
    tableColumnsMap,
  ]);

  const length = Math.ceil(Math.sqrt(cellFactors.length));

  const { hoveredId, onMouseOver, onMouseLeave } = useStructurePopover();

  const popoverContent = useCallback(
    ({ id }: { id: string }) => {
      const item = cellFactors.find((x) => x.substanceId === id);

      if (!item) {
        return null;
      }

      const height = BASIC_HEIGHT + item.charts.length * ITEM_HEIGHT;

      return (
        <TooltipContent height={height} id={id}>
          {item.charts.map((chart) => {
            return (
              <div key={chart.colId} className={styles.barsRoot}>
                <div className={styles.barPill} style={{ background: chart.color }} />

                <div>{columnLabelMap[chart.colId]?.label ?? chart.colId}</div>

                <div className={styles.factorValue}>{formatFactor(chart?.label)}</div>
              </div>
            );
          })}
        </TooltipContent>
      );
    },
    [cellFactors, columnLabelMap]
  );

  return (
    <TableCellPopover hoveredSubstanceId={hoveredId} popoverContent={popoverContent}>
      <div
        style={{
          display: 'grid',
          gridTemplateRows: `1fr `.repeat(length),
          gridTemplateColumns: `1fr `.repeat(length),
          width: '100%',
          height: '100%',
          gap: '4%',
          padding: '4px',
        }}
        onMouseLeave={onMouseLeave}
      >
        {cellFactors.map((cellFactor) => (
          <div
            key={cellFactor.smiles}
            className={styles.factors}
            data-id={cellFactor.substanceId}
            onMouseOver={onMouseOver}
          >
            <BarChart cellFactor={cellFactor} />
          </div>
        ))}
      </div>
    </TableCellPopover>
  );
};
