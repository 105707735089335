export interface DiscretePropertyValue {
  value: string | number;
  color: IRGBColor | null;
}

export interface IMoosaSarConfigTag {
  columnId: string;
  range: { min: number; max: number };
  isDiscreteProperty: boolean;
  isScoredColorizingMode: boolean;
  isColored: boolean;
  gradient: IGradient;
  discretePropertyValues: DiscretePropertyValue[];
  isScoreAvailable: boolean;
  isDatasetAvailable: boolean;
  isScoringColumn: boolean;
}

export enum EMoosaSarConfigImageSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

// TODO need to fix, it is just copied from moosa-common,
//  to don't add new package dependency
export interface IRGBColor {
  r: number; // [0, 255]
  g: number; // [0, 255]
  b: number; // [0, 255]
}

// TODO need to be fix
export interface IGradientColor {
  color: IRGBColor;
  percent: number;
}

export interface IGradient {
  scoredGradient: IGradientColor[];
  datasetGradient: IGradientColor[];
}

export interface ISarConfigState {
  structure: string[];
  columns: IMoosaSarConfigTag[][];
  imageSize: EMoosaSarConfigImageSize;
  isConfigPanelOpen: boolean;
  isColorizingTextMode: boolean;
}

export interface ISarConfigSubRoot {
  sarConfig: ISarConfigState;
}
