import { IRGBColor } from './utils/colorUtils';

export const AVAILABLE_COLORS_FOR_SAR_COLORIZING: IRGBColor[][] = [
  [
    { r: 255, g: 77, b: 79 },
    { r: 255, g: 120, b: 117 },
    { r: 255, g: 163, b: 158 },
    { r: 255, g: 204, b: 199 },
    { r: 255, g: 241, b: 240 },
  ],
  [
    { r: 255, g: 169, b: 64 },
    { r: 255, g: 192, b: 105 },
    { r: 255, g: 213, b: 145 },
    { r: 255, g: 231, b: 186 },
    { r: 255, g: 247, b: 230 },
  ],
  [
    { r: 255, g: 236, b: 61 },
    { r: 255, g: 245, b: 102 },
    { r: 255, g: 251, b: 143 },
    { r: 255, g: 255, b: 184 },
    { r: 254, g: 255, b: 230 },
  ],
  [
    { r: 115, g: 209, b: 61 },
    { r: 149, g: 222, b: 100 },
    { r: 183, g: 235, b: 143 },
    { r: 217, g: 247, b: 190 },
    { r: 246, g: 255, b: 237 },
  ],
  [
    { r: 54, g: 207, b: 201 },
    { r: 92, g: 219, b: 211 },
    { r: 135, g: 232, b: 222 },
    { r: 181, g: 245, b: 236 },
    { r: 230, g: 255, b: 251 },
  ],
  [
    { r: 64, g: 169, b: 255 },
    { r: 105, g: 192, b: 255 },
    { r: 145, g: 213, b: 255 },
    { r: 186, g: 231, b: 255 },
    { r: 230, g: 247, b: 255 },
  ],
  [
    { r: 89, g: 126, b: 247 },
    { r: 133, g: 165, b: 255 },
    { r: 173, g: 198, b: 255 },
    { r: 214, g: 228, b: 255 },
    { r: 240, g: 245, b: 255 },
  ],
  [
    { r: 146, g: 84, b: 222 },
    { r: 179, g: 127, b: 235 },
    { r: 211, g: 173, b: 247 },
    { r: 239, g: 219, b: 255 },
    { r: 249, g: 240, b: 255 },
  ],
  [
    { r: 247, g: 89, b: 171 },
    { r: 255, g: 133, b: 192 },
    { r: 255, g: 173, b: 210 },
    { r: 255, g: 214, b: 231 },
    { r: 255, g: 240, b: 246 },
  ],
];

export const SAR_IMAGE_SIZE_OPTIONS = {
  small: { label: 'Small', value: 'small', width: 258, height: 64 },
  medium: { label: 'Medium', value: 'medium', width: 300, height: 128 },
  large: { label: 'Large', value: 'large', width: 400, height: 180 },
  extraLarge: { label: 'Extra Large', value: 'extraLarge', width: 500, height: 250 },
};

export const DEFAULT_COLORS = [
  '#e4744e',
  '#f2b06e',
  '#f6e652',
  '#98d268',
  '#489556',
  '#51a4aa',
  '#0093dc',
  '#5276bb',
  '#a376c2',
  '#92656e',
  '#ee7d87',
  '#b34454',
  '#bdba6f',
  '#e9f3a3',
  '#bed4a9',
  '#a0dbdd',
  '#c6f0fd',
  '#5041ad',
  '#bd9da4',
  '#828282',
];
