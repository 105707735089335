import { DatasetRowId, ITableDataRow } from '@discngine/moosa-models';
import { IScoreMap } from '@discngine/moosa-models';
import tinycolor, { ColorFormats } from 'tinycolor2';

export const getColorFromGradient = (
  colorFrom: ColorFormats.RGB,
  colorTo: ColorFormats.RGB,
  value: number
): ColorFormats.RGB => {
  let w1 = 1 - value;
  let w2 = value;

  return {
    r: Math.round(colorFrom.r * w1 + colorTo.r * w2),
    g: Math.round(colorFrom.g * w1 + colorTo.g * w2),
    b: Math.round(colorFrom.b * w1 + colorTo.b * w2),
  };
};

export const getColorString = (value: number, missingValue?: boolean) => {
  let hexString = missingValue ? '#EAEAEA' : '#000000';
  const redColor = tinycolor('#FF4D4F');
  const yellowColor = tinycolor('#FFEC3D');
  const greenColor = tinycolor('#73D13D');

  if (value < -0.1 || value > 1.1 || missingValue) return hexString;

  if (value < 0.5) {
    hexString = tinycolor(
      getColorFromGradient(redColor.toRgb(), yellowColor.toRgb(), value)
    ).toHexString();
  } else {
    hexString = tinycolor(
      getColorFromGradient(yellowColor.toRgb(), greenColor.toRgb(), value)
    ).toHexString();
  }

  return hexString;
};

export type CalculateActivityParams = {
  id: string;
  dataset: Record<DatasetRowId, ITableDataRow>;
  parameter: string;
  scoredDataset: IScoreMap;
  columnKeyScoring: string;
  isScoredDataMode: boolean;
  scoredColumnIndex: number;
};
export const calculateActivity = ({
  id,
  dataset,
  parameter,
  columnKeyScoring,
  scoredDataset,
  isScoredDataMode,
  scoredColumnIndex,
}: CalculateActivityParams): number | null => {
  let activity: number | null = null;

  if (isScoredDataMode) {
    activity = scoredDataset[id]?.rawValues[scoredColumnIndex];
  } else {
    if (parameter === columnKeyScoring) {
      activity = scoredDataset[id]?.value;
    } else {
      if (dataset[id]?.[parameter] === null || dataset[id]?.[parameter] === undefined) {
        activity = null;
      } else {
        activity = Number(dataset[id]?.[parameter]);
      }
    }
  }

  return activity;
};
