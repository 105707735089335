enum State {
  IDLE,
  WAITING,
}

export class Queue {
  private id: number = 0;
  private tasks = new Map<number, Function>();
  private queue: number[] = [];
  private readonly interval: number = 0;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  private state: State = State.IDLE;

  constructor(interval: number) {
    this.interval = interval;
  }

  add(task: Function): number {
    this.id = (this.id + 1) % Number.MAX_SAFE_INTEGER;
    this.tasks.set(this.id, task);
    this.queue.push(this.id);

    this.start();

    return this.id;
  }

  remove(id: number) {
    this.tasks.delete(id);
  }

  start() {
    if (this.state === State.IDLE && this.queue.length > 0) {
      this.state = State.WAITING;
      this.timeoutId = setTimeout(this.run.bind(this), this.interval);
    }
  }

  run() {
    this.state = State.IDLE;
    while (this.queue.length > 0) {
      const id = this.queue.shift();

      if (typeof id !== 'undefined' && this.tasks.has(id)) {
        const task = this.tasks.get(id);

        if (task) {
          task();
          break;
        }
      }
    }

    this.start();
  }
}
