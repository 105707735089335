import React from 'react';

import { MMPChartBaseMolecule } from '../../types';

import styles from './sector.module.less';

export interface IFullSectorProps {
  molecule: MMPChartBaseMolecule;
  r0: number;
  r1: number;
  r2: number;
  strokeColor: string;
  color: string;
  onEnterSector: () => void;
  onLeaveSector: () => void;
}

export const FullSector = ({
  molecule,
  r0,
  r1,
  r2,
  strokeColor,
  color,
  onEnterSector,
  onLeaveSector,
}: IFullSectorProps) => {
  let smallRadius = 0;
  let bigRadius = 0;

  if (molecule.activity === null) {
    bigRadius = r2;
    smallRadius = r0;
  } else if (molecule.activity < 0) {
    bigRadius = r1;
    smallRadius = r1 - (r1 - r0) * Math.abs(molecule.activity);
  } else {
    bigRadius = r1 + (r2 - r1) * Math.abs(molecule.activity);
    smallRadius = r1;
  }

  return (
    <>
      <circle
        className={styles.sector}
        cx={0}
        cy={0}
        fill={color}
        r={bigRadius}
        stroke={strokeColor}
        strokeWidth="1"
        onMouseEnter={onEnterSector}
        onMouseLeave={onLeaveSector}
      ></circle>
      <circle
        cx={0}
        cy={0}
        fill={'white'}
        r={smallRadius}
        stroke={strokeColor}
        strokeWidth="1"
      />
    </>
  );
};
