import { FieldType } from '../../serverModels/IDatasetMetaModel';

import { ColumnId } from './datasetCommon';
import { ITableDataRow } from './tableData';
import { IColumnMetaInfo } from './tableInfo';

export function getCellValue(row: ITableDataRow, colId: string) {
  return row ? row[colId] : undefined;
}

/**
 * Note: the dataset may contain several or none structure columns, the function returns the first one
 */
export const getStructureColumnId = (
  columnsInfo: IColumnMetaInfo[]
): ColumnId | undefined => {
  return columnsInfo.find((col) => col.type === FieldType.Structure)?.name;
};

/**
 * Note: the dataset may contain several or none ID columns, the function returns the first one
 */
export const getIdentifierColumnId = (
  columnsInfo: IColumnMetaInfo[]
): ColumnId | undefined => {
  return columnsInfo.find((col) => col.type === FieldType.ID)?.name;
};
