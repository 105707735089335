import { MMPDataService } from '@discngine/moosa-cheminformatics-data';
import {
  DatasetRowId,
  IStructureEditorAPI,
  ITableDataRow,
} from '@discngine/moosa-models';
import {
  MoosaMMPChartConfig,
  MMPChartCentralMolecule,
} from '@discngine/moosa-sar-radiant-chart';
import { selectScoredDataset } from '@discngine/moosa-store/scores';
import { selectRawDataset } from '@discngine/moosa-store/tableData';
import { selectTableColumnIndexes } from '@discngine/moosa-store/tableInfo';
import { useRDKitContext } from '@discngine/moosa-structure-renderer';
import { getMOLBLOCK } from '@discngine/moosa-structure-renderer';
import { StructureRenderer } from '@discngine/moosa-structure-renderer';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIdColumnKey } from '../../../components/DataSarRadiantChartWrapper/hooks/useIdColumnKey';
import { useScoringColumnKey } from '../../../components/DataSarRadiantChartWrapper/hooks/useScoringColumnKey';
import { useStructureColumnKey } from '../../../components/DataSarRadiantChartWrapper/hooks/useStructureColumnKey';
import { calculateActivity } from '../../../components/DataSarRadiantChartWrapper/utils';
import {
  selectCoreID,
  selectJobStatus,
  selectMaxCompounds,
  selectParameter,
  selectMMPColumns,
  selectRequestConfig,
  selectRequestInProgress,
  selectScoredDataMode,
} from '../../../store/sarRadiantChart/sarRadiantChart.selector';
import {
  setCoreID,
  setMaxCompounds,
  setMolFile,
  setPairs,
  setRequestConfig,
  setSelection,
} from '../../../store/sarRadiantChart/sarRadiantChart.slice';
import {
  addMMPCalculationJob,
  deleteMMPJob,
} from '../../../store/sarRadiantChart/sarRadiantChart.thunk';

const StructureEditor = React.lazy(() => import('@discngine/moosa-structure-editor'));

const chemInfoDataservice = new MMPDataService('chemoinfo', 'v1');

export const MMPRequestConfig = () => {
  const dispatch = useDispatch();
  const { RDKit } = useRDKitContext();
  const columnKeyStructure = useStructureColumnKey();
  const columnKeyID = useIdColumnKey();
  const coreID = useSelector(selectCoreID);
  const maxCompounds = useSelector(selectMaxCompounds);
  const parameter = useSelector(selectParameter);
  const jobStatus = useSelector(selectJobStatus);
  const scoredDataset = useSelector(selectScoredDataset);
  const columnKeyScoring = useScoringColumnKey();
  const requestInProgress = useSelector(selectRequestInProgress);
  const isScoredDataMode = useSelector(selectScoredDataMode);
  const config = useSelector(selectRequestConfig);
  const selectedProperties = useSelector(selectMMPColumns);
  const tableColumnIndexes = useSelector(selectTableColumnIndexes);
  const dataset: Record<DatasetRowId, ITableDataRow> = useSelector(selectRawDataset);
  const scoredColumnIndex = tableColumnIndexes[parameter];
  let centralMoleculeSmiles = dataset?.[coreID]?.[columnKeyStructure];

  const centralMoleculeActivity: number | null = calculateActivity({
    id: coreID,
    scoredDataset: scoredDataset,
    columnKeyScoring: columnKeyScoring,
    parameter: parameter,
    dataset: dataset,
    isScoredDataMode: isScoredDataMode,
    scoredColumnIndex: scoredColumnIndex,
  });

  const centralMoleculeInitialActivities = selectedProperties.map((property) => {
    return calculateActivity({
      id: coreID,
      scoredDataset: scoredDataset,
      columnKeyScoring: columnKeyScoring,
      parameter: property[0].columnId,
      dataset: dataset,
      isScoredDataMode: isScoredDataMode,
      scoredColumnIndex: scoredColumnIndex,
    });
  });

  const centralMoleculeStructure = centralMoleculeSmiles
    ? `${centralMoleculeSmiles}`
    : '';

  const centralMolecule = useMemo<MMPChartCentralMolecule>(
    () => ({
      id: coreID,
      activity: centralMoleculeActivity,
      structure: centralMoleculeStructure,
      core: '',
      fragment: '',
      structureMOLFILE: getMOLBLOCK(RDKit, centralMoleculeStructure),
      initialValue: centralMoleculeActivity,
      initialValues: centralMoleculeInitialActivities,
    }),
    [
      coreID,
      RDKit,
      centralMoleculeActivity,
      centralMoleculeInitialActivities,
      centralMoleculeStructure,
    ]
  );
  const items = useMemo(() => {
    return Object.values(dataset).map((item) => {
      const smiles = item[columnKeyStructure];
      const cid = item[columnKeyID];

      return {
        id: item.key,
        SMILES: smiles ? `${smiles}` : '',
        cid: cid ? `${cid}` : '',
        index: item['system.rank'],
      };
    });
  }, [dataset, columnKeyID, columnKeyStructure]);

  const onChangeConfig = useCallback(
    (data) => {
      dispatch(setRequestConfig(data));
    },
    [dispatch]
  );

  const onStartJob = useCallback(() => {
    dispatch(addMMPCalculationJob(chemInfoDataservice));
  }, [dispatch]);

  const onStopJob = useCallback(
    (jobID: string) => {
      dispatch(deleteMMPJob(chemInfoDataservice, jobID));
    },
    [dispatch]
  );

  const onChangeCore = useCallback(
    (id?: string) => {
      dispatch(setCoreID(id || ''));
      dispatch(setPairs([]));

      if (jobStatus.id) {
        dispatch(deleteMMPJob(chemInfoDataservice, jobStatus.id));
      }
    },
    [dispatch, jobStatus.id]
  );

  const onChangeMaxCompounds = useCallback(
    (value: number) => {
      dispatch(setMaxCompounds(value));
    },
    [dispatch]
  );

  const onSelectionChange = useCallback(
    (selection: ReturnType<IStructureEditorAPI['getSelection']>, molFile: string) => {
      dispatch(setSelection(selection));
      dispatch(setMolFile(molFile));
    },
    [dispatch]
  );

  return (
    <MoosaMMPChartConfig
      config={config}
      coreID={coreID}
      cores={items}
      jobStatus={jobStatus}
      maxCompounds={maxCompounds}
      requestInProgress={requestInProgress}
      structure={centralMolecule.structureMOLFILE}
      StructureEditor={StructureEditor}
      StructureRenderer={StructureRenderer}
      onChangeConfig={onChangeConfig}
      onChangeCore={onChangeCore}
      onChangeMaxCompounds={onChangeMaxCompounds}
      onSelectionChange={onSelectionChange}
      onStartJob={onStartJob}
      onStopJob={onStopJob}
    />
  );
};
