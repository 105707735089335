import { RightOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ColorPickerPanelPure } from 'ColorPickerPanelPure/ColorPickerPanelPure';
import { FC } from 'react';

import { ColorView } from '../../ColorView/ColorView';

import styles from './FactorsPopover.module.less';
import { IFactorBar } from './IFactorBar';

type PropertyProps = {
  isPossibleToChangeColor: boolean;
  colId: string;
  color: string;
};

const Property: FC<PropertyProps> = ({ colId, color, isPossibleToChangeColor }) => {
  return (
    <div className={styles.property}>
      <div className={styles.color}>
        <ColorView color={color} />
      </div>
      <div className={styles.title}>{colId}</div>
      {isPossibleToChangeColor && <RightOutlined className={styles.arrow} />}
    </div>
  );
};

type FactorsPopoverProps = {
  barChart: IFactorBar[];
  isPossibleToChangeColor: boolean;
  onChangeColumnColor: (column: string, color: string) => void;
};

export const FactorsPopover: FC<FactorsPopoverProps> = function FactorsPopover({
  barChart,
  isPossibleToChangeColor,
  onChangeColumnColor,
}) {
  return (
    <>
      {barChart.map(({ colId, color }) => (
        <>
          {isPossibleToChangeColor && (
            <Popover
              key={colId}
              content={
                <ColorPickerPanelPure
                  color={color}
                  onColorChange={(color: string) => onChangeColumnColor(colId, color)}
                />
              }
              placement="right"
            >
              <div>
                <Property
                  colId={colId}
                  color={color}
                  isPossibleToChangeColor={isPossibleToChangeColor}
                />
              </div>
            </Popover>
          )}
          {!isPossibleToChangeColor && (
            <Property
              colId={colId}
              color={color}
              isPossibleToChangeColor={isPossibleToChangeColor}
            />
          )}
        </>
      ))}
    </>
  );
};
