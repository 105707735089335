import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { EMoosaSarConfigImageSize, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { Button, message, Space, Upload, UploadProps } from 'antd';
import React from 'react';

import styles from './MoosaSarTableConfig.module.less';
import { HandleSetSarConfig } from './MoosaSarTableConfigProps';

interface IEProps {
  columns: IMoosaSarConfigTag[][];
  structure: string[];
  imageSize: EMoosaSarConfigImageSize;
  isColorizingTextMode: boolean;
  setSettings: HandleSetSarConfig;
}

const JSON_MIME = 'application/json';

export const MoosaSarConfigImportExport: React.FC<IEProps> = ({
  columns,
  structure,
  imageSize,
  isColorizingTextMode,
  setSettings,
}) => {
  const exportData = () => {
    const filename = 'sar.config.json';
    const data = {
      columns,
      structure,
      imageSize,
      isColorizingTextMode,
    };
    const json = encodeURIComponent(JSON.stringify(data));

    const element = document.createElement('a');

    element.setAttribute('href', `data:${JSON_MIME};charset=utf-8,${json}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.json',
    showUploadList: false,
    beforeUpload(file) {
      file
        .text()
        .then((text) => {
          let data: {
            columns: IMoosaSarConfigTag[][];
            structure: string[];
            imageSize: EMoosaSarConfigImageSize;
            isColorizingTextMode: boolean;
          };

          try {
            data = JSON.parse(text);

            setSettings({
              columns: data.columns ?? [],
              coreStructures: data.structure ?? [],
              imageSize: data.imageSize ?? EMoosaSarConfigImageSize.Medium,
              isColorizingTextMode: data.isColorizingTextMode ?? isColorizingTextMode,
            });
          } catch (error) {
            message.error("Couldn't read parameters from file");
          }
        })
        .catch(() => {
          message.error("Couldn't upload file");
        });

      return false;
    },
  };

  return (
    <div className={styles.importExport}>
      <Space>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Import</Button>
        </Upload>
        <Button icon={<DownloadOutlined />} onClick={exportData}>
          Export
        </Button>
      </Space>
    </div>
  );
};
