import { IStructureRenderer } from '@discngine/moosa-models';
import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import { CellData, SarMatrixCellRendererProps } from '../SarMatrixTable/types';
import { CellRendererData } from '../types';
import { AxisValue } from '../utils/ISarMatrixDataService';

import { ModalWindowProvider } from './ModalWindowContext';
import { SarMatrixCellRendererDataProvider } from './SarMatrixCellRenrererContext';

const SarMatrixContext = createContext<null | State>(null);

interface State {
  cellRenderer: FC<SarMatrixCellRendererProps>;
  structureRenderer: IStructureRenderer;
  numberOfRows: number;
  numberOfColumns: number;
  xAxisValues: AxisValue[];
  yAxisValues: AxisValue[];
}

interface Props {
  /**
   * Required for cell rendering. To get access to all cellRendererData use "useCellRenderer" hook
   * */
  cellRenderer: FC<SarMatrixCellRendererProps>;
  /**
   * Required to render smiles structure
   * */
  structureRenderer: IStructureRenderer;
  /**
   * List of available values by X axis. Required to build cells data
   * */
  xAxisValues: AxisValue[];
  /**
   * List of available values by Y axis. Required to build cells data
   * */
  yAxisValues: AxisValue[];
  /**
   * Data needed to build a cell. Can be accessed by "useCellRenderer" hook
   * */
  cellRendererData: CellRendererData;
}

export const SarMatrixProvider = (props: PropsWithChildren<Props>) => {
  const {
    children,
    cellRenderer,
    structureRenderer,
    cellRendererData,
    xAxisValues,
    yAxisValues,
  } = props;

  const cellsData = useMemo<CellData[][]>(() => {
    if (!yAxisValues.length) {
      // Single R-group
      const row: CellData[] = xAxisValues.map((xGroup) => ({
        axisXGroupId: xGroup.id,
        axisYGroupId: null,
      }));

      return [row];
    }

    return yAxisValues.map((yGroup) => {
      const row: CellData[] = xAxisValues.map((xGroup) => ({
        axisXGroupId: xGroup.id,
        axisYGroupId: yGroup.id,
      }));

      return row;
    });
  }, [xAxisValues, yAxisValues]);

  const numberOfRows = cellsData.length;
  const numberOfColumns = cellsData[0]?.length || 0;

  const value = useMemo(
    () => ({
      cellRenderer,
      structureRenderer,
      numberOfRows,
      numberOfColumns,
      yAxisValues,
      xAxisValues,
    }),
    [
      cellRenderer,
      numberOfColumns,
      numberOfRows,
      structureRenderer,
      xAxisValues,
      yAxisValues,
    ]
  );

  const cellRendererDataInner = useMemo(
    () => ({ ...cellRendererData, cellsData }),
    [cellRendererData, cellsData]
  );

  return (
    <SarMatrixContext.Provider value={value}>
      <ModalWindowProvider>
        <SarMatrixCellRendererDataProvider data={cellRendererDataInner}>
          {children}
        </SarMatrixCellRendererDataProvider>
      </ModalWindowProvider>
    </SarMatrixContext.Provider>
  );
};

export const useSarMatrix = () => {
  const context = useContext(SarMatrixContext);

  if (context === null) {
    throw new Error('SarMatrixContext cannot be used outside its provider');
  }

  return context;
};
