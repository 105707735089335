import { DownOutlined, InfoCircleOutlined, ProfileOutlined } from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { IScoringTemplate } from '@discngine/moosa-models';
import { IColumnMetaInfo } from '@discngine/moosa-store/tableInfo';
import { Dropdown, Input, Menu, Select, Tooltip, Spin } from 'antd';
import React, { UIEvent, useCallback, useMemo, useState } from 'react';

import { useScoringTemplatesContext } from '../../ScoringTemplatesContext';

import styles from './MoosaTemplateSelector.module.less';
import {
  TEMPLATE_MENU_INTERNAL,
  TemplateDetails,
} from './TemplateDetails/TemplateDetails';

const OFFSET = 150;

type MoosaTemplateSelectorProps = {
  template?: IScoringTemplate;
  onSelectTemplate: (templateId: IScoringTemplate) => void;
  datasetColumns: Record<string, IColumnMetaInfo>;
};

export const MoosaTemplateSelector: React.FC<MoosaTemplateSelectorProps> = React.memo(
  ({ template, onSelectTemplate, datasetColumns }) => {
    const parentId = template?.parentId ?? template?._id;

    const [filter, setFilter] = useState('');

    const { hooks } = useScoringTemplatesContext();

    const map = hooks.useItemsMap();

    const parentName = map[parentId ?? '']?.name ?? template?.name;

    const {
      data: templates,
      isLoading,
      loadNext,
      hasMore,
    } = hooks.useInfiniteList({ limit: 20, name: filter === '' ? undefined : filter });

    const loadMore = useCallback(
      (event: UIEvent<HTMLDivElement>) => {
        const { target } = event;
        const targetElement = target as HTMLDivElement;

        const isScrolled =
          targetElement.scrollTop + targetElement.offsetHeight >=
          targetElement.scrollHeight - OFFSET;

        if (isScrolled && hasMore && !isLoading) {
          loadNext();
        }
      },
      [hasMore, isLoading, loadNext]
    );

    const onVersionsLoadSuccess = useCallback(
      (loadedVersions: IScoringTemplate[], params?: { parentId?: string | null }) => {
        const lastVersion = loadedVersions.at(-1);

        if (parentId === params?.parentId && lastVersion) {
          onSelectTemplate(lastVersion);
        }
      },
      [onSelectTemplate, parentId]
    );

    const { data: versions, isLoading: areVersionsLoading } = hooks.useVersions(
      { parentId },
      { onSuccess: onVersionsLoadSuccess }
    );

    const onSelectVersion = useCallback(
      (versionId: string) => {
        const selectedVersion = versions?.find((vers) => vers._id === versionId);

        if (selectedVersion) {
          onSelectTemplate(selectedVersion);
        }
      },
      [versions, onSelectTemplate]
    );

    const templateApplicationStats = useMemo<
      Record<string, { applicable: number; total: number; fullMatch: boolean }>
    >(() => {
      const entries = templates.map((item) => {
        const applicable = item.columns.filter(
          (col) => datasetColumns[col.columnName]
        ).length;
        const total = item.columns.length;

        return [item._id, { applicable, total, fullMatch: applicable === total }];
      });

      return Object.fromEntries(entries);
    }, [datasetColumns, templates]);

    const itemsSorted = useMemo(
      () =>
        [...templates].sort((templateA, templateB) => {
          const statA = templateApplicationStats[templateA._id];
          const statB = templateApplicationStats[templateB._id];

          if (statB.applicable === statB.total && statA.applicable < statA.total) {
            return 1;
          }

          if (statA.applicable === statA.total && statB.applicable < statB.total) {
            return -1;
          }

          return (
            statB.applicable * 100 - statB.total - statA.applicable * 100 + statA.total
          );
        }),
      [templates, templateApplicationStats]
    );

    const itemsFiltered = useMemo(
      () =>
        itemsSorted.filter((item) =>
          item.name.toUpperCase().includes(filter.toUpperCase())
        ),
      [filter, itemsSorted]
    );

    return (
      <Spin spinning={isLoading || areVersionsLoading}>
        <div className={styles.templateSelectsWrap}>
          <div className={styles.dropdownWrap}>
            <div className={styles.dropdown}>
              <Dropdown
                overlay={
                  <div>
                    <Input.Search
                      className={styles.searchTemplate}
                      placeholder="Search template"
                      value={filter}
                      onChange={(event) => setFilter(event.target.value)}
                      onClick={(event) => event.stopPropagation()}
                    />

                    <div className={styles.menuContainer} onScroll={loadMore}>
                      <Menu className={styles.menu} selectable={false}>
                        {itemsFiltered.map((item, index) => {
                          const stats = templateApplicationStats[item._id];

                          return (
                            template?._id !== item._id &&
                            template?.parentId !== item._id && (
                              <Menu.Item
                                key={item._id}
                                onClick={(event) => {
                                  // a click may come from details view
                                  // we can't use stopPropagation as menu does not close

                                  const isInternalMenuClicked = (
                                    event.domEvent.target as Element
                                  ).closest(`.${TEMPLATE_MENU_INTERNAL}`);

                                  if (!isInternalMenuClicked) {
                                    onSelectTemplate(item);
                                  }
                                }}
                              >
                                <div className={styles.menuItem}>
                                  <ProfileOutlined />
                                  <div className={styles.menuItemName} title={item.name}>
                                    {item.name}
                                  </div>
                                  <div
                                    className={classNames(styles.stats, {
                                      [styles.partialMatch]: !stats.fullMatch,
                                      [styles.fullMatch]: stats.fullMatch,
                                    })}
                                  >
                                    {stats.applicable}/{stats.total}
                                  </div>
                                  <TemplateDetails key={index} template={item} />
                                </div>
                              </Menu.Item>
                            )
                          );
                        })}
                      </Menu>
                    </div>
                  </div>
                }
                overlayClassName={styles.templatesOverlay}
                trigger={['click']}
                onOpenChange={() => setFilter('')}
              >
                <div className={styles.dropdownLink} title={parentName}>
                  {template ? (
                    <span>{parentName}</span>
                  ) : (
                    <span>Base scoring template</span>
                  )}
                  &nbsp;
                  <DownOutlined />
                </div>
              </Dropdown>
            </div>
          </div>
          <Select
            className={styles.versionSelect}
            placeholder="version"
            value={template?.versionName || 'initial'}
            onSelect={onSelectVersion}
          >
            {versions?.map((version) => (
              <Select.Option key={version._id} value={version._id}>
                {version.versionName || 'initial'}
                {/*TODO need to replace with popover*/}
                {version.description && (
                  <Tooltip
                    className={styles.tooltip}
                    placement="right"
                    title={version.description}
                  >
                    <InfoCircleOutlined className={styles.infoCircleIcon} />
                  </Tooltip>
                )}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Spin>
    );
  }
);
