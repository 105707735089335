import {
  IScoringTemplatesSubRoot,
  scoringTemplatesWithVersionsSlice,
} from '@discngine/moosa-store/scoringTemplates';
import { combineReducers } from 'redux';

export type RootState = IScoringTemplatesSubRoot;

export const rootReducer = combineReducers({
  scoringTemplatesWithVersions: scoringTemplatesWithVersionsSlice.slice.reducer,
});
