import { IDecisionTree, IMoosaDataService } from '@discngine/moosa-models';

import {
  createVersionisedListSlice,
  DataProviderWithVersions,
} from '@discngine/moosa-store/createVersionisedListSlice';

const DECISION_TREES_WITH_VERSIONS_SLICE_NAME = 'decisionTreesWithVersions' as const;

export const decisionTreesWithVersionsSlice = createVersionisedListSlice<
  typeof DECISION_TREES_WITH_VERSIONS_SLICE_NAME,
  IDecisionTree,
  { skip?: number; limit?: number; name?: string }
>({
  sliceName: DECISION_TREES_WITH_VERSIONS_SLICE_NAME,
});

type DecisionTreeDataProvider = DataProviderWithVersions<
  IDecisionTree,
  { skip?: number; limit?: number; name?: string }
>;

export const getDecisionTreesDataProvider = (
  dataService: IMoosaDataService
): DecisionTreeDataProvider => ({
  getList: (params) => dataService.getDecisionTrees(params ?? {}),
  createItem: (payload) => dataService.createDecisionTree(payload),
  getItem: (payload) => dataService.getDecisionTree(payload),
  updateItem: (payload) => dataService.updateDecisionTree(payload._id, payload),
  deleteItem: dataService.deleteDecisionTree,
  undeleteItem: dataService.undeleteDecisionTree,
  getVersions: (parentId) => dataService.getDecisionTrees({ parentId }),
});
