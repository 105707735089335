import { classNames } from '@discngine/moosa-common';
import { FC, useCallback } from 'react';
import {
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import styles from './DraggableTagWrapper.module.less';
import { TagRenderer } from './TagRendererProps';

interface DraggableTagWrapperProps {
  tagId: string;
  itemIndex: number;
  isDragDisabled: boolean;
  hideReordering: boolean;
  tagRenderer: TagRenderer;
  onDeleteTag: (tagId: string) => void;
}

export const DraggableTagWrapper: FC<DraggableTagWrapperProps> = ({
  tagId,
  itemIndex,
  tagRenderer,
  isDragDisabled,
  hideReordering,
  onDeleteTag,
}) => {
  const TagRenderer = tagRenderer;

  const onRemove = useCallback(() => {
    onDeleteTag(tagId);
  }, [onDeleteTag, tagId]);

  return (
    <Draggable
      key={tagId}
      draggableId={tagId}
      index={itemIndex}
      isDragDisabled={isDragDisabled}
    >
      {(tagDragProvided, snapshot) => {
        return (
          <div
            ref={tagDragProvided.innerRef}
            className={styles.tagWrapper}
            {...tagDragProvided.draggableProps}
            {...tagDragProvided.dragHandleProps}
            style={
              // https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
              hideReordering
                ? getStyle(tagDragProvided.draggableProps.style, snapshot)
                : tagDragProvided.draggableProps.style
            }
          >
            <TagRenderer
              className={classNames(
                styles.tagElement,
                snapshot.isDragging && styles.tagElementDragging,
                Boolean(snapshot.combineTargetFor) && styles.tagElementCombine
              )}
              tagId={tagId}
              onRemove={onRemove}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

// https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
function getStyle(
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
) {
  if (!snapshot.isDragging) return {};

  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    // cannot be 0, but make it super tiny
    transitionDuration: `0.001s`,
  };
}
