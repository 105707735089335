import {
  DatasetRowId,
  ITableDataRowMap,
  ITableDataState,
  SCORE_COLUMN_ID,
} from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitTableInfoPayload, tableInfoSlice } from '@discngine/moosa-store/tableInfo';

const initialState: ITableDataState = {
  isChunkLoading: false,
  sort: { [`mainTemplate.${SCORE_COLUMN_ID}`]: -1 },
  page: 1,
  pageSize: 0,
  selectedRows: {},
  compareMode: false,
  showErrors: false,
  highlightedRows: [],
  rawDataset: {},
  rawDatasetLength: 0,
  isFull: false,
  pageIds: [],
};

const tableSelectionChangeAction = (
  state: ITableDataState,
  rowId: DatasetRowId,
  selected: boolean
) => {
  if (rowId === undefined) {
    return;
  }

  if (selected) {
    state.selectedRows[rowId] = true;
  } else {
    delete state.selectedRows[rowId];
  }
};

export const tableDataSlice = createSlice({
  name: 'tableData',
  initialState,
  reducers: {
    setRawDataset(
      state,
      action: PayloadAction<{ rows: ITableDataRowMap; total: number }>
    ) {
      const rows = action.payload.rows ?? {};
      const rawDatasetLength = Object.keys(rows).length;

      state.rawDataset = rows;
      state.rawDatasetLength = rawDatasetLength;
      state.isFull = rawDatasetLength === action.payload.total;
    },
    appendData(
      state,
      action: PayloadAction<{ page: number; rows: ITableDataRowMap; total: number }>
    ) {
      const { page, rows, total } = action.payload;

      Object.assign(state, {
        page,
        rawDataset: { ...state.rawDataset, ...rows },
      });

      const rawDatasetLength = Object.keys(rows).length;

      state.rawDatasetLength = rawDatasetLength;
      state.isFull = rawDatasetLength === total;
    },
    appendDataAt(
      state,
      action: PayloadAction<{ offset: number; rows: ITableDataRowMap; total: number }>
    ) {
      const { rows } = action.payload;

      if (!state.isFull) {
        state.rawDataset = { ...state.rawDataset, ...rows };
      }

      const rawDatasetLength = Object.keys(rows).length;

      state.rawDatasetLength = rawDatasetLength;
      state.isFull = rawDatasetLength === action.payload.total;
    },
    overwriteData(
      state,
      action: PayloadAction<{ rows: ITableDataRowMap; total: number }>
    ) {
      state.rawDataset = action.payload.rows;
      const rawDatasetLength = Object.keys(action.payload.rows).length;

      state.rawDatasetLength = rawDatasetLength;
      state.isFull = rawDatasetLength === action.payload.total;
    },
    setPaginator(
      state,
      action: PayloadAction<{ page: number; pageSize: number; keys: DatasetRowId[] }>
    ) {
      const { page, pageSize, keys } = action.payload;
      const offset = (page - 1) * pageSize;

      state.pageIds = keys;
      state.page = page;
      state.pageSize = pageSize;

      keys.forEach((key, i) => {
        state.rawDataset[key]['system.rank'] = 1 + i + offset;
      });
    },
    setSortMode(
      state,
      action: PayloadAction<{
        colId: string;
        order: 1 | -1;
        isComparisonColumn: boolean;
      }>
    ) {
      const { colId, order, isComparisonColumn } = action.payload;

      const sortedColumn = isComparisonColumn
        ? `templateForComparison.${SCORE_COLUMN_ID}`
        : `mainTemplate.${colId}`;

      state.sort = {
        [sortedColumn]: order,
      };
    },
    sortTable(state, action) {
      const { sort, rows } = action.payload;

      Object.assign(state, {
        page: 1,
        rows,
        sort,
      });
    },
    toggleTableChunkLoading(state, action: PayloadAction<boolean>) {
      state.isChunkLoading = action.payload;
    },
    tableSelectionChange(
      state,
      action: PayloadAction<{
        rowId: DatasetRowId;
        selected: boolean;
      }>
    ) {
      const { selected, rowId } = action.payload;

      tableSelectionChangeAction(state, rowId, selected);
    },
    tableSelectionBulkChange(
      state,
      action: PayloadAction<{ rowId: DatasetRowId; selected: boolean }[]>
    ) {
      action.payload.forEach(({ rowId, selected }) => {
        tableSelectionChangeAction(state, rowId, selected);
      });
    },
    setCompareMode(state, action: PayloadAction<boolean>) {
      const on = action.payload;

      state.compareMode = on;

      if (!on) {
        state.highlightedRows = [];
        state.selectedRows = {};
      }
    },
    deselectAllRows(state) {
      state.selectedRows = {};
    },
    setShowErrors(state, action: PayloadAction<boolean>) {
      state.showErrors = action.payload;
    },
    setHighlightedRows(state, action: PayloadAction<string[]>) {
      state.highlightedRows = action.payload;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (
      state,
      action: PayloadAction<InitTableInfoPayload>
    ) => {
      Object.assign(state, initialState);
      state.rawDataset = {};
    },
  },
});

export const {
  setRawDataset,
  appendData,
  appendDataAt,
  setPaginator,
  setSortMode,
  sortTable,
  toggleTableChunkLoading,
  tableSelectionChange,
  tableSelectionBulkChange,
  setCompareMode,
  setShowErrors,
  setHighlightedRows,
  deselectAllRows,
} = tableDataSlice.actions;
