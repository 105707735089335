import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { FC, useMemo, ReactElement } from 'react';

import { ISarItem } from '../../../MoosaSarTablePanelProps';

import styles from './MenuRenderer.module.less';

export interface MenuItem {
  label: string;
  icon: ReactElement;
  isEnabled: boolean;
  onClick: (moleculeId: string) => void;
}

interface MenuRendererProps {
  menu: (moleculeId: string) => MenuItem[] | MenuItem[];
  sarItemData: ISarItem;
}

export const MenuRenderer: FC<MenuRendererProps> = ({ menu, sarItemData }) => {
  const menuItems = useMemo(() => {
    if (Array.isArray(menu)) {
      return menu;
    }

    return menu(sarItemData.rowId);
  }, [menu, sarItemData.rowId]);

  return (
    <div className={styles.menuRenderer}>
      <Dropdown
        overlay={
          <>
            <Menu selectable={false}>
              {menuItems.map((item) => (
                <Menu.Item
                  key={item.label}
                  disabled={!item.isEnabled}
                  onClick={() => item.onClick(sarItemData.rowId)}
                >
                  {item.icon} {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </>
        }
        trigger={['click']}
      >
        <Button
          className={styles.menuBtn}
          icon={<EllipsisOutlined />}
          shape="circle"
          type="text"
        />
      </Dropdown>
    </div>
  );
};

export interface MenuOverlayProps {
  sarItemData: ISarItem;
  menu: (moleculeId: string) => MenuItem[] | MenuItem[];
}

export const MenuOverlay: FC<MenuOverlayProps> = ({ sarItemData, menu }) => {
  return (
    <div className={styles.menuOverlay}>
      <MenuRenderer menu={menu} sarItemData={sarItemData} />
    </div>
  );
};
