import Icon from '@ant-design/icons';
import { IStructureRenderer } from '@discngine/moosa-models';
import React, { useCallback } from 'react';

import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import styles from '../MoosaSarTableConfig.module.less';

export interface IMoosaSarConfigCoreItemProps {
  structure: string;
  index: number;
  onRemove: (index: number) => void;
  onShowEditor: (index: number) => void;
  StructureRenderer: IStructureRenderer;
}

export const MoosaSarConfigCoreItem: React.FC<IMoosaSarConfigCoreItemProps> = ({
  structure,
  index,
  onRemove,
  onShowEditor,
  StructureRenderer,
}) => {
  const handleShowEditor = useCallback(() => {
    onShowEditor(index);
  }, [index, onShowEditor]);

  return (
    <div className={styles.coreItem}>
      <div className={styles.coreItemHeader}>
        <div className={styles.coreItemTitle}>Core</div>
        <div>
          <Icon
            className={styles.windowControl}
            component={CloseIcon}
            onClick={() => {
              onRemove(index);
            }}
          />
        </div>
      </div>

      {!!structure && (
        <div onClick={handleShowEditor}>
          <StructureRenderer smiles={structure} />
        </div>
      )}
      {!structure && (
        <div className={styles.coreItemText} onClick={handleShowEditor}>
          Click to draw a core structure
        </div>
      )}
    </div>
  );
};
