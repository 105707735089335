import { PushpinFilled } from '@ant-design/icons';
import { RGroupId } from '@discngine/moosa-cheminformatics-data';
import { Radio } from 'antd';
import React, { FC } from 'react';

export interface ISarMatrixAxisSelectorProps {
  axis: RGroupId[];
  value: RGroupId;
  disabledValue: RGroupId;
  lockedValues: Array<RGroupId>;
  onSelectAxis: (axis: RGroupId) => void;
}

export const SarMatrixAxisSelector: FC<ISarMatrixAxisSelectorProps> = ({
  axis,
  value,
  onSelectAxis,
  disabledValue,
  lockedValues,
}) => (
  <Radio.Group
    buttonStyle="solid"
    optionType="button"
    value={value}
    onChange={(event) => onSelectAxis(event.target.value)}
  >
    {axis.map((axis) => (
      <Radio.Button key={axis} disabled={disabledValue === axis} value={axis}>
        {lockedValues.includes(axis) && <PushpinFilled />}
        {axis}
      </Radio.Button>
    ))}
  </Radio.Group>
);
