import { DEFAULT_COLORS } from '@discngine/moosa-common';
import { FC } from 'react';

import { ColorPickerElement } from './ColorPickerElement';
import styles from './ColorPickerPanel.module.less';

type ColorPickerPanelPureProps = {
  color?: string;
  onColorChange?: (color: string) => void;
};

export const ColorPickerPanelPure: FC<ColorPickerPanelPureProps> = ({
  color = '#999',
  onColorChange,
}) => {
  return (
    <div className={styles.colorWrapper}>
      {DEFAULT_COLORS.map((defaultColor, index) => (
        <ColorPickerElement
          key={index}
          color={defaultColor}
          isActive={defaultColor === color}
          onColorSelect={() => onColorChange?.(defaultColor)}
        />
      ))}
    </div>
  );
};
