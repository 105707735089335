import { ITableInfoSubRoot } from '@discngine/moosa-models';
import { createSelector } from '@reduxjs/toolkit';

export const selectTableInfo = (state: ITableInfoSubRoot) => state.tableInfo;

export const selectTableId = (state: ITableInfoSubRoot) => state.tableInfo.id;

export const selectTableTotalRows = (state: ITableInfoSubRoot) => state.tableInfo.numRows;

export const selectTableMissingValues = (state: ITableInfoSubRoot) =>
  state.tableInfo.missingValues;

export const selectTableTotalColumns = (state: ITableInfoSubRoot) =>
  state.tableInfo.numColumns;

export const selectTableInfoIsLoading = (state: ITableInfoSubRoot) =>
  state.tableInfo.isLoading;

export const selectTableColumns = (state: ITableInfoSubRoot) => state.tableInfo.columns;

export const selectTableColumnIds = (state: ITableInfoSubRoot) =>
  state.tableInfo.columnIds;

export const selectTableColumnsMap = (state: ITableInfoSubRoot) =>
  state.tableInfo.columnsMap;

export const selectTableColumnIndexes = (state: ITableInfoSubRoot) =>
  state.tableInfo.columnIndexes;

export const selectTableScoringTemplateId = (state: ITableInfoSubRoot) =>
  state.tableInfo.scoringTemplateId?.id;

export const selectTableComparisonScoringTemplateId = (state: ITableInfoSubRoot) =>
  state.tableInfo.comparisonScoringTemplateId?.id;

export const selectIsTemplatesComparisonMode = (state: ITableInfoSubRoot) =>
  state.tableInfo.isTemplatesComparisonMode;

export const selectSiderWidth = createSelector(
  selectTableInfo,
  (tableInfo) => tableInfo.siderWidth
);
