export enum JobStatus {
  NotStarted = '',
  Started = 'Started',
  Finished = 'Finished',
  Failed = 'Failed',
  Deleted = 'Deleted',
}

export interface IJobStatus {
  id: string;
  status: JobStatus;
  info?: string;
}
