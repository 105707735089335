import { colorGenerator, hexToRgb } from '@discngine/moosa-common';
import {
  IColumnMetaInfo,
  IMoosaSarConfigTag,
  SCORE_COLUMN_ID,
  FieldType,
} from '@discngine/moosa-models';

export const buildTag = (
  columnId: string,
  tableColumnsMap: Record<string, IColumnMetaInfo>,
  templateColumnsIds: string[],
  isSimpleColorSelector?: boolean
): IMoosaSarConfigTag => {
  const isScoringColumn = columnId === SCORE_COLUMN_ID;
  const isScoreAvailable = templateColumnsIds.includes(columnId) || isScoringColumn;

  const range = {
    min: tableColumnsMap[columnId]?.statistics?.min ?? 0,
    max: tableColumnsMap[columnId]?.statistics?.max ?? 0,
  };

  const gradient = isSimpleColorSelector
    ? {
        scoredGradient: [{ color: hexToRgb(colorGenerator.next().value), percent: 0 }],
        datasetGradient: [],
      }
    : {
        scoredGradient: [
          { color: { r: 255, g: 77, b: 79 }, percent: 0 },
          { color: { r: 255, g: 236, b: 61 }, percent: 50 },
          { color: { r: 115, g: 209, b: 61 }, percent: 100 },
        ],
        datasetGradient: [
          { color: { r: 255, g: 77, b: 79 }, percent: 0 },
          { color: { r: 255, g: 236, b: 61 }, percent: 50 },
          { color: { r: 115, g: 209, b: 61 }, percent: 100 },
        ],
      };

  const isDiscreteProperty = tableColumnsMap[columnId]?.isDiscreteColumn;

  const newTag: IMoosaSarConfigTag = {
    columnId: columnId,
    range,
    isDiscreteProperty,
    isColored: true,
    isScoredColorizingMode: false,
    isScoreAvailable,
    isScoringColumn,
    isDatasetAvailable:
      range.min !== range.max || tableColumnsMap[columnId]?.type === FieldType.String,
    gradient,
    discretePropertyValues:
      tableColumnsMap[columnId]?.statistics?.textCategories?.map((category) => ({
        value: category.value,
        color: { r: 255, g: 236, b: 61 },
      })) || [],
  };

  return newTag;
};

export const addTagToPanel = (
  columns: IMoosaSarConfigTag[][],
  maxTagsInRow: number,
  newTag: IMoosaSarConfigTag
): IMoosaSarConfigTag[][] => {
  const clonedTags = [...columns];

  if (clonedTags.length > 0 && clonedTags[clonedTags.length - 1].length < maxTagsInRow) {
    clonedTags[clonedTags.length - 1] = [...clonedTags[clonedTags.length - 1], newTag];
  } else {
    clonedTags.push([newTag]);
  }

  return clonedTags;
};

export const addTag = (
  columnId: string,
  tableColumnsMap: Record<string, IColumnMetaInfo>,
  columns: IMoosaSarConfigTag[][],
  templateColumnsIds: string[],
  maxTagsInRow: number,
  isSimpleColorSelector?: boolean
): IMoosaSarConfigTag[][] => {
  const newTag = buildTag(
    columnId,
    tableColumnsMap,
    templateColumnsIds,
    isSimpleColorSelector
  );

  return addTagToPanel(columns, maxTagsInRow, newTag);
};
