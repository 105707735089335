import {
  MMPDataService,
  ChemoinformaticsJobError,
} from '@discngine/moosa-cheminformatics-data';
import { JobQueryInputFormat } from '@discngine/moosa-cheminformatics-data';
import {
  DatasetRowId,
  ITableDataRow,
  FieldType,
  JobStatus,
} from '@discngine/moosa-models';
import { selectRawDataset } from '@discngine/moosa-store/tableData';
import sortBy from 'lodash/sortBy';
import { getErr } from 'src/utils/helpers';

import { AppThunk } from '../store';

import { IMatchedMolecule } from './ISarRadiantChartState';
import {
  selectAtomsSelection,
  selectCoreID,
  selectMaxCompounds,
  selectMolFile,
  selectRequestConfig,
} from './sarRadiantChart.selector';
import { setJobStatus, setPairs } from './sarRadiantChart.slice';

export function addMMPCalculationJob(datasource: MMPDataService): AppThunk {
  return async function addMMPCalculationJob(dispatch, getState) {
    const state = getState();
    let queryInputFormat: JobQueryInputFormat = JobQueryInputFormat.Smiles;
    let protectedAtomsIds: Array<number> = [];
    const coreID = selectCoreID(state);
    const dataset: Record<DatasetRowId, ITableDataRow> = selectRawDataset(state);
    const tableID = state.tableInfo.id;
    const structureColumnName =
      Object.keys(state.tableConfig.columnTypes[tableID]).find(
        (key) => state.tableConfig.columnTypes[tableID][key] === FieldType.Structure
      ) || 'Canonical_Smiles';

    const items = Object.values(dataset).map((item) => {
      return {
        id: item.key,
        SMILES: `${item[structureColumnName]}`,
      };
    });

    const coreItem = items.find((item) => item.id === coreID);

    if (!coreItem) {
      return dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: 'No core item selected',
        })
      );
    }

    const maxCompounds = selectMaxCompounds(state);

    const itemsToSend = items.filter((x) => x.id !== coreItem.id).slice(0, maxCompounds);
    let query = coreItem.SMILES;
    const molFile = selectMolFile(state);

    if (molFile) {
      queryInputFormat = JobQueryInputFormat.MOLFile;
      const selection = selectAtomsSelection(state);

      query = molFile;

      if (selection) {
        protectedAtomsIds = selection.atoms || [];
      }
    }

    try {
      const requestParamsString = selectRequestConfig(state);
      const requestParams = JSON.parse(requestParamsString);
      const response = await datasource.createMMPJob(
        {
          query_input_format: queryInputFormat,
          query: query,
          compounds: itemsToSend.map((item) => [item.id, item.SMILES]),
          bond_mapper: {
            pattern: '',
            bond_in_ring: false,
            bond_type: 'SINGLE',
          },
          bonds_to_split: [],
          protected_atoms_idx: protectedAtomsIds,
        },
        requestParams
      );

      dispatch(
        setJobStatus({
          id: response.job_id,
          status: JobStatus.Started,
        })
      );

      dispatch(getMMPCalculationJobResults(datasource, response.job_id, 100, 3000));
    } catch (err) {
      console.error(err);

      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: getErr(err),
        })
      );
    }
  };
}

export function getMMPCalculationJobResults(
  datasource: MMPDataService,
  jobID: string,
  maxAttempts: number,
  intervalBetweenAttempts: number = 3000
): AppThunk {
  return async function getMMPCalculationJobResultsInner(dispatch) {
    try {
      const { signal } = new AbortController();

      const results = await datasource.pollJob(
        {
          job_id: jobID,
        },
        maxAttempts,
        intervalBetweenAttempts,
        signal
      );

      if (!results?.pairs_group) return;

      const resultMap: Record<string, IMatchedMolecule> = {};

      for (let group of results.pairs_group) {
        const { core, fragment: groupFragment } = group;

        for (let pair of group.pairs) {
          const { fragment, parent_id } = pair;

          const mol: IMatchedMolecule = {
            core: core,
            groupFragment,
            fragment,
            id: parent_id,
            parameterValue: 0,
          };

          const existingMol = resultMap[mol.id];

          if (
            !existingMol ||
            (existingMol && existingMol.core.length < mol.core.length)
          ) {
            resultMap[mol.id] = mol;
          }
        }
      }

      const mols = sortBy(Object.values(resultMap), function (mol) {
        return mol.core.length;
      });

      dispatch(
        setJobStatus({
          id: jobID,
          status: JobStatus.Finished,
        })
      );

      dispatch(setPairs(mols));
    } catch (err) {
      console.error(err);

      dispatch(
        setJobStatus({
          id: jobID,
          status: JobStatus.Failed,
          info: getErr(err),
        })
      );

      if (getErr(err) === ChemoinformaticsJobError.MaxAttemptExceed) {
        await datasource.deleteJob(jobID);
      }
    }
  };
}

export function deleteMMPJob(datasource: MMPDataService, jobID: string): AppThunk {
  return async function addMMPCalculationJob(dispatch, getState) {
    try {
      await datasource.deleteJob(jobID);

      dispatch(
        setJobStatus({
          id: jobID,
          status: JobStatus.Deleted,
        })
      );
    } catch (err) {
      dispatch(
        setJobStatus({
          id: jobID,
          status: JobStatus.Failed,
          info: getErr(err),
        })
      );
    }
  };
}
