import { ColumnId, IColumnHeader } from '@discngine/moosa-models';
import { selectScoringTemplate } from '@discngine/moosa-store/scoringTemplate';
import { IMoosaTemplatesPanelContext } from '@discngine/moosa-templates-panel';
import { useCallback, useMemo } from 'react';

import { MoosaTemplatesWidgetDataProps } from './MoosaTemplatesWidgetProps';
import { store } from './store';

type IColumn = {
  columnHeaders: IColumnHeader[];
  columnNames: Record<ColumnId, string>;
};

export function useTemplatePanelProvider(
  props: MoosaTemplatesWidgetDataProps & IColumn
): IMoosaTemplatesPanelContext {
  const {
    columnHeaders,
    columnNames,
    onCreateNewTemplate: onCreateNewTemplateProp,
    onSelectTemplate: onSelectTemplateProp,
    onDeleteTemplate: onDeleteTemplateProp,
    onTemplateChange: onTemplateChangeProp,
  } = props;

  const onCreateNewTemplate = useCallback(() => {
    onCreateNewTemplateProp(selectScoringTemplate(store.getState()));
  }, [onCreateNewTemplateProp]);

  const onSelectTemplate = useCallback(
    () => onSelectTemplateProp(selectScoringTemplate(store.getState())),
    [onSelectTemplateProp]
  );

  const onDeleteScoringTemplate = useCallback(
    () => onDeleteTemplateProp(selectScoringTemplate(store.getState())),
    [onDeleteTemplateProp]
  );

  const onTemplateChange = useCallback(
    () => onTemplateChangeProp(selectScoringTemplate(store.getState())),
    [onTemplateChangeProp]
  );

  const onAddColumnToScoring = onTemplateChange;
  const onToggleColumn = onTemplateChange;
  const onDeleteColumn = onTemplateChange;
  const onChangeTemplateColumnOrder = onTemplateChange;
  const onScoringWeightChange = onTemplateChange;
  const onRangeFilterFromChange = onTemplateChange;
  const onRangeFilterToChange = onTemplateChange;
  const onColumnPropertyFieldSelectorChange = onTemplateChange;
  const onMissingValueReplaceXChange = onTemplateChange;
  const onMissingValueReplaceYChange = onTemplateChange;
  const onMissingValueSelect = onTemplateChange;
  const onMissingValueSelectSecond = onTemplateChange;
  const onErrorModeChange = onTemplateChange;
  const onMissingValueReplacementColumnChange = onTemplateChange;
  const onDesirabilityChangeParam = onTemplateChange;
  const onDesirabilityAddPoint = onTemplateChange;
  const onDesirabilityRemovePoint = onTemplateChange;
  const onDesirabilityAfterMovePoint = onTemplateChange;
  const onDesirabilityFunctionChange = onTemplateChange;
  const onResetChanges = onTemplateChange;
  const onChangeColumnColor = onTemplateChange;

  return useMemo<IMoosaTemplatesPanelContext>(
    () => ({
      columnHeaders,
      columnNames,
      onAddColumnToScoring,
      onCreateNewTemplate,
      onDeleteScoringTemplate,
      onSelectTemplate,
      onToggleColumn,
      onDeleteColumn,
      onScoringWeightChange,
      onChangeTemplateColumnOrder,
      onRangeFilterFromChange,
      onRangeFilterToChange,
      onColumnPropertyFieldSelectorChange,
      onMissingValueReplaceXChange,
      onMissingValueReplaceYChange,
      onMissingValueSelect,
      onMissingValueSelectSecond,
      onMissingValueReplacementColumnChange,
      onErrorModeChange,
      onDesirabilityChangeParam,
      onDesirabilityAddPoint,
      onDesirabilityRemovePoint,
      onDesirabilityAfterMovePoint,
      onDesirabilityFunctionChange,
      onResetChanges,
      onChangeColumnColor,
    }),
    [
      columnHeaders,
      columnNames,
      onAddColumnToScoring,
      onCreateNewTemplate,
      onDeleteScoringTemplate,
      onSelectTemplate,
      onToggleColumn,
      onDeleteColumn,
      onScoringWeightChange,
      onChangeTemplateColumnOrder,
      onRangeFilterFromChange,
      onRangeFilterToChange,
      onColumnPropertyFieldSelectorChange,
      onMissingValueReplaceXChange,
      onMissingValueReplaceYChange,
      onMissingValueSelect,
      onMissingValueSelectSecond,
      onMissingValueReplacementColumnChange,
      onErrorModeChange,
      onDesirabilityChangeParam,
      onDesirabilityAddPoint,
      onDesirabilityRemovePoint,
      onDesirabilityAfterMovePoint,
      onDesirabilityFunctionChange,
      onResetChanges,
      onChangeColumnColor,
    ]
  );
}
