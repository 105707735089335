export const RADAR_COLUMN_ID = 'radar';
export const SCORE_COLUMN_ID = 'score';
export const MISSING_VALUES_COLUMN_ID = 'missingColumns';
export const COMPARISON_SCORE_COLUMN_ID = 'comparisonScore';
export const CID = 'CID';
export const ROW_NUMBER_COLUMN_ID = '__key';
export const NEW_CALC_COLUMN_ID = 'new_calc_key';
export const PAGE_SIZE = 100;
export const MAX_DATASET = 30000;

export const DEFAULT_COLUMN_NAMES: { [key: string]: string } = {
  [SCORE_COLUMN_ID]: 'Scoring Profile',
  [COMPARISON_SCORE_COLUMN_ID]: 'Comparison scoring',
  [MISSING_VALUES_COLUMN_ID]: 'Missing values',
  [RADAR_COLUMN_ID]: 'Radar',
  [CID]: 'CID',
};
