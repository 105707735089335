import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useCopy } from '@discngine/moosa-structure-renderer';
import { Button } from 'antd';
import React from 'react';
import { forwardRef, RefObject, useCallback, useImperativeHandle, useState } from 'react';

import styles from './CopyMoleculeButton.module.less';

interface CopyMoleculeButtonProps {
  id: string;
  structure: string;
  svgRef: RefObject<SVGSVGElement>;
  className: string;
}
export interface RefType {
  onSetCopiedToFalse: () => void;
}

export const CopyMoleculeButton = forwardRef<RefType, CopyMoleculeButtonProps>(
  ({ className, svgRef, id, structure }, ref) => {
    const [copied, setCopied] = useState(false);
    const onSetCopiedToFalse = () => {
      setCopied(false);
    };

    useImperativeHandle(ref, () => ({ onSetCopiedToFalse }));

    const copy = useCopy();

    const handleCopy = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();
        copy(structure, svgRef?.current?.outerHTML ?? '', id ?? '');
        setCopied(true);
      },
      [copy, id, structure, svgRef]
    );

    const style = `${styles.copyButton} ${
      copied ? styles.copyButtonActive : null
    } ${className}`;

    return (
      <Button
        className={style}
        icon={copied ? <CheckOutlined /> : <CopyOutlined />}
        shape="circle"
        size="small"
        type="text"
        onClick={handleCopy}
      />
    );
  }
);
