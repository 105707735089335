import {
  FieldType,
  ColumnType,
  isScoringType,
  SpecialColumn,
} from '@discngine/moosa-models';
import { PINNED_COLUMNS } from '@discngine/moosa-store/tableConfig';

export const isScoring = (colType: ColumnType, isDiscreteColumn?: boolean) =>
  isScoringType(colType, isDiscreteColumn);
export const isSystem = (colType: ColumnType) =>
  PINNED_COLUMNS.includes(colType) && colType !== SpecialColumn.Comparison;
export const isPinned = (colType: ColumnType) => PINNED_COLUMNS.includes(colType);
export const isAlwaysVisible = (colType: ColumnType) =>
  [SpecialColumn.Score, SpecialColumn.Radar, FieldType.Structure].includes(colType);
