import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line, LineRadial } from '@visx/shape';
import { Text, TextProps } from '@visx/text';
import { theme } from 'antd';
import { SVGProps, useMemo } from 'react';

import { genAngles, genPoints, genPolygonPoints, getLabels, zeroPoint } from './helpers';
import styles from './RadarChart.module.less';

const DEFAULT_MARGINS = { left: 16, right: 16, top: 16, bottom: 16 };
const DEGREES = 360;

const RADIAL_SCALE = scaleLinear<number>({
  range: [0, Math.PI * 2],
  domain: [DEGREES, 0],
});

interface Props {
  data: { points: number[]; props?: SVGProps<SVGPolygonElement> }[];
  width: number;
  height: number;
  margin?: { top: number; bottom: number; left: number; right: number };
  levels?: number;
  labels?: { label: string; props?: TextProps }[];
}

export const RadarChart = (props: Props) => {
  const {
    margin = DEFAULT_MARGINS,
    height,
    width,
    data,
    levels = 0,
    labels = [],
  } = props;

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const radius = Math.min(xMax, yMax) / 2;

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [0, radius],
        domain: [0, 1],
      }),
    [radius]
  );

  const dataSize = data[0].points.length;

  const polygons = useMemo(
    () =>
      data.map((item) => ({
        ...genPolygonPoints({ data: item.points, scale: yScale }),
        props: item.props,
      })),
    [data, yScale]
  );

  const points = useMemo(() => genPoints({ size: dataSize, radius }), [dataSize, radius]);

  const webs = useMemo(() => genAngles(dataSize), [dataSize]);

  const formattedLabels = useMemo(() => getLabels({ labels, radius }), [labels, radius]);

  return (
    <svg
      className={styles.root}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Group left={width / 2} top={height / 2}>
        {[...new Array(levels)].map((_, i) => (
          <LineRadial
            key={`web-${i}`}
            angle={(dd) => RADIAL_SCALE(dd.angle) ?? 0}
            data={webs}
            fill="none"
            radius={((i + 1) * radius) / dataSize}
            stroke="#C1C1C1"
            strokeLinecap="round"
            strokeWidth={1}
          />
        ))}

        {points.map((point, idx) => (
          <Line key={`radar-line-${idx}`} from={zeroPoint} stroke="black" to={point} />
        ))}

        {polygons.map((x, idx) => {
          return (
            <polygon
              key={`${x.pointString}-${idx}`}
              className={styles.polygon}
              fill="transparent"
              points={x.pointString}
              stroke={theme.defaultSeed.colorPrimary}
              strokeWidth={2}
              {...x.props}
            />
          );
        })}

        {formattedLabels.map((item) => {
          const { x, y, label, props: labelProps, width: textWidth } = item;

          return (
            <Text
              key={label}
              fontSize={9}
              fontWeight="bold"
              textAnchor="middle"
              transform={`rotate(180,${x},${y - 2})`}
              width={textWidth}
              x={x}
              y={y}
              {...labelProps}
            >
              {label}
            </Text>
          );
        })}
      </Group>
    </svg>
  );
};
