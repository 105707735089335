import Icon from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { FC } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { ReactComponent as dndIcon } from '../MultiplePropertiesSelector/resources/drag-n-drop.svg';

import styles from './DraggableTagsRow.module.less';
import { DraggableTagWrapper } from './DraggableTagWrapper';
import { TagId, TagRenderer, TYPE_TAG } from './TagRendererProps';

interface TagsDropZoneProps {
  tags: TagId[];
  tagsRowId: string;
  dragTagRowId: string | null;
  tagRenderer: TagRenderer;
  isNewRow: boolean;
  isDropDisabled: boolean;
  maxTagsInRow: number;
  onDeleteTag: (tagId: string) => void;
}

const TagsDropZone: FC<TagsDropZoneProps> = ({
  tags,
  tagsRowId,
  dragTagRowId,
  tagRenderer,
  isNewRow,
  isDropDisabled,
  maxTagsInRow,
  onDeleteTag,
}) => {
  // prevent adding a new tag to a full row
  const cantDropMore = tags.length === maxTagsInRow && dragTagRowId !== tagsRowId;

  return (
    <Droppable
      direction={'horizontal'}
      droppableId={tagsRowId}
      isCombineEnabled={true}
      isDropDisabled={isDropDisabled}
      type={TYPE_TAG}
    >
      {(dropProvided, dropSnapshot) => {
        const isDraggingOver = dropSnapshot.isDraggingOver;
        const isDraggingFrom = Boolean(dropSnapshot.draggingFromThisWith);

        return (
          <div
            ref={dropProvided.innerRef}
            className={classNames(
              styles.tagsRowBody,
              isDraggingOver && styles.isDraggingOver,
              isDraggingFrom && styles.isDraggingFrom,
              isNewRow && styles.newRow,
              styles['perRow' + maxTagsInRow]
            )}
            {...dropProvided.droppableProps}
          >
            {isNewRow && <div className={styles.newRowText}>Add to new line</div>}
            {tags.map((tagId, itemIndex) => {
              return (
                <DraggableTagWrapper
                  key={tagId}
                  hideReordering={cantDropMore}
                  isDragDisabled={maxTagsInRow === 1}
                  itemIndex={itemIndex}
                  tagId={tagId}
                  tagRenderer={tagRenderer}
                  onDeleteTag={onDeleteTag}
                />
              );
            })}
            <span
              style={
                // https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
                isNewRow || cantDropMore ? { display: 'none' } : undefined
              }
            >
              {dropProvided.placeholder}
            </span>
          </div>
        );
      }}
    </Droppable>
  );
};

interface IDraggableTagsRowProps {
  isNewRow?: boolean;
  isDropDisabled: boolean;
  isDragDisabled: boolean;
  rowId: string;
  rowIndex: number;
  tagsRow: string[];
  dragTagRowId: string | null;
  onDeleteTag: (tagId: string) => void;
  tagRenderer: TagRenderer;
  maxTagsInRow: number;
}

export const DraggableTagsRow: FC<IDraggableTagsRowProps> = ({
  isNewRow,
  isDropDisabled,
  isDragDisabled,
  rowId,
  rowIndex,
  dragTagRowId,
  tagsRow,
  onDeleteTag,
  tagRenderer,
  maxTagsInRow,
}) => {
  return (
    <Draggable draggableId={rowId} index={rowIndex} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            className={classNames(
              styles.tagsRow,
              snapshot.isDragging && styles.tagsRowDragging
            )}
            {...provided.draggableProps}
          >
            <div className={styles.tagsRowHandle} {...provided.dragHandleProps}>
              <Icon component={dndIcon} />
            </div>
            <TagsDropZone
              dragTagRowId={dragTagRowId}
              isDropDisabled={isDropDisabled}
              isNewRow={Boolean(isNewRow)}
              maxTagsInRow={maxTagsInRow}
              tagRenderer={tagRenderer}
              tags={tagsRow}
              tagsRowId={rowId}
              onDeleteTag={onDeleteTag}
            />
          </div>
        );
      }}
    </Draggable>
  );
};
