/* eslint-disable @typescript-eslint/no-use-before-define */
import { IColorPalette } from '@discngine/moosa-common';
import { DatasetRowId, IColumnHeader } from '@discngine/moosa-models';
import { IRowsAndScores } from '@discngine/moosa-store/tableData';

export interface IChartsSubRoot {
  chartsData: IChartsState;
}

export interface IChartsState {
  isShown: boolean;
  isLoading: boolean;
  isFirstOpen: boolean;
  currentChartType: ChartType;
  smartChartState: ISmartChartState;
  scatterPlotState: IScatterPlotState;
  fullDataset: IRowsAndScores;
}

export interface ISmartChartOptions {
  iterations: number;
  neighbors: number;
  minDist: number; // 0..1
}

export enum SizeSortOrder {
  asc = 'asc',
  dsc = 'dsc',
}

interface ICommonPlotState {
  points: IScatterPlotPoint[];
  rowIds: DatasetRowId[];
  colorColumn: IColumnHeader | null;
  colorPalette: IColorPalette;
  sizeColumn: IColumnHeader | null;
  sizeSortOrder: SizeSortOrder;
}

export interface ISmartChartState extends ICommonPlotState {
  range: IChartRange;
  options: ISmartChartOptions;
  maxIterations: number;
  currentIteration: number;
  calculationState: CalculationState;
}

export interface IScatterPlotState extends ICommonPlotState {
  columnX: IColumnHeader | null;
  columnY: IColumnHeader | null;
}

export interface IChartRange {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

export interface IScatterPlotPoint {
  x: number;
  y: number;
  size: number;
  color: string;
}

export enum ChartType {
  SmartChart = 'SmartChart',
  PointChart = 'PointChart',
}

export enum CalculationState {
  None = 'None',
  NotCreated = 'NotCreated',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Failed = 'Failed',
}
