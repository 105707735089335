export function humanFileSize(bytes: number) {
  const thresh = 1024;
  let size = bytes;
  const units = ['Kb', 'Mb', 'Gb', 'Tb'];
  let counter = -1;

  if (bytes < thresh) return bytes + ' byte';

  do {
    size /= thresh;
    ++counter;
  } while (size >= thresh && counter < units.length - 1);

  return `${size.toFixed(2)} ${units[counter]}`;
}

const formatter = new Intl.DateTimeFormat('en-GB');

export function fullDateToGBFormat(date: Date) {
  return formatter.format(date);
}
