import React, { MouseEvent } from 'react';

import { CellFactor, IFactorPie } from '../types';

export type PieChartProps = {
  data: CellFactor<IFactorPie>;
  onMouseOver: (event: MouseEvent) => void;
};

function getCoordinatesForPercent(percent: number) {
  const x = Math.cos(2 * Math.PI * percent);
  const y = Math.sin(2 * Math.PI * percent);

  return [x, y];
}

export const PieChart = ({ data, onMouseOver }: PieChartProps) => {
  let cumulativePercent = 0;

  return (
    <svg
      data-id={data.substanceId}
      style={{ transform: 'rotate(-0.25turn)' }}
      viewBox="-1 -1 2 2"
      onMouseOver={onMouseOver}
    >
      <circle cx="0" cy="0" fill="none" r="1" stroke="#eeeeee" strokeWidth="0.03" />
      {data.charts.map((item) => {
        const [startX, startY] = getCoordinatesForPercent(cumulativePercent);
        const largeArcFlag = item.percent / 100 > 0.5 ? 1 : 0;

        cumulativePercent += item.percent / 100;

        const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

        return (
          <path
            key={item.colId}
            d={`M ${startX} ${startY}
               A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}
               L 0 0`}
            fill={item.color}
          />
        );
      })}
    </svg>
  );
};
