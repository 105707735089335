import { DiscretePropertyValue, IGradient } from './sarConfig';

export enum MMPView {
  Table = 'Table',
  RadiantChart = 'Radiant Chart',
  MultipleRadiantChart = 'Multiple Radiant Chart',
}

export interface IMoosaMMPConfigTag {
  columnId: string;
  range: { min: number; max: number };
  isDiscreteProperty: boolean;
  isScoredColorizingMode: boolean;
  isColored: boolean;
  gradient: IGradient;
  discretePropertyValues: DiscretePropertyValue[];
  isScoreAvailable: boolean;
  isDatasetAvailable: boolean;
  isScoringColumn: boolean;
}
