import { Smiles } from '@discngine/moosa-models';

export type RGroupId = string;
export type SubstituentId = string;
export type CompoundTuple = [string, Smiles]; // as [ID, SMILES]

export enum BondTypes {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  TRIPLE = 'TRIPLE',
}

export enum JobStatus {
  finished = 'finished',
  job_not_found = 'job not found',
  failed = 'failed',
  started = 'started',
  queued = 'queued',
}

export enum JobPriority {
  low = 'low',
  default = 'default',
  high = 'high',
}

export enum JobQueryInputFormat {
  Smiles = 'smiles',
  MOLFile = 'molfile',
}

export enum BooleanFlag {
  False = 'False',
  True = 'True',
}

export enum ChemoinformaticsJobError {
  JobCancelled = 'JobCancelled',
  MaxAttemptExceed = 'MaxAttemptExceed',
  JobFailed = 'JobFailed',
}
