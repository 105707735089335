import {
  IColumnLabelMap,
  IColumnMetaInfo,
  IMoosaSarConfigTag,
} from '@discngine/moosa-models';
import { Select } from 'antd';
import { useCallback, useState } from 'react';
import { DragDropContext, DropResult, Droppable, DragStart } from 'react-beautiful-dnd';

import { MoosaSarConfigTagsRow } from './MoosaSarConfigTagsRow/MoosaSarConfigTagsRow';
import styles from './MultiplePropertiesSelector.module.less';
import { addTag } from './utils/addTag';

export interface IMultiplePropertiesSelectorProps {
  columns: IMoosaSarConfigTag[][];
  onColumnsChange: (columns: IMoosaSarConfigTag[][]) => void;
  tableColumnsMap: Record<string, IColumnMetaInfo>;
  templateColumnsIds: string[];
  maxTagsInRow?: number;
  columnLabelMap?: IColumnLabelMap;
  allowOneProperty?: boolean;
  isSimpleColorSelector?: boolean;
  options: { label: string; value: string }[];
}

const NEW_LINE_ID = 'newLine';
const TYPE_ROW = 'ROW';

export const MultiplePropertiesSelector: React.FC<IMultiplePropertiesSelectorProps> = ({
  columns,
  columnLabelMap,
  onColumnsChange,
  tableColumnsMap,
  templateColumnsIds,
  maxTagsInRow = 3,
  allowOneProperty,
  isSimpleColorSelector = false,
  options,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [draggingItem, setDraggingItem] = useState({ row: -1, index: -1 });
  const [isNewLineVisible, setNewLineVisibility] = useState(false);

  const addNewTag = useCallback(
    (value: string) => {
      const clonedTags = addTag(
        value,
        tableColumnsMap,
        allowOneProperty ? [] : columns,
        templateColumnsIds,
        maxTagsInRow,
        isSimpleColorSelector
      );

      setInputValue(null);
      onColumnsChange(clonedTags);
    },
    [
      allowOneProperty,
      columns,
      maxTagsInRow,
      onColumnsChange,
      tableColumnsMap,
      templateColumnsIds,
      isSimpleColorSelector,
    ]
  );

  const handleRowChange = useCallback(
    (rowId: number, tagsRow: IMoosaSarConfigTag[]) => {
      const newTags = [...columns];

      if (tagsRow.length > 0) {
        newTags[rowId] = tagsRow;
      } else {
        newTags.splice(rowId, 1);
      }

      onColumnsChange(newTags);
    },
    [columns, onColumnsChange]
  );

  const dragEndHandler = useCallback(
    (result: DropResult) => {
      setNewLineVisibility(false);
      setDraggingItem({ row: -1, index: -1 });

      if (result.reason === 'CANCEL' || !result.destination) {
        return;
      }

      if (result.type === TYPE_ROW) {
        const newColumns = [...columns];
        const target = newColumns[result.source.index];

        newColumns.splice(result.source.index, 1);
        newColumns.splice(result.destination.index, 0, target);
        onColumnsChange(newColumns);

        return;
      }

      // Same row and column
      if (
        result.destination.droppableId === result.source.droppableId &&
        result.destination.index === result.source.index
      ) {
        return;
      }

      // Impossible to add dragged item near the destination
      if (result.destination.index > maxTagsInRow - 1) {
        return;
      }

      const newColumns = [...columns];

      const sRowIndex = parseInt(result.source.droppableId);
      const sItemIndex = result.source.index;
      const sTarget = newColumns[sRowIndex][sItemIndex];

      const dRowIndex =
        result.destination.droppableId === NEW_LINE_ID
          ? newColumns.push([]) - 1
          : parseInt(result.destination.droppableId);
      const dItemIndex = result.destination.index;
      const dTarget = newColumns[dRowIndex][dItemIndex];

      newColumns[sRowIndex] = [...newColumns[sRowIndex]];
      newColumns[dRowIndex] = [...newColumns[dRowIndex]];

      // swap
      if (
        result.destination.droppableId !== NEW_LINE_ID &&
        result.destination.droppableId !== result.source.droppableId &&
        columns[Number(result.destination.droppableId)]?.length === maxTagsInRow
      ) {
        newColumns[sRowIndex].splice(sItemIndex, 1, dTarget);
        newColumns[dRowIndex].splice(dItemIndex, 1, sTarget);
      }
      // move
      else {
        newColumns[sRowIndex].splice(sItemIndex, 1);
        newColumns[dRowIndex].splice(dItemIndex, 0, sTarget);

        if (newColumns[sRowIndex].length === 0) {
          newColumns.splice(sRowIndex, 1);
        }
      }

      onColumnsChange(newColumns);
    },
    [columns, maxTagsInRow, onColumnsChange]
  );

  const dragStartHandler = useCallback((start: DragStart) => {
    if (start.type !== TYPE_ROW) {
      setNewLineVisibility(true);

      if (!start.draggableId) {
        return setDraggingItem({ row: -1, index: -1 });
      }

      const matches = start.draggableId.match(/(\d+):(\d+)/);

      if (matches) {
        setDraggingItem({
          row: parseInt(matches[1] ?? '-1'),
          index: parseInt(matches[2] ?? '-1'),
        });
      }
    }
  }, []);

  return (
    <>
      <h3 className={styles.title}>Parameters</h3>
      <Select
        className={styles.tagSelect}
        disabled={!options.length}
        filterOption={true}
        placeholder={options?.length ? 'Add a field' : 'All fields added'}
        showArrow={true}
        showSearch
        value={inputValue}
        onChange={setInputValue}
        onSelect={addNewTag}
      >
        {options.map((option) => {
          return (
            <Select.Option key={`${option.label}-${option.value}`} value={option.value}>
              {option.label}
            </Select.Option>
          );
        })}
      </Select>

      <DragDropContext onDragEnd={dragEndHandler} onDragStart={dragStartHandler}>
        <div>
          <Droppable droppableId={TYPE_ROW} type={TYPE_ROW}>
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  className={styles.tagsContainer}
                  {...provided.droppableProps}
                >
                  {columns.map((tagsRow, index) => {
                    return (
                      <MoosaSarConfigTagsRow
                        key={index}
                        columnLabelMap={columnLabelMap}
                        draggingItem={draggingItem}
                        isSimpleColorSelector={isSimpleColorSelector}
                        maxTagsInRow={maxTagsInRow}
                        rowIndex={index}
                        tagsRow={tagsRow}
                        onRowChange={handleRowChange}
                      />
                    );
                  })}

                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>

          <Droppable direction={'horizontal'} droppableId={NEW_LINE_ID}>
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  className={styles.newRow}
                  style={{ visibility: isNewLineVisible ? 'visible' : 'hidden' }}
                  {...provided.droppableProps}
                >
                  <div style={{ display: 'none' }}>{provided.placeholder}</div>
                  Add to new line
                </div>
              );
            }}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
};
