import { RGroupId, SubstituentId } from '@discngine/moosa-cheminformatics-data';
import { useGetScrollbarWidth, classNames } from '@discngine/moosa-common';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { FixedSizeGrid as Grid } from 'react-window';

import { useModal, useSarMatrix } from '../SarMatrixContext';

import { SarMatrixColumnLabel } from './SarMatrixColumnLabel';
import { SarMatrixGridCell } from './SarMatrixGridCell';
import { ISarMatrixRowLabelProps, SarMatrixRowLabel } from './SarMatrixRowLabel';
import styles from './SarMatrixTable.module.less';

const CELL_SIZE_OFFSET = 5;
const MIN_CELL_SIZE = 45;

export type SarMatrixTableProps = {
  patternStructure: string; // smiles
  tableWidth: number;
  tableHeight: number;
  onHeaderCellClick: ISarMatrixRowLabelProps['onPinIconClick'];
  xAxis: RGroupId;
  yAxis: RGroupId;
  lockedMap: Record<RGroupId, SubstituentId>;
};

export const SarMatrixTable: FC<SarMatrixTableProps> = React.memo(
  ({
    patternStructure,
    tableWidth,
    tableHeight,
    onHeaderCellClick,
    xAxis,
    yAxis,
    lockedMap,
  }) => {
    const rootElement = useRef<HTMLDivElement>(null);
    const [isMatrixHovered, setIsMatrixHovered] = useState(false);
    const [cellSize, setCellSize] = useState(100);
    const [isOptimizedMode, setIsOptimizedMode] = useState(false);
    const axisSize = isOptimizedMode ? cellSize * 4 : cellSize;

    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    // set up some refs, so we can have access to `.scrollTo`
    const rowLabelRef = useRef<any>(null);
    const columnLabelRef = useRef<any>(null);
    const gridRef = useRef<any>(null);

    const {
      structureRenderer: StructureRenderer,
      xAxisValues,
      yAxisValues,
      numberOfColumns,
      numberOfRows,
    } = useSarMatrix();

    const { toggle } = useModal();

    const handleGridScroll = useCallback((event) => {
      // from the official docs:
      // > scrollUpdateWasRequested is a boolean.
      // > This value is true if the scroll was caused by scrollTo() or scrollToItem(),
      // > And false if it was the result of a user interaction in the browser.
      //
      // so we want to ignore events that were from `scrollTo`
      if (event.scrollUpdateWasRequested) return;

      setScrollX(event.scrollLeft);
      setScrollY(event.scrollTop);
    }, []);

    const handleRowLabelScroll = useCallback((event) => {
      if (event.scrollUpdateWasRequested) return;

      setScrollY(event.scrollOffset);
    }, []);

    const handleColumnLabelScroll = useCallback((event) => {
      if (event.scrollUpdateWasRequested) return;

      setScrollX(event.scrollOffset);
    }, []);

    const RowLabel = useCallback(
      ({ index: rowIndex, style }) => {
        const height = isOptimizedMode
          ? `${style.height * 2 - 5}px`
          : `${style.height}px`;
        const width = isOptimizedMode ? `${style.height * 2 - 2}px` : `${style.height}px`;

        const smiles = !yAxisValues.length ? null : yAxisValues[rowIndex].groupStructure;
        const substituentId = !yAxisValues.length ? null : yAxisValues[rowIndex].id;

        const locked = lockedMap[yAxis] === substituentId;

        return (
          <SarMatrixRowLabel
            axisId={yAxis}
            height={height}
            isOptimizedMode={isOptimizedMode}
            locked={locked}
            rowIndex={rowIndex}
            smiles={smiles}
            style={style}
            substituentId={substituentId}
            width={width}
            onPinIconClick={onHeaderCellClick}
          />
        );
      },
      [isOptimizedMode, yAxisValues, lockedMap, yAxis, onHeaderCellClick]
    );

    const ColumnLabel = useCallback(
      ({ index: columnIndex, style }) => {
        const height = isOptimizedMode ? `${style.width * 2 - 2}px` : `${style.width}px`;
        const width = isOptimizedMode ? `${style.width * 2 - 5}px` : `${style.width}px`;
        const substituentId = xAxisValues[columnIndex].id;

        const locked = lockedMap[xAxis] === substituentId;

        return (
          <SarMatrixColumnLabel
            axisId={xAxis}
            columnIndex={columnIndex}
            height={height}
            isOptimizedMode={isOptimizedMode}
            locked={locked}
            smiles={xAxisValues[columnIndex].groupStructure}
            style={style}
            substituentId={substituentId}
            width={width}
            onPinIconClick={onHeaderCellClick}
          />
        );
      },
      [isOptimizedMode, xAxisValues, lockedMap, xAxis, onHeaderCellClick]
    );

    const scrollbarWidth = useGetScrollbarWidth();

    const rowLabelsHeight: number = useMemo(() => {
      const doesHorizontalScrollExist = tableWidth >= cellSize * (numberOfColumns + 1);

      if (doesHorizontalScrollExist) {
        return tableHeight - axisSize + (isOptimizedMode ? cellSize : 0);
      }

      return tableHeight - axisSize - scrollbarWidth + (isOptimizedMode ? cellSize : 0);
    }, [
      axisSize,
      cellSize,
      isOptimizedMode,
      numberOfColumns,
      scrollbarWidth,
      tableHeight,
      tableWidth,
    ]);

    const onMouseEnter = useCallback(() => setIsMatrixHovered(true), []);
    const onMouseLeave = useCallback(() => setIsMatrixHovered(false), []);

    useEffect(() => {
      rowLabelRef.current?.scrollTo(scrollY);
      columnLabelRef.current?.scrollTo(scrollX);
      gridRef.current?.scrollTo({
        scrollLeft: scrollX,
        scrollTop: scrollY,
      });
    }, [scrollY, scrollX]);

    const isMaxCellSize = cellSize > tableHeight / 2;
    const isMinCellSize = cellSize < MIN_CELL_SIZE;
    const needToOptimize = cellSize <= 65;

    useEffect(() => {
      const onWheel = (event: WheelEvent) => {
        if (!event.ctrlKey) {
          return;
        }

        isMatrixHovered && event.preventDefault();

        const deltaY = event.deltaY;

        if (deltaY > 0 && !isMaxCellSize) {
          setCellSize((prevCellSize) => prevCellSize + CELL_SIZE_OFFSET);
        } else if (deltaY < 0 && !isMinCellSize) {
          setCellSize((prevCellSize) => prevCellSize - CELL_SIZE_OFFSET);
        }

        if (deltaY < 0 && needToOptimize) {
          setIsOptimizedMode(true);
        } else if (deltaY > 0 && !needToOptimize) {
          setIsOptimizedMode(false);
        }
      };

      document.addEventListener('wheel', onWheel, {
        passive: false,
      });

      return () => document.removeEventListener('wheel', onWheel);
    }, [isMatrixHovered, isMaxCellSize, isMinCellSize, needToOptimize]);

    return (
      <div
        ref={rootElement}
        className={styles.root}
        style={{
          width: `${tableWidth - scrollbarWidth}px`,
          height: `${tableHeight - scrollbarWidth}px`,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles.header}>
          <div
            className={classNames(styles.patternLabel, {
              [styles.patternInOptimizedMode]: isOptimizedMode,
            })}
            style={{
              height: `${axisSize}px`,
              width: `${axisSize}px`,
              padding: isOptimizedMode ? `${cellSize}px` : undefined,
            }}
            onClick={() => toggle(true)}
          >
            <StructureRenderer smiles={patternStructure} />
          </div>

          {isOptimizedMode && (
            <div
              className={classNames(styles.labelsOverlay, styles.columnLabelsOverlay)}
              style={{ left: `${axisSize - cellSize}px`, width: `${cellSize}px` }}
            />
          )}
          <List
            ref={columnLabelRef}
            className={classNames(styles.columnLabels, {
              [styles.labelsInOptimizedMode]: isOptimizedMode,
            })}
            height={axisSize}
            itemCount={numberOfColumns}
            itemSize={cellSize}
            layout="horizontal"
            style={{
              marginLeft: isOptimizedMode ? `-${cellSize}px` : undefined,
              paddingLeft: isOptimizedMode ? `${cellSize}px` : undefined,
              marginRight: isOptimizedMode ? `-${cellSize}px` : undefined,
              paddingRight: isOptimizedMode ? `${cellSize}px` : undefined,
            }}
            width={tableWidth - axisSize + (isOptimizedMode ? cellSize : 0)}
            onScroll={handleColumnLabelScroll}
          >
            {ColumnLabel}
          </List>
        </div>
        <div className={styles.table} style={{ width: `${tableWidth}px` }}>
          {isOptimizedMode && (
            <div
              className={styles.labelsOverlay}
              style={{
                top: `-${cellSize}px`,
                left: `${cellSize * 2}px`,
                height: `${cellSize}px`,
                width: `${cellSize * 2}px`,
              }}
            />
          )}
          <List
            ref={rowLabelRef}
            className={classNames(styles.rowLabels, {
              [styles.labelsInOptimizedMode]: isOptimizedMode,
            })}
            height={rowLabelsHeight}
            itemCount={numberOfRows}
            itemSize={cellSize}
            style={{
              marginTop: isOptimizedMode ? `-${cellSize}px` : undefined,
              paddingTop: isOptimizedMode ? `${cellSize}px` : undefined,
              paddingBottom: `${cellSize}px`,
            }}
            width={axisSize}
            onScroll={handleRowLabelScroll}
          >
            {RowLabel}
          </List>

          <Grid
            ref={gridRef}
            className={styles.grid}
            columnCount={numberOfColumns}
            columnWidth={cellSize}
            height={tableHeight - axisSize}
            rowCount={numberOfRows}
            rowHeight={cellSize}
            width={tableWidth - cellSize}
            onScroll={handleGridScroll}
          >
            {SarMatrixGridCell}
          </Grid>
        </div>
      </div>
    );
  }
);
