import React, { useContext } from 'react';

export interface IRDKitContext {
  RDKit: Record<string, any> | null;
}

export const RDKitContext = React.createContext<IRDKitContext | null>(null);

export function useRDKitContext(): IRDKitContext {
  const context = useContext(RDKitContext);

  if (!context) {
    throw new Error('RDKitContext is not defined');
  }

  return context;
}
