import {
  ISarConfigState,
  IMoosaSarConfigTag,
  EMoosaSarConfigImageSize,
} from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';

const initialState: ISarConfigState = {
  columns: [],
  structure: [],
  imageSize: EMoosaSarConfigImageSize.Medium,
  isConfigPanelOpen: false,
  isColorizingTextMode: false,
};

export const sarConfigSlice = createSlice({
  name: 'sarConfig',
  initialState,
  reducers: {
    setSarColumns(state: ISarConfigState, action: PayloadAction<IMoosaSarConfigTag[][]>) {
      state.columns = action.payload;
    },
    setSarStructure(state: ISarConfigState, action: PayloadAction<string[]>) {
      state.structure = action.payload;
    },
    setConfigPanelMode(state: ISarConfigState, action: PayloadAction<boolean>) {
      state.isConfigPanelOpen = action.payload;
    },
    setSarImageSize(state, action: PayloadAction<EMoosaSarConfigImageSize>) {
      state.imageSize = action.payload;
    },
    setColorizingTextMode(state: ISarConfigState, action: PayloadAction<boolean>) {
      state.isColorizingTextMode = action.payload;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (state) => {
      state.columns = [];
      state.structure = [];
    },
  },
});

export const {
  setSarColumns,
  setSarStructure,
  setSarImageSize,
  setConfigPanelMode,
  setColorizingTextMode,
} = sarConfigSlice.actions;
