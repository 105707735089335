import { FieldType } from '@discngine/moosa-models';
import React, {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { CellData } from '../SarMatrixTable/types';
import { CellRendererData } from '../types';
import { SarMatrixSubstanceData } from '../utils/ISarMatrixDataService';

interface CellCoordinates {
  columnIndex: number;
  rowIndex: number;
}

interface Props {
  data: CellRendererData & { cellsData: CellData[][] };
}

interface State extends CellRendererData {
  /**
   * @param {CellCoordinates} cellCoordinates - vertical and horizontal indexes that define cell's position
   * @param {number} cellCoordinates.columnIndex - number of column in which the cell is placed
   * @param {number} cellCoordinates.rowIndex - number of row in which the cell is placed
   * @returns {SarMatrixSubstanceData[]} - substances available for the provided coordinates
   * */
  getSubstances: (indexes: CellCoordinates) => SarMatrixSubstanceData[];

  /**
   * @param {CellCoordinates} cellCoordinates - vertical and horizontal indexes that define cell's position
   * @param {number} cellCoordinates.columnIndex - number of column in which the cell is placed
   * @param {number} cellCoordinates.rowIndex - number of row in which the cell is placed
   * @returns {CellData} - data available for the provided coordinates
   * */
  getCellData: (indexes: CellCoordinates) => CellData;

  /**
   * ID of column which field type is "Structure"
   * */
  structureColumnName: string;
  /**
   * Data for all cells.
   * */
  cellsData: CellData[][];
}

const CellRendererContext = createContext<State | null>(null);

export const SarMatrixCellRendererDataProvider = memo(
  (props: PropsWithChildren<Props>) => {
    const { children, data } = props;
    const { matrix, columnTypesMap, cellsData } = data;

    const structureColumnName =
      Object.keys(columnTypesMap).find(
        (key) => columnTypesMap[key] === FieldType.Structure
      ) || 'Canonical_Smiles';

    const getCellData = useCallback(
      ({ columnIndex, rowIndex }: CellCoordinates) => cellsData[rowIndex][columnIndex],
      [cellsData]
    );

    const getSubstances = useCallback(
      (indexes: CellCoordinates): SarMatrixSubstanceData[] => {
        const cellData = getCellData(indexes);

        const matrixData = matrix[cellData.axisXGroupId]?.[cellData?.axisYGroupId ?? ''];

        if (!matrixData) {
          console.error('cellData is missing');
        }

        return (
          // TODO: check if we need groups here
          matrixData?.map((cell) => ({ substanceId: cell.id, groups: [] })) ?? []
        );
      },
      [getCellData, matrix]
    );

    const value = useMemo(
      () => ({ getSubstances, getCellData, structureColumnName, ...data }),
      [data, getCellData, getSubstances, structureColumnName]
    );

    return (
      <CellRendererContext.Provider value={value}>
        {children}
      </CellRendererContext.Provider>
    );
  }
);

export const useCellRenderer = () => {
  const context = useContext(CellRendererContext);

  if (!context) {
    throw new Error(
      'You can`t use SarMatrixCellRendererDataContext outside its provider'
    );
  }

  return context;
};
