import { ChemoinformaticsJobError } from '../types';

export function delayPromise(ms: number, signal: AbortSignal) {
  return new Promise<void>(function (resolve, reject) {
    signal.onabort = () => {
      reject(ChemoinformaticsJobError.JobCancelled);
    };
    setTimeout(() => {
      signal.onabort = null;
      resolve();
    }, ms);
  });
}

export type PollParams<T> = {
  cb: () => Promise<T>;
  predicate: (result: T) => boolean;
  errorHandler?: () => void;
  interval: number;
  maxAttempts: number;
  signal: AbortSignal;
};
export function poll<T>({
  cb,
  errorHandler,
  interval,
  maxAttempts,
  predicate,
  signal,
}: PollParams<T>): Promise<T | void> {
  function run(attempt: number): Promise<T | void> {
    return cb().then((result) => {
      if (signal.aborted) {
        return Promise.reject(ChemoinformaticsJobError.JobCancelled);
      }

      if (predicate(result)) {
        // we know we're done here, return from here whatever you
        // want the final resolved value of the promise to be
        return result;
      } else {
        if (attempt > maxAttempts) {
          errorHandler && errorHandler();

          return Promise.reject(ChemoinformaticsJobError.MaxAttemptExceed);
        } else {
          // run again with a short delay
          return delayPromise(interval, signal).then(() => run(attempt + 1));
        }
      }
    });
  }

  return run(1);
}
