import { ColumnId, IColumnLabelMap } from '@discngine/moosa-models';
import { ColumnColorMultiSelector } from '@discngine/moosa-shared-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

import { ScoringItem } from '../../cellRenderer/types';

import styles from './SarMatrixControls.module.less';

export interface ScoringControlProps {
  /**
   * Defines if average mode is used
   * */
  isAverageMode: boolean;

  /**
   * Callback invokes on average mode change, event
   * @param {CheckboxChangeEvent} event - allows to define if average mode was turned off or on
   * */
  onAverageModeChange: (event: CheckboxChangeEvent) => void;

  /**
   * Available dataset parameters
   * */
  options: ColumnId[];

  /**
   * Selected scoring properties with their colors
   * */
  columns: ScoringItem[];

  /**
   * Invokes on property select
   * @param {ScoringItem[]} columns - updated list of all selected options
   * */
  onChange: (columns: ScoringItem[]) => void;

  /**
   * Allows to define option label. Record<IColumnMetaInfo['name'], string>;
   * */
  columnLabelMap?: IColumnLabelMap;
}

export const SarMatrixScoringControls = (props: ScoringControlProps) => {
  const {
    onAverageModeChange,
    isAverageMode,
    onChange,
    options,
    columnLabelMap,
    columns,
  } = props;

  return (
    <>
      <Checkbox checked={isAverageMode} onChange={onAverageModeChange}>
        Show average
      </Checkbox>

      <div className={styles.properties}>
        <ColumnColorMultiSelector
          availableColumns={options}
          columnLabelMap={columnLabelMap}
          columns={columns}
          title="Select properties"
          onColumnsChange={onChange}
        />
      </div>
    </>
  );
};
