import { IScore, ScoringFunction } from '@discngine/moosa-models';

import { IFactorPie } from '../types';

export const pieChartBuilder = (
  columnIds: string[],
  order: number[],
  score: IScore,
  scoringFunction: ScoringFunction
): IFactorPie[] =>
  order.map((index) => {
    const factor = score?.factors[index];
    const colId = columnIds[index];
    const { color } = scoringFunction[colId];
    let percent = 0;

    if (factor) {
      percent = factor * 100;
    }

    return { colId, color, percent };
  });
