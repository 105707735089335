import { MouseEvent, useCallback, useState } from 'react';

export const useStructurePopover = () => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const onMouseOver = useCallback((event: MouseEvent) => {
    const target = event.target as any;
    const substanceId =
      (target?.getAttribute && target.getAttribute('data-id')) ||
      target.closest('[data-id]')?.getAttribute('data-id');

    setHoveredId(substanceId ?? null);
  }, []);

  const onMouseLeave = useCallback(() => {
    setHoveredId(null);
  }, []);

  return {
    hoveredId,
    onMouseOver,
    onMouseLeave,
  };
};
