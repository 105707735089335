import { Tooltip } from 'antd';

import styles from './RequiredMark.module.less';

export const RequiredMark = () => {
  return (
    <Tooltip title="Value is required">
      <span className={styles.required}>*</span>
    </Tooltip>
  );
};
