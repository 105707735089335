export const valueConverter = (value: string | null) =>
  value
    ?.split('[*]')
    .map((item, index, arr) => {
      if (index < arr.length - 1) {
        return `${item}[*:${index + 1}]`;
      }

      return item;
    })
    .join('') || '';
