import { Popover } from 'antd';
import debounce from 'lodash/debounce';
import React, { FC, useState, useMemo, useEffect } from 'react';

import styles from './TableCellPopover.module.less';

export type TableCellPopoverProps = {
  popoverContent?: FC<{ id: string }>;
  hoveredSubstanceId: string | null;
};

export const TableCellPopover: FC<TableCellPopoverProps> = ({
  popoverContent: PopoverContent,
  hoveredSubstanceId,
  children,
}) => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const popoverContent = useMemo(() => {
    if (!PopoverContent || !hoveredId) {
      return null;
    }

    return <PopoverContent id={hoveredId} />;
  }, [hoveredId, PopoverContent]);

  const debouncer = useMemo(() => {
    return debounce(
      (id: string | null) => {
        setOpen(Boolean(id));

        // keep last valid id to avoid popover glitches
        if (id) {
          setHoveredId(id);
        }
      },
      150,
      { trailing: true }
    );
  }, []);

  useEffect(() => {
    debouncer(hoveredSubstanceId);
  }, [debouncer, hoveredSubstanceId]);

  return (
    <Popover
      autoAdjustOverflow={true}
      content={popoverContent}
      open={open}
      overlayClassName={styles.popover}
      placement={'topLeft'}
    >
      {children}
    </Popover>
  );
};
