import { decimalFormatter } from '@discngine/moosa-common';
import { FC } from 'react';

import styles from './ScoringErrors.module.less';
interface ScoringErrorProps {
  base: number;
  delta: number;
}
export const ScoringError: FC<ScoringErrorProps> = ({ base, delta }) => {
  const sign = delta > 0 ? '+' : '';

  return (
    <div className={styles.scoringError}>
      {`${decimalFormatter.format(base + delta)} (${sign}${decimalFormatter.format(
        delta
      )})`}
    </div>
  );
};
