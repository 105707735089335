import React, { FC } from 'react';

import { TableBarStyle, TableValueStyle } from '../MoosaMMPChartConfig';
import { MMPChartBaseMolecule } from '../types';

import styles from './MoosaMMPTable.module.less';
export interface MoosaMMPCellValueProps {
  collapsed: boolean;
  radialMolecule?: MMPChartBaseMolecule;
  tableBarStyle?: TableBarStyle;
  tableValueStyle?: TableValueStyle;
  value?: number | null;
  delta?: string;
  color?: string;
}

export const MMPTableCellValue: FC<MoosaMMPCellValueProps> = ({
  radialMolecule,
  tableBarStyle,
  tableValueStyle,
  collapsed,
  value,
  delta,
  color,
}: MoosaMMPCellValueProps) => {
  let style = {};

  if (radialMolecule) {
    if (
      radialMolecule &&
      radialMolecule.initialValue &&
      radialMolecule.activity !== null
    ) {
      if (collapsed)
        style = {
          right: 'unset',
          width: `${Math.abs(radialMolecule.activity * 100 || 1)}%`,
          backgroundColor: color,
        };
      else if (tableBarStyle === TableBarStyle.VERTICAL)
        style = {
          left: 0,
          right: 'unset',
          width: `${Math.abs(radialMolecule.activity * 100 || 1)}%`,
          height: '100%',
          backgroundColor: color,
        };
      else if (tableBarStyle === TableBarStyle.VERTICAL_SEPARATE)
        style = {
          left: `${radialMolecule.activity >= 0 ? '0' : 'unset'}`,
          right: `${radialMolecule.activity < 0 ? '0' : 'unset'}`,
          width: `${Math.abs(radialMolecule.activity * 100 || 1)}%`,
          height: '100%',
          backgroundColor: color,
        };
      else if (tableBarStyle === TableBarStyle.VERTICAL_AXIS)
        style = {
          right: `${radialMolecule.activity < 0 && '50'}%`,
          left: `${radialMolecule.activity >= 0 && '50'}%`,
          width: `${Math.abs(radialMolecule.activity * 50 || 1)}%`,
          height: '100%',
          backgroundColor: color,
        };
      else if (tableBarStyle === TableBarStyle.HORIZONTAL_AXIS)
        style = {
          bottom: `${radialMolecule.activity >= 0 && '50'}%`,
          top: `${radialMolecule.activity < 0 && '50'}%`,
          height: `${Math.abs(radialMolecule.activity * 50 || 1)}%`,
          width: '100%',
          backgroundColor: color,
        };
    }
  }

  return (
    <>
      {/* SIMPLE VALUE FOR HEADER ROW */}
      {value && (
        <div className={styles.cell}>
          <span className={styles.cellValue}>{value}</span>
        </div>
      )}
      {/* DRAW BARS AND VALUES OF NORMAL ROWS */}
      {radialMolecule && (
        <div className={`${styles.cell} ${collapsed && styles.cellCollapsed}`}>
          <span className={styles.cellValue}>{radialMolecule.initialValue}</span>
          <div className={styles.cellSectorContainer}>
            <div className={styles.cellSector} style={style}></div>
          </div>
          {tableValueStyle === TableValueStyle.DELTA && delta && (
            <span
              className={`${styles.cellDelta} ${
                delta && delta[0] === '-' && styles.cellDeltaNegative
              }`}
            >
              {delta}
            </span>
          )}
        </div>
      )}
    </>
  );
};
