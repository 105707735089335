import { IScore, ScoringFunction } from '@discngine/moosa-models';
import { Popover, theme } from 'antd';
import { FC, useMemo } from 'react';

import { RadarChart } from '../RadarChart';
import { ResponsiveContainer } from '../ResponsiveContainer/ResponsiveContainer';

import styles from './RadarRendererAbstract.module.less';

const RADAR_PREVIEW_PROPS = {
  margin: { left: 40, right: 40, top: 40, bottom: 40 },
};

const RADAR_TABLE_PROPS = {
  width: 80,
  height: 80,
};

const COLOR = theme.defaultSeed.colorPrimary;

const POLYGON_STYLES = { fillOpacity: 0.1, fill: '#12939A' };

type Props = {
  columnIds: string[];
  columnIndices: Record<string, number>;
  order: string[];
  score: IScore;
  scoringFunction: ScoringFunction;
};

export const RadarRendererAbstract: FC<Props> = ({
  columnIds,
  columnIndices,
  order,
  score,
  scoringFunction,
}) => {
  const [keys, values] = useMemo(() => {
    const items = order
      .filter((col) => columnIds.includes(col))
      .filter((col) => scoringFunction[col].isInUse)
      .map((col) => columnIndices[col])
      .map((index) => {
        const factor = (score && score.rawValues[index]) ?? 0;
        const colId = columnIds[Number(index)];

        return [
          { label: colId, props: { fill: scoringFunction[colId].color } },
          factor,
        ] as const;
      })
      .reduce<[keys: { label: string }[], values: number[]]>(
        (acc, [key, value]) => {
          acc[0].push(key);
          acc[1].push(value);

          return acc;
        },
        [[], []]
      );

    if (items[0].length < 3) {
      return [null, null];
    }

    return items;
  }, [order, columnIds, scoringFunction, columnIndices, score]);

  if (score.value === null || !Object.keys(score.factors).length) {
    return (
      <Popover
        content="Scoring is N/A, radar plot cannot be built"
        overlayClassName={styles.hint}
        placement="bottom"
      >
        <div className="text-center">N/A</div>
      </Popover>
    );
  }

  if (!keys || !values) {
    return (
      <Popover
        content="Radar plot cannot be built cause there are less than 3 parameters in the scoring function"
        overlayClassName={styles.hint}
        placement="bottom"
      >
        <div className="text-center">N/A</div>
      </Popover>
    );
  }

  return (
    <Popover
      content={
        <div className={styles.popover}>
          <ResponsiveContainer
            component={RadarChart}
            data={[{ points: values, props: POLYGON_STYLES }]}
            labels={keys}
            levels={keys.length}
            margin={RADAR_PREVIEW_PROPS.margin}
            polygonProps={POLYGON_STYLES}
          />
        </div>
      }
      placement="right"
    >
      <div className="text-center">
        <div className={styles.preview}>
          <ResponsiveContainer
            component={RadarChart}
            data={[{ points: values, props: { fill: 'transparent', stroke: COLOR } }]}
            {...RADAR_TABLE_PROPS}
          />
        </div>
      </div>
    </Popover>
  );
};
