import { IScoringTemplateSubRoot, ITableInfoSubRoot } from '@discngine/moosa-models';
import { desirabilityFunctionsSlice } from '@discngine/moosa-store/desirabilityFunctions';
import { scoringTemplateSlice } from '@discngine/moosa-store/scoringTemplate';
import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

export type RootState = IScoringTemplateSubRoot &
  ITableInfoSubRoot & {
    [desirabilityFunctionsSlice.slice.name]: ReturnType<
      typeof desirabilityFunctionsSlice.slice.reducer
    >;
  };

const reducers = {
  scoringTemplate: scoringTemplateSlice.reducer,
  tableInfo: tableInfoSlice.reducer,
  [desirabilityFunctionsSlice.slice.name]: desirabilityFunctionsSlice.slice.reducer,
};

export const store = configureStore<RootState>({
  reducer: combineReducers(reducers),
});

export type AppDispatch = typeof store.dispatch;
