import { RGroupId, SubstituentId } from '@discngine/moosa-cheminformatics-data';
import React from 'react';

import {
  ISarMatrixPinnedListItemProps,
  SarMatrixPinnedListItem,
} from './SarMatrixPinnedListItem';
import styles from './styles.module.less';

export interface PinnedListProps {
  /**
   * List of pinned rows/columns
   * */
  list: Array<{ rGroupId: RGroupId; substituentId: SubstituentId; structure: string }>;
  /**
   * @param {string} rGroupId - clicked group
   * @returns {void}
   * */
  onItemClick: ISarMatrixPinnedListItemProps['onClick'];
}

export const SarMatrixPinnedList = React.memo(
  ({ onItemClick, list }: PinnedListProps) => {
    return (
      <div className={styles.list}>
        {list.map((item) => (
          <SarMatrixPinnedListItem
            key={item.rGroupId}
            rGroupId={item.rGroupId}
            structure={item.structure}
            onClick={onItemClick}
          />
        ))}
      </div>
    );
  }
);
