import { IRGBColor } from '@discngine/moosa-common';
import { DiscretePropertyValue } from '@discngine/moosa-models';
import { FC } from 'react';

import styles from './DiscretePropertyColorizingConfig.module.less';
import { DiscretePropertyItem } from './DiscretePropertyItem/DiscreteTextPropertyItem';

interface IDiscretePropertyColorizingConfigProps {
  discretePropertyValues: DiscretePropertyValue[];
  onColorChange: (indexOfSelectedColor: number, color: IRGBColor | null) => void;
}

export const DiscretePropertyColorizingConfig: FC<
  IDiscretePropertyColorizingConfigProps
> = ({ discretePropertyValues, onColorChange }) => {
  return (
    <div className={styles.discretePropertyColorizingConfig}>
      {discretePropertyValues.map((item, index) => (
        <DiscretePropertyItem
          key={index}
          indexOfSelectedColor={index}
          item={item}
          onColorChange={onColorChange}
        />
      ))}
    </div>
  );
};
