import { smilesToMolecule } from '@discngine/moosa-structure-renderer';
import orderBy from 'lodash/orderBy';

import { MolFileParser } from '../MolfileParser/MolFileParser';
import { Sorting } from '../MoosaMMPChartConfig';
import {
  MMPChartBaseMolecule,
  MMPChartCentralMolecule,
  MMPChartMolecule,
} from '../types';

const getChartDataElements = (
  molecules: MMPChartMolecule[],
  sorting: Sorting,
  numberOfElements: number,
  cloneSortingOrder?: string[]
) => {
  let data: MMPChartBaseMolecule[] = [];

  if (!cloneSortingOrder) {
    const order = sorting === Sorting.ASC ? 'asc' : 'desc';

    data = orderBy(
      molecules.map((mol) => {
        return {
          ...mol,
          affection: mol.affection!,
          initialValue: mol.activity,
        };
      }),
      [
        (obj) => {
          if (obj.initialValue === null) {
            if (sorting === Sorting.ASC) return Number.MAX_SAFE_INTEGER;

            if (sorting === Sorting.DESC) return Number.MIN_SAFE_INTEGER;
          }

          return obj.initialValue;
        },
      ],
      [order]
    );
    data = data.slice(0, numberOfElements);
  } else {
    data = molecules.map((mol) => {
      return {
        ...mol,
        affection: mol.affection!,
        initialValue: mol.activity,
      };
    });

    // make sure data is sortened down to numberOfElements with the correct molecules in the list
    data = data.filter((mol) => cloneSortingOrder.includes(mol.id));

    data = data.sort(function (elemA, elemB) {
      return cloneSortingOrder.indexOf(elemA.id) - cloneSortingOrder.indexOf(elemB.id);
    });
  }

  return data;
};

export const normalizeChartData = (
  centralMolecule: MMPChartCentralMolecule,
  molecules: MMPChartMolecule[],
  sorting: Sorting,
  cloneSortingOrder?: string[]
) => {
  const numberOfElements = Math.min(molecules.length || 1, 12);

  const data = cloneSortingOrder
    ? getChartDataElements(molecules, sorting, numberOfElements, cloneSortingOrder)
    : getChartDataElements(molecules, sorting, numberOfElements);

  const centralActivity = centralMolecule.activity;
  let activities = data.map((x) => {
    if (x.activity !== null && centralActivity !== null) {
      return x.activity - centralActivity;
    }

    return null;
  });
  const distance =
    Math.max(
      ...activities
        .filter((x) => x !== null)
        .map((x) => {
          return Math.abs(x!);
        })
    ) || 1;

  const chartActivities = activities.map((act) => {
    if (act !== null) return act / distance;

    return null;
  });
  const chartAffections = chartActivities.map((activity) => {
    if (activity !== null) {
      return (activity + 1) / 2;
    }

    return 0;
  });

  let chartMolecules: Array<MMPChartBaseMolecule> = data.map((mol, index) => {
    return {
      ...mol,
      activity: chartActivities[index],
      affection: mol.affection !== undefined ? mol.affection : chartAffections[index],
    };
  });

  const chartCentralMolecule: MMPChartCentralMolecule = {
    ...centralMolecule,
  };

  return { chartMolecules, chartCentralMolecule, numberOfElements };
};

export const transformMoleculeStructures = (
  chartMolecules: Array<MMPChartBaseMolecule>,
  chartCentralMolecule: MMPChartCentralMolecule,
  RDKit: any
) => {
  const centralMol = smilesToMolecule(
    RDKit!,
    chartCentralMolecule.structureMOLFILE,
    true
  );
  const centralMoleculeStructureMOLFILE = centralMol.get_molblock();
  const centralMoleculeStructureObj = MolFileParser.parseMol(
    centralMoleculeStructureMOLFILE
  );

  const molecules = chartMolecules.map((molecule) => {
    const moleculeSmiles = molecule.structure;
    const moleculeRDObj = smilesToMolecule(RDKit!, moleculeSmiles, true);
    const moleculeStructureMOLFILE = moleculeRDObj.get_molblock();
    const moleculeCoreRDObj = smilesToMolecule(RDKit!, molecule.core, true);
    const moleculeCoreMOLFILE = moleculeCoreRDObj.get_molblock();
    const moleculeCoreObj = MolFileParser.parseMol(moleculeCoreMOLFILE);
    const moleculeCoreWithoutRGroupsObj = MolFileParser.removeRGroups(
      structuredClone(moleculeCoreObj)
    );
    const moleculeCoreWithoutRGroupsMOLFILE = MolFileParser.toMOLFILE(
      moleculeCoreWithoutRGroupsObj
    );
    const moleculeCoreWithoutRGroupsRDObj = smilesToMolecule(
      RDKit!,
      moleculeCoreWithoutRGroupsMOLFILE,
      true
    );
    const matchedAtomsAndBonds = JSON.parse(
      centralMol.get_substruct_match(moleculeCoreWithoutRGroupsRDObj)
    );
    const centralMoleculeReducedToCoreObj = MolFileParser.trim(
      structuredClone(centralMoleculeStructureObj),
      matchedAtomsAndBonds.atoms?.map((x: number) => x + 1),
      matchedAtomsAndBonds.bonds?.map((x: number) => x + 1)
    );
    const coreStructureMOLFILE = MolFileParser.toMOLFILE(centralMoleculeReducedToCoreObj);

    moleculeRDObj.delete();
    moleculeCoreRDObj.delete();
    moleculeCoreWithoutRGroupsRDObj.delete();

    return {
      ...molecule,
      core: coreStructureMOLFILE,
      structure: moleculeStructureMOLFILE,
    };
  });

  centralMol.delete();

  return { molecules };
};

export const formatChartCaption = (value: number | null, centralValue: number | null) => {
  if (centralValue === null || value === null || !Number.isFinite(value)) return '';

  return value.toFixed(4);
};
