import { colorGenerator } from '@discngine/moosa-common';
import { ColumnId, IColumnLabelMap } from '@discngine/moosa-models';
import { Select } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { DraggableTags, TagId, TagsPanel } from '../../DraggableTags';
import styles from '../MultiplePropertiesSelector.module.less';

import {
  IMoosaColorTagContext,
  MoosaColorTagContext,
  MoosaColorTagRenderer,
} from './MoosaColorTagRenderer';

export interface ColumnWithColor {
  columnId: string;
  color: string;
}

export interface ColumnColorMultiSelectorProps {
  title?: string;
  availableColumns: ColumnId[];
  columns: ColumnWithColor[];
  onColumnsChange: (columns: ColumnWithColor[]) => void;
  columnLabelMap?: IColumnLabelMap;
}

export const ColumnColorMultiSelector: React.FC<ColumnColorMultiSelectorProps> = ({
  title,
  availableColumns,
  columns,
  columnLabelMap,
  onColumnsChange,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const tagsMap = useMemo(() => {
    return columns.reduce<Record<TagId, string>>((acc, tag) => {
      acc[tag.columnId] = tag.color;

      return acc;
    }, {});
  }, [columns]);

  const context = useMemo(() => {
    const tagColorMap = columns.reduce<Record<TagId, string>>((acc, column) => {
      acc[column.columnId] = column.color;

      return acc;
    }, {});

    const onColorChange = (tagId: TagId, color: string) => {
      const newColumns = columns.map((col) => {
        if (col.columnId !== tagId) {
          return col;
        }

        const newCol: ColumnWithColor = {
          columnId: tagId,
          color,
        };

        return newCol;
      });

      onColumnsChange(newColumns);
    };

    const value: IMoosaColorTagContext = {
      columnLabelMap,
      tagColorMap,
      onColorChange,
    };

    return value;
  }, [columnLabelMap, columns, onColumnsChange]);

  const options = useMemo(() => {
    return availableColumns
      .filter((column) => !tagsMap[column])
      .map((columnId, index) => {
        const label = columnLabelMap?.[columnId]?.label || columnId;

        return (
          <Select.Option key={index} value={columnId}>
            {label}
          </Select.Option>
        );
      });
  }, [availableColumns, columnLabelMap, tagsMap]);

  const addNewTag = useCallback(
    (value: string) => {
      const newCol: ColumnWithColor = {
        columnId: value,
        color: colorGenerator.next().value,
      };

      onColumnsChange([...columns, newCol]);
      setInputValue(null);
    },
    [columns, onColumnsChange]
  );

  const tagIds = useMemo(() => {
    return columns.map((column) => {
      return [column.columnId];
    });
  }, [columns]);

  const onTagReorder = useCallback(
    (tagsPanel: TagsPanel) => {
      const columns: ColumnWithColor[] = tagsPanel.map((row) => {
        const columnId = row[0];
        const color = tagsMap[columnId];

        if (columnId == null) {
          throw new Error(`tag not found: ${columnId}`);
        }

        const newCol: ColumnWithColor = {
          columnId,
          color,
        };

        return newCol;
      });

      onColumnsChange(columns);
    },
    [onColumnsChange, tagsMap]
  );

  return (
    <>
      <h3 className={styles.title}>{title ?? 'Parameters'}</h3>
      <Select
        className={styles.tagSelect}
        disabled={!options.length}
        filterOption={true}
        placeholder={options?.length ? 'Add a field' : 'All fields added'}
        showArrow={true}
        showSearch
        value={inputValue}
        onChange={setInputValue}
        onSelect={addNewTag}
      >
        {options}
      </Select>
      <MoosaColorTagContext.Provider value={context}>
        <DraggableTags
          className={styles.tagsContainer}
          maxTagsInRow={1}
          tagRenderer={MoosaColorTagRenderer}
          tags={tagIds}
          onChange={onTagReorder}
        />
      </MoosaColorTagContext.Provider>
    </>
  );
};
