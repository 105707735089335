import { DropResult } from 'react-beautiful-dnd';

import {
  NEW_LINE_ID,
  TagId,
  TagRowCol,
  TagsPanel,
  TYPE_ROW,
  TYPE_TAG,
} from './TagRendererProps';

const reorderRows = (
  tags: TagsPanel,
  startIndex: number,
  endIndex: number
): TagsPanel => {
  const result = [...tags];
  const from = result[startIndex];
  const to = result[endIndex];

  result[startIndex] = to;
  result[endIndex] = from;

  return result;
};

const addNewRow = (tags: TagsPanel, from: TagRowCol): TagsPanel => {
  const result = [...tags];
  const moved = result[from.row].splice(from.col, 1);

  result.push(moved);

  // remove empty line
  if (!result[from.row].length) {
    result.splice(from.row, 1);
  }

  return result;
};

const swapTags = (tags: TagsPanel, from: TagRowCol, to: TagRowCol): TagsPanel => {
  const result = [...tags];

  result[from.row] = [...result[from.row]];
  result[to.row] = [...result[to.row]];

  const fromTag = tags[from.row][from.col];
  const toTag = tags[to.row][to.col];

  result[from.row][from.col] = toTag;
  result[to.row][to.col] = fromTag;

  return result;
};

const moveTag = (
  tags: TagsPanel,
  maxTagsInRow: number,
  from: TagRowCol,
  to: TagRowCol
): TagsPanel => {
  const swapBetweenRows = tags[to.row].length === maxTagsInRow && from.row !== to.row;
  const swapInRow = from.row === to.row;

  if (swapBetweenRows || swapInRow) {
    return swapTags(tags, from, to);
  }
  const result = [...tags];

  result[from.row] = [...result[from.row]];
  result[to.row] = [...result[to.row]];

  const fromTag = tags[from.row][from.col];

  // move tag
  result[from.row].splice(from.col, 1);
  result[to.row].splice(to.col, 0, fromTag);

  // remove empty line
  if (!result[from.row].length) {
    result.splice(from.row, 1);
  }

  return result;
};

export const deleteTag = (tags: TagsPanel, tagId: TagId): TagsPanel => {
  return tags.reduce<TagsPanel>((acc, tagsRow) => {
    const tagIndex = tagsRow.indexOf(tagId);

    if (tagIndex === -1) {
      acc.push(tagsRow);
    } else {
      const newRow = [...tagsRow];

      newRow.splice(tagIndex, 1);

      // remove empty line
      if (!newRow.length) {
        return acc;
      }
      acc.push(newRow);
    }

    return acc;
  }, []);
};

export const handleDropResult = (
  tags: TagsPanel,
  maxTagsInRow: number,
  result: DropResult
): TagsPanel => {
  const source = result.source;
  const destination = result.destination;

  if (result.combine && result.type === TYPE_TAG) {
    const from: TagRowCol = { row: Number(source.droppableId), col: source.index };

    const row = Number(result.combine.droppableId);
    const col = tags[row].indexOf(result.combine.draggableId);
    const to: TagRowCol = { row, col };

    return swapTags(tags, from, to);
  }

  // dropped nowhere
  if (!destination) {
    return tags;
  }

  // did not move anywhere - can bail early
  if (
    source.droppableId === destination.droppableId &&
    source.index === destination.index
  ) {
    return tags;
  }

  // reordering rows
  if (result.type === TYPE_ROW) {
    return reorderRows(tags, source.index, destination.index);
  }

  // reorder tags
  if (result.type === TYPE_TAG) {
    const from: TagRowCol = { row: Number(source.droppableId), col: source.index };
    const to: TagRowCol = {
      row: Number(destination.droppableId),
      col: destination.index,
    };

    if (destination.droppableId === NEW_LINE_ID) {
      return addNewRow(tags, from);
    }

    const maxTagIndex = maxTagsInRow - 1;

    if (to.col > maxTagIndex) {
      // instead of moving behind last tag swap tags
      to.col = maxTagIndex;
    }

    return moveTag(tags, maxTagsInRow, from, to);
  }

  return tags;
};
