import React, { FC, memo } from 'react';

import { TableCellPopover } from '../TableCellPopover/TableCellPopover';
import { useStructurePopover } from '../TableCellPopover/useStructurePopover';

import styles from './styles.module.less';

export type ISarMatrixTableCellProps = {
  popoverContent?: FC<{ id: string }>;
  data: { id: string; color: string }[];
};

export const CircleChart = memo((props: ISarMatrixTableCellProps) => {
  const { popoverContent, data } = props;

  const length = Math.ceil(Math.sqrt(data.length));
  const cellsize = 100 / length;
  const radius = (cellsize * 0.97) / 2;

  const getPosition = (index: number) => {
    const xIndex = index % length;
    const yIndex = Math.floor(index / length);

    return [(xIndex + 0.5) * cellsize, (yIndex + 0.5) * cellsize];
  };

  const { hoveredId, onMouseOver, onMouseLeave } = useStructurePopover();

  return (
    <TableCellPopover hoveredSubstanceId={hoveredId} popoverContent={popoverContent}>
      <div className={styles.root} onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
        <svg
          fill="none"
          height="100%"
          viewBox="0 0 100 100"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          {data.map(({ id, color }, index) => {
            const [x, y] = getPosition(index);

            if (color) {
              return (
                <circle key={id} cx={x} cy={y} data-id={id} fill={color} r={radius} />
              );
            }

            const strokeWidth = 3;
            const cr = radius - strokeWidth / 2;

            return (
              <circle
                key={id}
                cx={x}
                cy={y}
                data-id={id}
                fill="white"
                r={cr}
                stroke="gray"
                strokeWidth={strokeWidth}
              />
            );
          })}
        </svg>
      </div>
    </TableCellPopover>
  );
});
