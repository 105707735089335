import { ISmartChartModelParams } from '@discngine/moosa-models';

import { ISmartChartOptions } from './IChartsState';

export function smartChartOptionsToModelParams(
  options: ISmartChartOptions
): ISmartChartModelParams {
  const payload: ISmartChartModelParams = {
    iterations: options.iterations,
    neighbors: options.neighbors,
    minDist: options.minDist,
    columns: null, // tbd
  };

  return payload;
}
