import { useCallback, useState } from 'react';
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';

import { DraggableTagsRow } from './DraggableTagsRow';
import { deleteTag, handleDropResult } from './handleDropResult';
import {
  NEW_LINE_ID,
  TagId,
  TagRenderer,
  TagRowCol,
  TagsPanel,
  TYPE_ROW,
  TYPE_TAG,
} from './TagRendererProps';

export interface MultiplePropertiesDraggableTagsProps {
  className?: string;
  tagRenderer: TagRenderer;
  tags: TagsPanel;
  maxTagsInRow?: number; // Default value is 3
  onChange: (tags: TagsPanel) => void;
}

export const DraggableTags: React.FC<MultiplePropertiesDraggableTagsProps> = ({
  className,
  tagRenderer,
  tags: tagRows,
  maxTagsInRow = 3,
  onChange,
}) => {
  const [draggingItem, setDraggingItem] = useState<TagRowCol | null>(null);

  const onDragStart = useCallback((start: DragStart) => {
    if (start.type === TYPE_TAG) {
      setDraggingItem({ row: Number(start.source.droppableId), col: start.source.index });
    }
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      setDraggingItem(null);
      onChange(handleDropResult(tagRows, maxTagsInRow, result));
    },
    [maxTagsInRow, onChange, tagRows]
  );

  const onDeleteTag = useCallback(
    (tagId: TagId) => {
      onChange(deleteTag(tagRows, tagId));
    },
    [onChange, tagRows]
  );

  const lastRow = tagRows.length - 1;
  const isDraggingSingleTagInLastRow =
    draggingItem && tagRows[lastRow].length === 1 && draggingItem.row === lastRow;
  const isNewLineVisible = draggingItem && !isDraggingSingleTagInLastRow;

  const dragTagRowId = draggingItem ? String(draggingItem.row) : null;
  const isSingleRow = tagRows.length === 1;

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId={TYPE_ROW} type={TYPE_ROW}>
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              className={className}
              {...provided.droppableProps}
            >
              {tagRows.map((tagsRow, index) => {
                const rowId = String(index);

                return (
                  <DraggableTagsRow
                    key={rowId}
                    dragTagRowId={dragTagRowId}
                    isDragDisabled={isSingleRow}
                    isDropDisabled={false}
                    maxTagsInRow={maxTagsInRow}
                    rowId={rowId}
                    rowIndex={index}
                    tagRenderer={tagRenderer}
                    tagsRow={tagsRow}
                    onDeleteTag={onDeleteTag}
                  />
                );
              })}
              <div style={{ visibility: isNewLineVisible ? 'visible' : 'hidden' }}>
                <DraggableTagsRow
                  dragTagRowId={dragTagRowId}
                  isDragDisabled={true}
                  isDropDisabled={!isNewLineVisible}
                  isNewRow={true}
                  maxTagsInRow={maxTagsInRow}
                  rowId={NEW_LINE_ID}
                  rowIndex={tagRows.length}
                  tagRenderer={tagRenderer}
                  tagsRow={[]}
                  onDeleteTag={onDeleteTag}
                />
              </div>

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
