import { IStructureRenderer } from '@discngine/moosa-models';
import React, { useCallback } from 'react';

import { MMPChartBaseMolecule, MMPChartHoveredMolecule } from '../../types';

type RadialMoleculeProps = {
  index: number;
  stepAngle: number;
  turnDegreePi: number;
  r2: number;
  width: number;
  height: number;
  molecule: MMPChartBaseMolecule;
  StructureRenderer: IStructureRenderer;
  setHoveredMolecule: (core: MMPChartHoveredMolecule) => void;
};

const { cos, sin } = Math;

export const RadialMolecule = React.memo(
  ({
    index,
    stepAngle,
    turnDegreePi,
    r2,
    width,
    height,
    molecule,
    StructureRenderer,
    setHoveredMolecule,
  }: RadialMoleculeProps) => {
    const halfAngle = (index + 0.5) * stepAngle + turnDegreePi;
    let translateX = (r2 + width / 1.4) * cos(halfAngle);
    let translateY = (r2 + height / 1.4) * sin(halfAngle);

    translateX += -width / 2;
    translateY += -height / 2;

    const onMouseEnter = useCallback(() => {
      setHoveredMolecule({
        id: molecule.id,
        core: molecule.core,
        structure: molecule.structure,
        value: molecule.initialValue,
      });
    }, [setHoveredMolecule, molecule]);

    const onMouseLeave = useCallback(() => {
      setHoveredMolecule({
        id: '',
        core: '',
        structure: '',
        value: null,
      });
    }, [setHoveredMolecule]);

    const coreSmiles = [{ core: molecule.core, color: 'transparent' }];

    const alignStructures = [molecule.core];

    return (
      <g key={index}>
        <foreignObject
          height="250"
          transform={`translate(${translateX} ${translateY})`}
          width="250"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {StructureRenderer && (
            <div>
              <StructureRenderer
                alignSmiles={alignStructures}
                coreSmiles={coreSmiles}
                disableQueue={true}
                smiles={molecule.structure}
              />
            </div>
          )}
        </foreignObject>
      </g>
    );
  }
);
