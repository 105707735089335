import {
  ColumnId,
  IColumnLabelMap,
  IColumnMetaInfo,
  IMoosaSarConfigTag,
} from '@discngine/moosa-models';
import { MultiplePropertiesSelector } from '@discngine/moosa-shared-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useMemo } from 'react';

import styles from '../MoosaSarMatrixConfig.module.less';

export interface CircleControlProps {
  /**
   * Available dataset parameters
   * */
  options: ColumnId[];

  /**
   * Defines if average mode is used
   * */
  isAverageMode: boolean;

  /**
   * Callback invokes on average mode change, event
   * @param {CheckboxChangeEvent} event - allows to define if average mode was turned off or on
   * */
  onAverageModeChange: (event: CheckboxChangeEvent) => void;

  /**
   * selected columns that are generated basic on selected option and datasetColumnsMap
   * */
  columns: IMoosaSarConfigTag[][];

  /**
   * map of columns to get column meta info inside sar matrix
   * */
  datasetColumnsMap: Record<IColumnMetaInfo['name'], IColumnMetaInfo>;

  /**
   * ordered columnIds that helps to define if isScoreAvailable true or false for a selected option
   * */
  datasetColumnsIds: ColumnId[];
  /**
   * Invokes on option select.
   * @param {IMoosaSarConfigTag[][]} columns - updated list of selected columns
   * */
  onChange: (columns: IMoosaSarConfigTag[][]) => void;
  /**
   * Allows to define option label. Record<IColumnMetaInfo['name'], string>;
   * */
  columnLabelMap?: IColumnLabelMap;
}

export const SarMatrixCircleControls = (props: CircleControlProps) => {
  const {
    columnLabelMap,
    datasetColumnsMap,
    datasetColumnsIds,
    columns,
    onAverageModeChange,
    onChange,
    isAverageMode,
    options,
  } = props;

  const circleOptions = useMemo(() => {
    return options
      .filter((column) => {
        return !columns.some((tagsRow) => tagsRow.some((tag) => tag.columnId === column));
      })
      .map((columnId) => {
        const label = columnLabelMap?.[columnId]?.label || columnId;

        return { label, value: columnId };
      });
  }, [columnLabelMap, columns, options]);

  return (
    <>
      <Checkbox
        checked={isAverageMode}
        disabled={columns && columns[0] && columns[0][0].isDiscreteProperty}
        onChange={onAverageModeChange}
      >
        Show average
      </Checkbox>

      <div className={styles.properties}>
        <span>Select Property</span>
        <MultiplePropertiesSelector
          allowOneProperty
          columnLabelMap={columnLabelMap}
          columns={columns}
          maxTagsInRow={1}
          options={circleOptions}
          tableColumnsMap={datasetColumnsMap}
          templateColumnsIds={datasetColumnsIds}
          onColumnsChange={onChange}
        />
      </div>
    </>
  );
};
