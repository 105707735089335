import { IMoosaDataService } from '@discngine/moosa-models';
import {
  IScoringTemplatesSubRoot,
  scoringTemplatesWithVersionsSlice,
  ScoringTemplatesWithVersionsSliceProvider,
} from '@discngine/moosa-store/scoringTemplates';
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import { ScoringTemplatesContext } from '../ScoringTemplatesContext';

import { MoosaTemplateSelectorWidgetStoreContext, store } from './store/store';

export type MoosaTemplateSelectorWidgetWrapperProps = {
  dataService: IMoosaDataService;
};
export const MoosaTemplateSelectorWidgetContextWrapper = ({
  dataService,
  children,
}: PropsWithChildren<MoosaTemplateSelectorWidgetWrapperProps>) => {
  return (
    <Provider context={MoosaTemplateSelectorWidgetStoreContext} store={store}>
      <ScoringTemplatesWithVersionsSliceProvider<IScoringTemplatesSubRoot>
        dataService={dataService}
        slice={scoringTemplatesWithVersionsSlice}
        SliceContext={ScoringTemplatesContext}
        storeContext={MoosaTemplateSelectorWidgetStoreContext}
      >
        {children}
      </ScoringTemplatesWithVersionsSliceProvider>
    </Provider>
  );
};
