import { classNames } from '@discngine/moosa-common';
import { ColorPickerPure } from '@discngine/moosa-shared-components';
import { Switch } from 'antd';
import React, { useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import styles from './Columns.module.less';

const DND_TYPE = 'ColumnItem';

interface DndItem {
  colId: string;
  type: string;
}

export interface IColumnViewData {
  colId: string;
  color: string;
  columnName: string;
  isVisible: boolean;
  isSystem: boolean;
  isNumber: boolean;
  isPinned: boolean;
  isAlwaysVisible: boolean;
}

export interface IColumnViewItem extends IColumnViewData {
  tableId: string;
  onChangeVisibility: (tableId: string, column: string, visible: boolean) => void;
  onChangeColumnOrder: (tableId: string, from: string, to: string) => void;
  onColorChange: (color: string) => void;
}

export const ColumnViewItem: React.FC<IColumnViewItem> = ({
  tableId,
  colId,
  color,
  columnName,
  isVisible,
  isSystem,
  isNumber,
  isPinned,
  isAlwaysVisible,
  onChangeVisibility,
  onChangeColumnOrder,
  onColorChange,
}) => {
  const changeVisibility = useCallback(
    (visible: boolean) => {
      onChangeVisibility(tableId, colId, visible);
    },
    [tableId, colId, onChangeVisibility]
  );

  const dragEnd = useCallback(
    (item: DndItem | undefined, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        onChangeColumnOrder(tableId, item.colId, dropResult.colId);
      }
    },
    [tableId, onChangeColumnOrder]
  );

  const DND_ITEM_TYPE = isPinned ? 'Pinned' : DND_TYPE;

  const [{ opacity }, drag, preview] = useDrag<DndItem, DndItem, { opacity: number }>({
    type: DND_ITEM_TYPE,
    item: { type: DND_ITEM_TYPE, colId },
    end: dragEnd,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const [, drop] = useDrop({
    accept: DND_TYPE,
    drop: () => ({ colId }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <li key={colId} ref={preview} className={styles.listItem} style={{ opacity }}>
      <div ref={drop} className={styles.system}>
        {!isSystem && <div ref={drag} className={styles.anchor} />}
        {isNumber && (
          <div className={styles.color}>
            <ColorPickerPure color={color} onColorChange={onColorChange} />
          </div>
        )}

        <div
          className={classNames({
            [styles.name]: true,
            [styles.notNumber]: !isNumber,
            [styles.system]: isSystem,
          })}
        >
          {columnName}
        </div>
        <Switch
          checked={isVisible}
          className={styles.switch}
          disabled={isAlwaysVisible}
          size="small"
          onChange={changeVisibility}
        />
      </div>
    </li>
  );
};
