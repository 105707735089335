import { IColorPalette, valueToRGB } from '@discngine/moosa-common';
import {
  DatasetRowId,
  IMoosaDataService,
  ISmartChartModel,
  IColumnHeader,
  isScoringColumn,
  MAX_DATASET,
  SpecialColumn,
} from '@discngine/moosa-models';
import { selectScoringTemplate } from '@discngine/moosa-store/scoringTemplate';
import { selectColumnHeaders } from '@discngine/moosa-store/tableConfig';
import {
  fetchCurrentDataWithScores,
  IRowsAndScores,
} from '@discngine/moosa-store/tableData';
import {
  ITableInfoState,
  selectTableId,
  selectTableInfo,
} from '@discngine/moosa-store/tableInfo';
import { notification } from 'antd';

import { AppDispatch, AppThunk } from '../store';

import {
  selectFullDataset,
  selectIsFirstChartOpen,
  selectScatterPlotState,
  selectSmartChartState,
} from './charts.selector';
import { smartChartOptionsToModelParams } from './charts.service';
import {
  DEFAULT_CHART_POINT_COLOR,
  overwriteScatterPlotData,
  overwriteSmartChartData,
  setFullDataset,
  setScatterPlotColorColumn,
  setScatterPlotColorPalette,
  setScatterPlotColumnX,
  setScatterPlotColumnY,
  setScatterPlotSizeColumn,
  setScatterPlotSizeOrder,
  setSmartChartColorColumn,
  setSmartChartColorPalette,
  setSmartChartData,
  setSmartChartIterations,
  setSmartChartSizeColumn,
  setSmartChartSizeOrder,
  setSmartChartState,
  toggleChartFirstOpen,
  toggleChartLoading,
  toggleChartMode,
  toggleScatterPlotColumns,
} from './charts.slice';
import { CalculationState, IScatterPlotPoint, SizeSortOrder } from './IChartsState';
import { greenRedPaletteGenerator } from './paletteUtils';

const DELAY_BETWEEN_UPDATES = 1000; // ms

export function fetchSmartChart(
  getSmartChart: IMoosaDataService['getSmartChart']
): AppThunk {
  return async function fetchSmartChartThunk(dispatch, getState) {
    const tableId = selectTableId(getState());

    let smartChart = null;

    try {
      smartChart = await getSmartChart(tableId);
    } catch (err) {
      console.error(err);
    }

    try {
      if (!smartChart || !smartChart.params) {
        dispatch(setSmartChartState(CalculationState.NotCreated));

        return; // not created
      }

      if (smartChart.params.iterations > smartChart.iteration) {
        // calculation is in progress
        smartChart = await waitForCalculation(tableId, dispatch as any, getSmartChart);
      }

      if (smartChart && smartChart.result) {
        dispatch(
          setSmartChartData({ result: smartChart.result, rowIds: smartChart.rowIds! })
        );
      }

      console.info('smartChart', smartChart);
    } catch (err: any) {
      dispatch(setSmartChartState(CalculationState.NotCreated));
      notification.error({
        message: err.message,
      });
    }
  };
}

export function fetchNewSmartChart(service: {
  smartChartCreate: IMoosaDataService['smartChartCreate'];
  getSmartChart: IMoosaDataService['getSmartChart'];
}): AppThunk {
  return async function fetchNewSmartChartThunk(dispatch, getState) {
    const tableId = selectTableId(getState());
    const params = selectSmartChartState(getState()).options;

    try {
      cancelSmartChartCalculations();
      const payload = smartChartOptionsToModelParams(params);

      await service.smartChartCreate(tableId, payload);

      dispatch(
        setSmartChartIterations({
          current: 0,
          max: params.iterations,
        })
      );

      const smartChart = await waitForCalculation(
        tableId,
        dispatch as any,
        service.getSmartChart
      );

      if (smartChart && smartChart.result) {
        dispatch(
          setSmartChartData({ result: smartChart.result, rowIds: smartChart.rowIds! })
        );
      }
    } catch (err: any) {
      dispatch(setSmartChartState(CalculationState.NotCreated));
      notification.error({
        message: err.message,
      });
    }
  };
}

let cancelCalculation: null | (() => void) = null;

export function cancelSmartChartCalculations() {
  if (cancelCalculation) {
    cancelCalculation();
    cancelCalculation = null;
  }
}

export async function waitForCalculation(
  tableId: string,
  dispatch: AppDispatch,
  getSmartChart: IMoosaDataService['getSmartChart']
): Promise<ISmartChartModel | null> {
  let smartChart: ISmartChartModel | null = null;
  let counter = 1;
  let prevIteration = 0;
  let isCancelled = false;
  const cancel = () => (isCancelled = true);

  cancelSmartChartCalculations();
  cancelCalculation = cancel;

  await new Promise((resolve) => setTimeout(resolve, DELAY_BETWEEN_UPDATES));
  do {
    if (isCancelled) {
      return null;
    }
    smartChart = await getSmartChart(tableId);
    const currentIteration = smartChart.iteration || 0;

    dispatch(
      setSmartChartIterations({
        current: currentIteration,
        max: smartChart.params!.iterations,
      })
    );

    if (counter % 5 === 0) {
      if (currentIteration === prevIteration) {
        break; // TODO temporary solution to finish stuck job
      } else {
        prevIteration = currentIteration;
      }
    }
    counter += 1;

    await new Promise((resolve) => setTimeout(resolve, DELAY_BETWEEN_UPDATES));
  } while ((smartChart.iteration || 0) < smartChart.params!.iterations);

  return smartChart;
}

export function applyToggleCharts(
  getDataWithScore: IMoosaDataService['getDataWithScore'],
  showCharts: boolean
): AppThunk {
  return function openChartsThunk(dispatch, getState) {
    dispatch(toggleChartMode(showCharts));

    if (showCharts) {
      dispatch(fetchFullDataset(getDataWithScore));
    }
  };
}

export function fetchFullDataset(
  getDataWithScore: IMoosaDataService['getDataWithScore']
): AppThunk {
  return async function fetchFullDatasetThunk(dispatch, getState) {
    const state = getState();
    const columns = selectColumnHeaders(state);
    const template = selectScoringTemplate(state);
    const isFirstOpen = selectIsFirstChartOpen(state);

    if (isFirstOpen) {
      const scoreColumn = columns.find((col) => col.type === SpecialColumn.Score) || null;

      dispatch(setSmartChartColorColumn(scoreColumn));
    }
    dispatch(toggleChartLoading(true));
    try {
      const data = await dispatch(
        fetchCurrentDataWithScores(template, getDataWithScore, 1, MAX_DATASET)
      );

      dispatch(setFullDataset(data));
      dispatch(applyPropsToSmartChart());
      dispatch(applyPropsToScatterPlot());
    } finally {
      dispatch(toggleChartFirstOpen(false));
      dispatch(toggleChartLoading(false));
    }
  };
}

export function applyColorToSmartChart(col: IColumnHeader | null): AppThunk {
  return function applyColorToSmartChartThunk(dispatch, getState) {
    dispatch(setSmartChartColorColumn(col));
    dispatch(applyPropsToSmartChart());
  };
}

export function applySizeToSmartChart(col: IColumnHeader | null): AppThunk {
  return function applySizeToSmartChartThunk(dispatch, getState) {
    dispatch(setSmartChartSizeColumn(col));
    dispatch(applyPropsToSmartChart());
  };
}

export function applySizeOrderToSmartChart(sizeSortOrder: SizeSortOrder): AppThunk {
  return function applySizeOrderToSmartChartThunk(dispatch, getState) {
    dispatch(setSmartChartSizeOrder(sizeSortOrder));
    dispatch(applyPropsToSmartChart());
  };
}

export function applyColorPaletteToSmartChart(palette: IColorPalette): AppThunk {
  return function applyColorPaletteToSmartChartThunk(dispatch, getState) {
    dispatch(setSmartChartColorPalette(palette));
    const smartChartState = selectSmartChartState(getState());

    if (smartChartState.colorColumn) {
      dispatch(applyPropsToSmartChart());
    }
  };
}

export function applyPropsToSmartChart(): AppThunk {
  return function applyPropsToSmartChartThunk(dispatch, getState) {
    const data = selectFullDataset(getState());

    const smartChartState = selectSmartChartState(getState());
    const points = smartChartState.points;
    const colorColumn = smartChartState.colorColumn;
    const sizeColumn = smartChartState.sizeColumn;
    const sizeSortOrder = smartChartState.sizeSortOrder;
    const tableInfo = selectTableInfo(getState());
    const colorPalette = smartChartState.colorPalette;
    const rowIds = smartChartState.rowIds;

    const newPoints = points.map((point, index) => {
      const rowId = rowIds[index];
      const newPoint = {
        ...point,
        color: getPointColor(colorColumn, rowId, data, tableInfo, colorPalette),
        size: getPointSize(sizeColumn, rowId, data, tableInfo, sizeSortOrder),
      };

      return newPoint;
    });

    dispatch(overwriteSmartChartData(newPoints));
  };
}

/** ScatterPlot **/

export function applyColumnXToScatterPlot(col: IColumnHeader | null): AppThunk {
  return function applyColumnXToScatterPlotThunk(dispatch, getState) {
    dispatch(setScatterPlotColumnX(col));
    dispatch(applyPropsToScatterPlot());
  };
}

export function applyColumnYToScatterPlot(col: IColumnHeader | null): AppThunk {
  return function applyColumnYToScatterThunk(dispatch, getState) {
    dispatch(setScatterPlotColumnY(col));
    dispatch(applyPropsToScatterPlot());
  };
}

export function applyToggleColumnsToScatterPlot(): AppThunk {
  return function applyToggleColumnsToScatterThunk(dispatch, getState) {
    dispatch(toggleScatterPlotColumns());
    dispatch(applyPropsToScatterPlot());
  };
}

export function applyColorToScatterPlot(col: IColumnHeader | null): AppThunk {
  return function applyColorToScatterPlotThunk(dispatch, getState) {
    dispatch(setScatterPlotColorColumn(col));
    dispatch(applyPropsToScatterPlot());
  };
}

export function applySizeToScatterPlot(col: IColumnHeader | null): AppThunk {
  return function applySizeToScatterPlotThunk(dispatch, getState) {
    dispatch(setScatterPlotSizeColumn(col));
    dispatch(applyPropsToScatterPlot());
  };
}

export function applySizeOrderToScatterPlot(sizeSortOrder: SizeSortOrder): AppThunk {
  return function applySizeOrderToScatterPlotThunk(dispatch, getState) {
    dispatch(setScatterPlotSizeOrder(sizeSortOrder));
    dispatch(applyPropsToScatterPlot());
  };
}

export function applyColorPaletteToScatterPlot(palette: IColorPalette): AppThunk {
  return function applyColorPaletteToScatterThunk(dispatch, getState) {
    dispatch(setScatterPlotColorPalette(palette));
    const ScatterPlotState = selectScatterPlotState(getState());

    if (ScatterPlotState.colorColumn) {
      dispatch(applyPropsToScatterPlot());
    }
  };
}

export function applyPropsToScatterPlot(): AppThunk {
  return function applyPropsToScatterPlotThunk(dispatch, getState) {
    const data = selectFullDataset(getState());
    const state = selectScatterPlotState(getState());
    const tableInfo = selectTableInfo(getState());

    let newPoints: IScatterPlotPoint[] = [];

    if (state.columnX && state.columnY) {
      newPoints = Object.keys(data.rows).map((rowId) => {
        const point: IScatterPlotPoint = {
          x: getPointValue(state.columnX, rowId, data),
          y: getPointValue(state.columnY, rowId, data),
          color: getPointColor(
            state.colorColumn,
            rowId,
            data,
            tableInfo,
            state.colorPalette
          ),
          size: getPointSize(
            state.sizeColumn,
            rowId,
            data,
            tableInfo,
            state.sizeSortOrder
          ),
        };

        return point;
      });
    }

    dispatch(overwriteScatterPlotData(newPoints));
  };
}

function getRatioFromData(
  column: IColumnHeader,
  rowId: DatasetRowId,
  data: IRowsAndScores,
  tableInfo: ITableInfoState
): number {
  if (!isScoringColumn(column)) {
    return NaN;
  }
  const metadata = tableInfo.columnsMap[column.columnId];

  if (!metadata.dataWidth) {
    return 0;
  }
  const min = metadata?.statistics?.min || 0;
  const row = data.rows[rowId];
  const val = row ? (row[column.columnId] as number) : 0;

  return (val - min) / metadata.dataWidth;
}

export function getPointColor(
  colorColumn: IColumnHeader | null,
  rowId: DatasetRowId,
  data: IRowsAndScores,
  tableInfo: ITableInfoState,
  colorPalette?: IColorPalette
): string {
  let ratio = NaN;

  if (colorColumn && colorColumn.type === SpecialColumn.Score) {
    ratio = data.scores[rowId]?.value ?? NaN;
  }

  if (isScoringColumn(colorColumn)) {
    ratio = getRatioFromData(colorColumn!, rowId, data, tableInfo);
  }

  if (!ratio && ratio !== 0) {
    return DEFAULT_CHART_POINT_COLOR;
  }

  if (colorPalette) {
    return valueToRGB(ratio, colorPalette);
  }

  return greenRedPaletteGenerator(ratio);
}

export function getPointSize(
  sizeColumn: IColumnHeader | null,
  rowId: DatasetRowId,
  data: IRowsAndScores,
  tableInfo: ITableInfoState,
  sizeSortOrder: SizeSortOrder
): number {
  let ratio = NaN;

  if (sizeColumn?.type === SpecialColumn.Score) {
    ratio = data.scores[rowId]?.value || 0;
  }

  if (isScoringColumn(sizeColumn)) {
    ratio = getRatioFromData(sizeColumn, rowId, data, tableInfo);
  }

  if (!ratio && ratio !== 0) {
    return 3;
  }

  const sorted = sizeSortOrder === SizeSortOrder.asc ? ratio : 1 - ratio;

  return 3 + 6 * sorted;
}

export function getPointValue(
  column: IColumnHeader | null,
  rowId: DatasetRowId,
  data: IRowsAndScores
): number {
  if (column && column.type === SpecialColumn.Score) {
    return data.scores[rowId].value ?? 0;
  }

  if (isScoringColumn(column)) {
    return data.rows[rowId][column!.columnId] as number;
  }

  return NaN;
}
