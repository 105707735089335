import { SwapOutlined, SignalFilled } from '@ant-design/icons';
import {
  IStructureRenderer,
  IMoosaMMPConfigTag,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { Table, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { FC, useMemo, JSXElementConstructor } from 'react';

import {
  TableBarStyle,
  TableValueStyle,
  ITableColumnStates,
  TableSorting,
} from '../MoosaMMPChartConfig';
import {
  MMPChartCentralMolecule,
  MMPChartTableMolecules,
  MMPChartBaseMolecule,
} from '../types';

import { CustomizedMoosaMMPCellStructureProps } from './MMPTableCellStructure';
import { MMPTableCellValue } from './MMPTableCellValue';
import styles from './MoosaMMPTable.module.less';

type PropertyNamesType = {
  columnId: string;
  title: string;
  value?: number | null;
}[];

type DataType = Record<string, MMPChartBaseMolecule | number>;

const calculateDelta = (moleculeValue: number, centralMoleculeValue: number) => {
  return moleculeValue && centralMoleculeValue
    ? `${moleculeValue > centralMoleculeValue ? '+' : ''}${
        moleculeValue < centralMoleculeValue ? '-' : ''
      }${Number(Math.abs(centralMoleculeValue - moleculeValue).toFixed(3))}`
    : '';
};

const generateMMPTableColumns = (
  columnNames: PropertyNamesType,
  centralMolecule: MMPChartCentralMolecule,
  tableBarStyle: TableBarStyle,
  tableValueStyle: TableValueStyle,
  StructureRenderer: IStructureRenderer,
  tableCollapsedRows: boolean[],
  tableColumnStates: ITableColumnStates,
  getColorString: (affection: number) => string,
  onChangeColumnInvert: (parameterId: string) => void,
  onChangeColumnSorting: (parameterId: string) => void,
  onChangeCollapsedState: (rowIndex: number) => void,
  TableStructureCellRenderer: JSXElementConstructor<CustomizedMoosaMMPCellStructureProps>
) => {
  const columns: ColumnsType<DataType> = columnNames.map((col, index) => {
    return {
      title: (_prop) => {
        return (
          <div style={{ width: '100%' }}>
            {col.title}
            {index !== 0 && (
              <span className={styles.columnActions}>
                <Button
                  icon={
                    <SwapOutlined
                      className={
                        tableColumnStates.invert[col.columnId]
                          ? styles.sorted
                          : styles.notSorted
                      }
                    />
                  }
                  shape="circle"
                  type="text"
                  onClick={() => onChangeColumnInvert(col.columnId)}
                />
                <Button
                  icon={
                    <SignalFilled
                      className={
                        tableColumnStates.sorting.parameterId === col.columnId
                          ? styles.sorted
                          : styles.notSorted
                      }
                      rotate={
                        tableColumnStates.sorting.parameterId === col.columnId &&
                        tableColumnStates.sorting.sortOrder === TableSorting.ASC
                          ? 270
                          : 90
                      }
                    />
                  }
                  shape="circle"
                  type="text"
                  onClick={() => onChangeColumnSorting(col.columnId)}
                />
              </span>
            )}
          </div>
        );
      },
      dataIndex: col.columnId,
      key: col.columnId,
      width: 170,
      render: (data, record, index) => {
        if (index === 0) {
          if (col.columnId === 'smiles')
            return (
              <TableStructureCellRenderer
                centralMolecule={centralMolecule}
                collapsed={false}
                radialMolecule={data}
                StructureRenderer={StructureRenderer}
              />
            );
          else return <MMPTableCellValue collapsed={false} value={col.value} />;
        } else {
          if (col.columnId === 'smiles')
            return (
              <TableStructureCellRenderer
                centralMolecule={centralMolecule}
                collapsed={tableCollapsedRows[record.key as number]}
                radialMolecule={data}
                rowIndex={record.key as number}
                StructureRenderer={StructureRenderer}
                onChangeCollapsedState={onChangeCollapsedState}
              />
            );
          else {
            const colorAffection = tableColumnStates.invert[col.columnId]
              ? 1 - data.affection
              : data.affection;
            const color = getColorString && getColorString(colorAffection);

            return (
              <MMPTableCellValue
                collapsed={tableCollapsedRows[record.key as number]}
                color={color}
                delta={calculateDelta(data.initialValue, col.value as number)}
                radialMolecule={data}
                tableBarStyle={tableBarStyle}
                tableValueStyle={tableValueStyle}
              />
            );
          }
        }
      },
      showSorterTooltip: false,
      sortOrder:
        tableColumnStates.sorting.parameterId === col.columnId
          ? tableColumnStates.sorting.sortOrder
          : null,
      sorter:
        index === 0
          ? false
          : (valA, valB) => {
              const cellA = valA[col.columnId] as MMPChartBaseMolecule;
              const cellB = valB[col.columnId] as MMPChartBaseMolecule;

              return (cellA.initialValue as number) - (cellB.initialValue as number);
            },
    };
  });

  return columns;
};

export interface MoosaMMPTableProps {
  StructureRenderer: IStructureRenderer;
  tableMolecules: MMPChartTableMolecules;
  tableBarStyle: TableBarStyle;
  tableValueStyle: TableValueStyle;
  tableCollapsedRows: boolean[];
  tableColumnStates: ITableColumnStates;
  TableStructureCellRenderer: JSXElementConstructor<CustomizedMoosaMMPCellStructureProps>;
  selectedColumns: IMoosaMMPConfigTag[][];
  columnLabelMap: IColumnLabelMap;
  getColorString: (affection: number) => string;
  onChangeCollapsedState: (rowIndex: number) => void;
  onChangeColumnInvert: (parameterId: string) => void;
  onChangeColumnSorting: (parameterId: string) => void;
}

export const MoosaMMPTable: FC<MoosaMMPTableProps> = ({
  StructureRenderer,
  tableMolecules,
  tableBarStyle,
  tableValueStyle,
  tableCollapsedRows,
  tableColumnStates,
  TableStructureCellRenderer,
  selectedColumns,
  columnLabelMap,
  getColorString,
  onChangeCollapsedState,
  onChangeColumnInvert,
  onChangeColumnSorting,
}: MoosaMMPTableProps) => {
  const properties = selectedColumns.map((col) => ({
    columnId: col[0].columnId,
    title: columnLabelMap[col[0].columnId].label,
    value: tableMolecules.parameters[col[0].columnId].centralMolecule.initialValue,
  }));

  const columns = useMemo(
    () =>
      generateMMPTableColumns(
        [
          {
            columnId: 'smiles',
            title: 'Smiles',
          },
          ...properties,
        ],
        properties.length
          ? tableMolecules.parameters[properties[0].columnId].centralMolecule
          : (tableMolecules.commonCentralMolecule as MMPChartCentralMolecule),
        tableBarStyle,
        tableValueStyle,
        StructureRenderer,
        tableCollapsedRows,
        tableColumnStates,
        getColorString,
        onChangeColumnInvert,
        onChangeColumnSorting,
        onChangeCollapsedState,
        TableStructureCellRenderer
      ),
    [
      properties,
      tableMolecules.parameters,
      tableMolecules.commonCentralMolecule,
      tableBarStyle,
      tableValueStyle,
      StructureRenderer,
      tableCollapsedRows,
      tableColumnStates,
      getColorString,
      onChangeColumnInvert,
      onChangeColumnSorting,
      onChangeCollapsedState,
      TableStructureCellRenderer,
    ]
  );

  const tableData = useMemo(() => {
    return tableMolecules.moleculeList.map((moleculeForRender, molIndex) => {
      const data: DataType = {
        key: molIndex,
        smiles: moleculeForRender,
      };

      properties.forEach((parameter) => {
        const molecule =
          tableMolecules.parameters[parameter.columnId].radialMolecules[molIndex];

        data[parameter.columnId] = molecule;
      });

      return data;
    });
  }, [tableMolecules.moleculeList, tableMolecules.parameters, properties]);

  return (
    <div className={styles.root}>
      <Table bordered columns={columns} dataSource={tableData} pagination={false} />
    </div>
  );
};
