import React from 'react';

import styles from './CoreList.module.less';
import { ICoreListItem, ICoreListItemProps, MemoCoreListItem } from './CoreListItem';

export type ICoreListProps = {
  cores: Array<Omit<ICoreListItem, 'selected'>>;
  currentCore: string;
  setCore: ICoreListItemProps['setCore'];
};

export const CoreList = React.memo(({ cores, currentCore, setCore }: ICoreListProps) => {
  return (
    <div className={styles.coreList}>
      {cores.map((core) => (
        <MemoCoreListItem
          moleculesCount={core.moleculesCount}
          selected={core.structure === currentCore}
          setCore={setCore}
          structure={core.structure}
        />
      ))}
    </div>
  );
});
