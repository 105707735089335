import { AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import React, { memo, useMemo } from 'react';

import { CellFactor, IFactorBar } from '../types';

const PADDING_FACTOR = 0.8;
const xMin = 0;
const yMin = 0;
const xMax = 100;
const yMax = 100;

interface Props {
  cellFactor: CellFactor<IFactorBar>;
}

export const BarChart = memo((props: Props) => {
  const { cellFactor } = props;

  const xScale = useMemo(
    () =>
      scaleBand({
        range: [xMin, xMax],
        round: true,
        domain: cellFactor.charts.map((x) => x.colId),
        padding: PADDING_FACTOR / cellFactor.charts.length,
      }),
    [cellFactor.charts]
  );

  const yScale = useMemo(
    () => scaleLinear<number>({ range: [yMax, yMin], round: true, domain: [0, 1] }),
    []
  );

  return (
    <svg
      height="100%"
      viewBox="0 0 100 100"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      {cellFactor.charts.map((x, idx) => {
        const barWidth = xScale.bandwidth();
        const barX = xScale(x.colId) ?? 0;
        const length = x.factorMax - x.factorMin || 1;

        const value = x.factor === x.factorMin ? x.factor : x.factor - x.factorMin;
        const normalizedValue = value / length;
        const barHeight = yMax - (yScale(normalizedValue) ?? 0);
        const barY = yMax - barHeight;
        const minDeviation = Math.max((x.factor - x.deviation - x.factorMin) / length, 0);
        const maxDeviation = (x.factor + x.deviation - x.factorMin) / length;

        const hasDeviation = x.deviation > 0;

        const sixthOfBarWidth = barWidth / 6;

        const deviationX1 = barX + sixthOfBarWidth;
        const deviationX2 = barX + barWidth - sixthOfBarWidth;

        return (
          <g key={`bar-${idx}`}>
            <Bar fill={x.color} height={barHeight} width={barWidth} x={barX} y={barY} />

            {hasDeviation && (
              <line
                stroke={'black'}
                x1={barX + barWidth / 2}
                x2={barX + barWidth / 2}
                y1={yScale(minDeviation)}
                y2={yScale(maxDeviation)}
              />
            )}

            {hasDeviation && (
              <line
                stroke="black"
                x1={deviationX1}
                x2={deviationX2}
                y1={yScale(minDeviation)}
                y2={yScale(minDeviation)}
              />
            )}

            {hasDeviation && (
              <line
                stroke="black"
                x1={deviationX1}
                x2={deviationX2}
                y1={yScale(maxDeviation)}
                y2={yScale(maxDeviation)}
              />
            )}
          </g>
        );
      })}

      <Group top={yMax}>
        <AxisBottom hideTicks scale={xScale} tickValues={[]}></AxisBottom>
      </Group>
    </svg>
  );
});
