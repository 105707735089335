import { createSelector } from '@reduxjs/toolkit';

import { GenericItem } from '@discngine/moosa-store/common';

import { ListState } from './types';

const selectState =
  <Item extends GenericItem>(path: string) =>
  (state: { [key: string]: ListState<Item> }) =>
    state[path];

const selectIds = <Item extends GenericItem>(path: string) =>
  createSelector(selectState<Item>(path), (state) => state.ids);

const selectItems = <Item extends GenericItem>(path: string) =>
  createSelector(selectState<Item>(path), (state) => state.items);

export const selectResult = <Item extends GenericItem>(path: string) =>
  createSelector(selectIds<Item>(path), selectItems<Item>(path), (ids, items) =>
    ids.map((id) => items[id])
  );

export const selectItemById = <Item extends GenericItem>(path: string, id: string) =>
  createSelector(selectItems<Item>(path), (items) =>
    id in items ? items[id] : undefined
  );

export const selectTotal = <Item extends GenericItem>(path: string) =>
  createSelector(selectState<Item>(path), (state) => state.total);

export const selectSkip = <Item extends GenericItem>(path: string) =>
  createSelector(selectState<Item>(path), (state) => state.skip);
