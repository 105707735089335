import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { RGroupId, SubstituentId } from '@discngine/moosa-cheminformatics-data';
import { classNames } from '@discngine/moosa-common';
import { Button } from 'antd';
import React, { FC, useCallback } from 'react';

import { useSarMatrix } from '../SarMatrixContext';

import styles from './SarMatrixTable.module.less';

export interface ISarMatrixRowLabelProps {
  isOptimizedMode: boolean;
  rowIndex: number;
  width: string;
  height: string;
  style: any;
  smiles: string | null;
  substituentId: SubstituentId | null;
  onPinIconClick: (axisId: RGroupId, id: SubstituentId) => void;
  axisId: RGroupId;
  locked: boolean;
}

export const SarMatrixRowLabel: FC<ISarMatrixRowLabelProps> = ({
  isOptimizedMode,
  rowIndex,
  style,
  height,
  width,
  smiles,
  substituentId,
  onPinIconClick,
  axisId,
  locked,
}) => {
  const { structureRenderer: StructureRenderer } = useSarMatrix();

  const onPinClick = useCallback(() => {
    if (substituentId) {
      onPinIconClick(axisId, substituentId);
    }
  }, [substituentId, onPinIconClick, axisId]);

  return (
    <div
      className={classNames(styles.cellWrap, {
        [styles.optimizedMode]: isOptimizedMode,
        [styles.secondCol]: isOptimizedMode && rowIndex % 2 !== 0,
      })}
      style={{
        ...style,
        height: height,
        marginTop: isOptimizedMode ? `${style.height}px` : undefined,
      }}
    >
      <div
        className={classNames(styles.cell, {
          [styles.rowLabelCellInOptimizedMode]: isOptimizedMode,
        })}
        style={{
          height: height,
          width: width,
        }}
      >
        <div
          className={classNames({
            [styles.lockedCellHeader]: locked,
            [styles.cellHeader]: !locked,
          })}
        >
          <Button
            className={styles.pinButton}
            ghost={true}
            icon={locked ? <PushpinFilled /> : <PushpinOutlined />}
            size="small"
            onClick={onPinClick}
          />
        </div>

        {smiles !== null && <StructureRenderer key={rowIndex} smiles={smiles} />}
      </div>
    </div>
  );
};
