import { IMoosaSarConfigTag, SCORE_COLUMN_ID } from '@discngine/moosa-models';
import cloneDeep from 'lodash/cloneDeep';

export const updateTag = (
  templateColumnsIds: string[],
  sarColumns: IMoosaSarConfigTag[][]
): IMoosaSarConfigTag[][] => {
  const sarColumnsCopy = cloneDeep(sarColumns);

  const newSarColumns: IMoosaSarConfigTag[][] = [];

  sarColumnsCopy.forEach((sarColumnRow) => {
    const newSarColumnRow = sarColumnRow.map((tag) =>
      templateColumnsIds.includes(tag.columnId) || tag.columnId === SCORE_COLUMN_ID
        ? {
            ...tag,
            isScoreAvailable: true,
          }
        : { ...tag, isScoreAvailable: false, isScoredColorizingMode: false }
    );

    newSarColumns.push(newSarColumnRow);
  });

  return newSarColumns;
};
