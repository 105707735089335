import { MAX_DATASET } from '@discngine/moosa-models';
import { Sorting, GlobalSorting } from '@discngine/moosa-sar-radiant-chart';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMoosaMMPConfigTag } from '../sarRadiantChart/ISarRadiantChartState';

import {
  ISarRadiantChartMultiple,
  ISarRadiantChartMultipleState,
} from './ISarRadiantChartMultipleState';

const initialState: ISarRadiantChartMultipleState = {
  config: {},
  columns: [],
  globalSorting: {
    type: GlobalSorting.PARAMETER,
    mainParameter: '',
    order: [],
  },
};

const initialMoleculeState: ISarRadiantChartMultiple = {
  parameter: '',
  pairs: [],
  sorting: Sorting.DESC,
  coreID: '',
  maxCompounds: MAX_DATASET,
  selection: null,
  molFile: null,
  isScoredDataMode: false,
  invertAxes: false,
};

export const sarRadiantChartMultipleSlice = createSlice({
  name: 'sarRadiantChartMultiple',
  initialState,
  reducers: {
    setInitialMoleculeM(state, action: PayloadAction<string>) {
      if (!state.config[action.payload])
        state.config[action.payload] = initialMoleculeState;
    },
    setParameterM(
      state,
      action: PayloadAction<{
        parameterId: string;
        value: string;
      }>
    ) {
      state.config[action.payload.parameterId].parameter = action.payload.value;
    },
    setSortingM(
      state,
      action: PayloadAction<{
        parameterId: string;
        value: Sorting;
      }>
    ) {
      state.config[action.payload.parameterId].sorting = action.payload.value;
    },
    setMMPColumnsM(state, action: PayloadAction<IMoosaMMPConfigTag[][]>) {
      state.columns = action.payload;
    },
    setGlobalSortingType(state, action: PayloadAction<GlobalSorting>) {
      state.globalSorting.type = action.payload;
    },
    setGlobalSortingParameter(state, action: PayloadAction<string>) {
      state.globalSorting.mainParameter = action.payload;
    },
    setGlobalSortingOrder(state, action: PayloadAction<string[]>) {
      state.globalSorting.order = action.payload;
    },
  },
});

export const {
  setInitialMoleculeM,
  setParameterM,
  setSortingM,
  setMMPColumnsM,
  setGlobalSortingType,
  setGlobalSortingParameter,
  setGlobalSortingOrder,
} = sarRadiantChartMultipleSlice.actions;
