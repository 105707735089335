import { JSXElementConstructor } from 'react';

export interface IStructureEditorSelection {
  atoms?: number[];
  bonds?: number[];
  enhancedFlags?: number[];
  rxnPluses?: number[];
  rxnArrows?: number[];
}

export interface IStructureEditorAPI {
  getStructure: (type: StructureType) => Promise<string | null>;
  getSelection: () => IStructureEditorSelection | null;
}

export interface IStructureEditorProps {
  structure: string;
  onInit: (editorApi: IStructureEditorAPI) => void;
  disableEditing?: boolean;
}

export enum StructureType {
  SMILES,
  MOLFILE,
}

export type IStructureEditor = JSXElementConstructor<IStructureEditorProps>;
