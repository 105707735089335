import { IStructureRenderer } from '@discngine/moosa-models';
import React from 'react';

import styles from './CoreItem.module.less';

export interface ICoreItemProps {
  structure: string;
  onShowEditor: () => void;
  StructureRenderer: IStructureRenderer;
}

export const CoreItem: React.FC<ICoreItemProps> = ({
  structure,
  onShowEditor,
  StructureRenderer,
}) => {
  return (
    <div className={styles.coreItem}>
      {structure && (
        <div onClick={onShowEditor}>
          <StructureRenderer smiles={structure} />
        </div>
      )}
      {!structure.length && (
        <div className={styles.coreItemText} onClick={onShowEditor}>
          First, select a core from the list (below)
        </div>
      )}
    </div>
  );
};
