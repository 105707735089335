import { IStructureRenderer } from '@discngine/moosa-models';
import { Popover } from 'antd';
import React, { useCallback, useState, FC } from 'react';

import {
  MMPChartBaseMolecule,
  MMPChartCentralMolecule,
  MMPChartHoveredMolecule,
} from '../../types';
import { PopupMoleculeComparison } from '../PopupMoleculeComparison/PopupMoleculeComparison';
import { RadialMolecule } from '../RadialMolecule/RadialMolecule';
import { FullSector } from '../Sector/FullSector';
import { Sector } from '../Sector/Sector';
import { formatChartCaption } from '../utils';

import styles from './MMPChartBase.module.less';

const scale = 2;
// const center = { x: 320 * scale, y: 320 * scale };
const r0 = 90 * scale;
const r1 = 159 * scale;
const r2 = 228 * scale;
const strokeColor = '#C1C1C1';
const { round, min, max, abs } = Math;
const moleculeSVG = { width: 250, height: 200 };

export interface IMMPChartBase {
  centralMolecule: MMPChartCentralMolecule;
  chartMolecules: Array<MMPChartBaseMolecule>;
  numberOfElements: number;
  getColorString: (affection: number, hasValue?: boolean) => string;
  StructureRenderer: IStructureRenderer;
  hoveredMoleculeId?: string;
  invertAxes?: boolean;
  showRadialMolecules?: boolean;
  disablePopup?: boolean;
  parameter: string;
  onHoverSector?: (hoveredMolecule: MMPChartHoveredMolecule) => void;
}

export const MMPChartBase: FC<IMMPChartBase> = ({
  StructureRenderer,
  centralMolecule,
  chartMolecules,
  getColorString,
  numberOfElements,
  hoveredMoleculeId,
  invertAxes,
  showRadialMolecules,
  disablePopup,
  parameter,
  onHoverSector,
}: IMMPChartBase) => {
  const [hoveredMolecule, setHoveredMolecule] = useState<MMPChartHoveredMolecule>({
    id: '',
    core: '',
    structure: '',
    value: null,
    parameter: '',
    color: '',
  });

  const onEnterSector = useCallback(
    (index: number, color: string) => () => {
      const hoveredMolecule = {
        id: chartMolecules[index].id,
        core: chartMolecules[index].core,
        structure: chartMolecules[index].structure,
        value: chartMolecules[index].initialValue,
        parameter: parameter,
        color: color,
      };

      setHoveredMolecule(hoveredMolecule);
      onHoverSector && onHoverSector(hoveredMolecule);
    },
    [chartMolecules, onHoverSector, parameter]
  );
  const onLeaveSector = useCallback(() => {
    const hoveredMolecule = {
      id: '',
      core: '',
      structure: '',
      value: null,
      parameter: '',
      color: '',
    };

    setHoveredMolecule(hoveredMolecule);
    onHoverSector && onHoverSector(hoveredMolecule);
  }, [onHoverSector]);

  const smallR = invertAxes ? r2 : r0;
  const middleR = r1;
  const bigR = invertAxes ? r0 : r2;
  const stepAngle = (2 * Math.PI) / numberOfElements;

  const centralTranslateX = -round(moleculeSVG.width / 2);
  const centralTranslateY = -round(moleculeSVG.height / 2);

  const turnDegree = -90;
  const turnDegreePi = -Math.PI / 2;

  let minValue = min(
    ...chartMolecules.filter((x) => x.initialValue !== null).map((x) => x.initialValue!)
  );
  let maxValue = max(
    ...chartMolecules.filter((x) => x.initialValue !== null).map((x) => x.initialValue!)
  );
  const centralValue = centralMolecule.initialValue;

  if (centralValue !== null) {
    if (abs(centralValue - maxValue) > abs(centralValue - minValue)) {
      minValue = centralValue - abs(centralValue - maxValue);
    } else {
      maxValue = centralValue + abs(centralValue - minValue);
    }
  }

  const willShowChartCaptions = chartMolecules.length > 0;

  const viewBoxLeft = !showRadialMolecules ? -510 : -760;
  const viewBoxTop = !showRadialMolecules ? -510 : -760;
  const viewBoxRight = !showRadialMolecules ? 1015 : 1520;
  const viewBoxBottom = !showRadialMolecules ? 1015 : 1520;

  let outerCircleCaptionY = -bigR - 30;
  let innerCircleCaptionY = -smallR;

  if (invertAxes) {
    outerCircleCaptionY = -bigR;
    innerCircleCaptionY = -smallR - 30;
  }

  const centralMoleculeCoreStructure = [
    { core: hoveredMolecule.core, color: 'transparent' },
  ];

  return (
    <>
      <svg
        viewBox={`${viewBoxLeft} ${viewBoxTop} ${viewBoxRight} ${viewBoxBottom}`}
        width="100%"
      >
        {/*RADIAL MOLECULES*/}
        {showRadialMolecules && (
          <g>
            {chartMolecules.map((molecule, index) => {
              let position = index;
              let turnPi = turnDegreePi;

              if (chartMolecules.length === 1) {
                position = -0.5;
                turnPi = 0;
              }

              return (
                <RadialMolecule
                  key={index}
                  height={moleculeSVG.height}
                  index={position}
                  molecule={molecule}
                  r2={r2}
                  setHoveredMolecule={setHoveredMolecule}
                  stepAngle={stepAngle}
                  StructureRenderer={StructureRenderer}
                  turnDegreePi={turnPi}
                  width={moleculeSVG.width}
                />
              );
            })}
          </g>
        )}

        {/*SECTORS*/}
        <Popover
          content={
            <PopupMoleculeComparison
              centralMolecule={centralMolecule}
              hoveredMolecule={hoveredMolecule}
              StructureRenderer={StructureRenderer}
            />
          }
          placement="leftTop"
          trigger={disablePopup ? '' : 'hover'}
        >
          <g transform={`rotate(${turnDegree})`}>
            {chartMolecules.map((molecule, index) => {
              const colorAffection = invertAxes
                ? 1 - molecule.affection
                : molecule.affection;

              const missingValue = molecule.activity === null ? true : false;

              const color =
                hoveredMoleculeId === molecule.id && missingValue
                  ? '#DDDDDD'
                  : getColorString(colorAffection, missingValue);

              const opacity =
                !hoveredMoleculeId || hoveredMoleculeId === molecule.id || missingValue
                  ? 1
                  : 0.2;

              if (chartMolecules.length === 1) {
                return (
                  <FullSector
                    key={index}
                    color={color}
                    molecule={molecule}
                    r0={smallR}
                    r1={middleR}
                    r2={bigR}
                    strokeColor={strokeColor}
                    onEnterSector={onEnterSector(index, color)}
                    onLeaveSector={onLeaveSector}
                  />
                );
              } else {
                return (
                  <Sector
                    key={index}
                    color={color}
                    getColorString={getColorString}
                    index={index}
                    molecule={molecule}
                    opacity={opacity}
                    r0={smallR}
                    r1={middleR}
                    r2={bigR}
                    stepAngle={stepAngle}
                    onEnterSector={onEnterSector(index, color)}
                    onLeaveSector={onLeaveSector}
                  />
                );
              }
            })}
          </g>
        </Popover>

        <circle cx={0} cy={0} fill="none" r={r2} stroke={strokeColor} strokeWidth="1" />
        <circle cx={0} cy={0} fill="none" r={r1} stroke={strokeColor} strokeWidth="1" />
        <circle cx={0} cy={0} fill="none" r={r0} stroke={strokeColor} strokeWidth="1" />

        {/*CENTRAL MOLECULE*/}
        {centralMolecule.structure && (
          <foreignObject
            height="250"
            transform={`translate(${centralTranslateX} ${centralTranslateY})`}
            width="250"
          >
            {StructureRenderer && (
              <StructureRenderer
                alignSmiles={[]}
                coreSmiles={centralMoleculeCoreStructure}
                disableQueue={true}
                smiles={centralMolecule.structureMOLFILE}
              />
            )}
          </foreignObject>
        )}

        {/*IN CASE YOU NEED SAFE WAY TO RENDER SVG FROM A STRING (AS RASTERIZED PICTURE)*/}
        {/*<img src={`data:image/svg+xml;base64,${btoa(svgStr)}`}/>*/}

        {willShowChartCaptions && (
          <>
            <foreignObject
              className={styles.captionWrapper}
              height={30}
              transform={`translate(-50, ${innerCircleCaptionY})`}
              width={100}
            >
              <span className={styles.valueCaption}>
                {formatChartCaption(minValue, centralValue)}
              </span>
            </foreignObject>

            <foreignObject
              className={styles.captionWrapper}
              height={30}
              transform={`translate(-50, ${-middleR - 10})`}
              width={100}
            >
              <span className={styles.valueCaption}>
                {formatChartCaption(centralValue, centralValue)}
              </span>
            </foreignObject>

            <foreignObject
              className={styles.captionWrapper}
              height={30}
              transform={`translate(-50, ${outerCircleCaptionY})`}
              width={100}
            >
              <span className={styles.valueCaption}>
                {formatChartCaption(maxValue, centralValue)}
              </span>
            </foreignObject>
          </>
        )}
      </svg>
    </>
  );
};
