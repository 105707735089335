import { WarningFilled } from '@ant-design/icons';
import { IStructureRenderer } from '@discngine/moosa-models';
import { Alert } from 'antd';
import React from 'react';

import { MMPChartBase } from '../MoosaMMPChart/MMPChartBase/MMPChartBase';
import { MMPChartBaseMolecule, MMPChartCentralMolecule } from '../types';

export interface IMoosaMMPChartSingle {
  centralMolecule: MMPChartCentralMolecule;
  numberOfElements: number;
  parameterID: string;
  radialMolecules: Array<MMPChartBaseMolecule>;
  getColorString: (affection: number) => string;
  StructureRenderer: IStructureRenderer;
  invertAxes?: boolean;
  showRadialMolecules: boolean;
}

export const MoosaMMPChartSingle: React.FC<IMoosaMMPChartSingle> = ({
  StructureRenderer,
  centralMolecule,
  numberOfElements,
  parameterID,
  radialMolecules,
  getColorString,
  invertAxes,
  showRadialMolecules,
}: IMoosaMMPChartSingle) => {
  if (!parameterID) return null;

  const alertMessage = centralMolecule.structure
    ? 'Molecule has no value for this parameter'
    : 'Please add molecule to compare with';

  const alertType = centralMolecule.structure ? 'error' : 'info';

  return (
    <div>
      {!centralMolecule.initialValue && (
        <Alert
          icon={<WarningFilled />}
          message={alertMessage}
          showIcon
          type={alertType}
        />
      )}
      {parameterID && centralMolecule.initialValue && (
        <MMPChartBase
          centralMolecule={centralMolecule}
          chartMolecules={radialMolecules}
          getColorString={getColorString}
          invertAxes={invertAxes}
          numberOfElements={numberOfElements}
          parameter={parameterID}
          showRadialMolecules={showRadialMolecules}
          StructureRenderer={StructureRenderer}
        />
      )}
    </div>
  );
};
