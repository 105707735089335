import { IJobStatus } from '@discngine/moosa-models';
import { Button } from 'antd';
import React, { FC, useCallback } from 'react';

import styles from './SarMatrixJobControlPanel.module.less';

export interface ISarMatrixJobControlPanelProps {
  jobStatus: IJobStatus;
  calculateBtnDisabled: boolean;
  onStartJob: () => void;
  onStopJob: (jobID: string) => void;
  failedMoleculesQty: number;
  unmatchedIndicesQty: number;
}

export const SarMatrixJobControlPanel: FC<ISarMatrixJobControlPanelProps> = ({
  jobStatus,
  calculateBtnDisabled,
  onStartJob,
  onStopJob,
  unmatchedIndicesQty,
  failedMoleculesQty,
}) => {
  const stopHandler = useCallback(
    () => onStopJob(jobStatus.id),
    [jobStatus.id, onStopJob]
  );

  return (
    <div>
      <div className={styles.firstRow}>Job info:</div>
      <div className={styles.fieldName}>id</div>
      <div className={styles.fieldValue}>{jobStatus.id}</div>
      <div className={styles.fieldName}>status</div>
      <div className={styles.fieldValue}>{jobStatus.status}</div>
      {jobStatus.info && (
        <>
          <div className={styles.fieldName}>description</div>
          <div className={styles.fieldValue}>{jobStatus.info}</div>
        </>
      )}
      {unmatchedIndicesQty !== 0 && (
        <>
          <div className={styles.fieldName}>Unmatched Indices #</div>
          <div className={styles.fieldValue}>{unmatchedIndicesQty}</div>
        </>
      )}
      {failedMoleculesQty !== 0 && (
        <>
          <div className={styles.fieldName}>Failed Molecules #</div>
          <div className={styles.fieldValue}>{failedMoleculesQty}</div>
        </>
      )}
      <div className={styles.buttonsGroup}>
        <Button disabled={calculateBtnDisabled} onClick={onStartJob}>
          Calculate
        </Button>
        <Button disabled={!calculateBtnDisabled} onClick={stopHandler}>
          Terminate
        </Button>
      </div>
    </div>
  );
};
