import React, { FC } from 'react';

import styles from './styles.module.less';

export type IAveragedModeTableCellProps = {
  substanceIds: string[];
  numberOfExistingScores: number;
  averagedColor: string;
};

const CELL_SIZE = 100;

export const CircleChartAverageMode: FC<IAveragedModeTableCellProps> = ({
  averagedColor,
  numberOfExistingScores,
  substanceIds,
}) => {
  const radius = (CELL_SIZE * 0.97) / 2;

  return (
    <div className={styles.root}>
      <svg
        fill="none"
        height="100%"
        viewBox="0 0 100 100"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        {substanceIds.length > 0 && (
          <>
            <circle
              cx={50}
              cy={50}
              fill={averagedColor ? averagedColor : ''}
              r={radius}
              stroke={'gray'}
              strokeWidth={averagedColor ? 0 : 3}
            />
            {numberOfExistingScores > 1 && (
              <>
                <circle
                  cx={83}
                  cy={17}
                  fill={'#004D67'}
                  r={radius / 3.75}
                  stroke={'white'}
                  strokeWidth={2}
                />
                <text
                  alignmentBaseline="middle"
                  fill="white"
                  textAnchor="middle"
                  x="83"
                  y="17"
                >
                  {numberOfExistingScores}
                </text>
              </>
            )}
          </>
        )}
      </svg>
    </div>
  );
};
