import { MMPView } from '@discngine/moosa-models';
import React from 'react';

import { MoosaMMPChartMultiple } from '../MoosaMMPChartMultiple';
import { MoosaMMPChartSingle } from '../MoosaMMPChartSingle';
import { MoosaMMPTable } from '../MoosaMMPTable';

import styles from './MoosaMMP.module.less';
import { IMoosaMMPChart } from './types';

export const MoosaMMP: React.FC<IMoosaMMPChart> = ({
  centralMolecule,
  columnLabelMap,
  radialMolecules,
  multipleMolecules,
  tableMolecules,
  tableBarStyle,
  tableValueStyle,
  tableCollapsedRows,
  tableColumnStates,
  tableSelectedColumns,
  TableStructureCellRenderer,
  numberOfElements,
  parameterID,
  sortingM,
  StructureRenderer,
  getColorString,
  globalSorting,
  invertAxes,
  showRadialMolecules,
  selectedColumnsM,
  viewMode,
  onChangeGlobalSortingOrderM,
  onChangeSortingM,
  onChangeCollapsedState,
  onChangeColumnInvert,
  onChangeColumnSorting,
}) => {
  return (
    <>
      {viewMode === MMPView.Table && (
        <div className={styles.mmpTable}>
          <MoosaMMPTable
            columnLabelMap={columnLabelMap}
            getColorString={getColorString}
            selectedColumns={tableSelectedColumns}
            StructureRenderer={StructureRenderer}
            tableBarStyle={tableBarStyle}
            tableCollapsedRows={tableCollapsedRows}
            tableColumnStates={tableColumnStates}
            tableMolecules={tableMolecules}
            TableStructureCellRenderer={TableStructureCellRenderer}
            tableValueStyle={tableValueStyle}
            onChangeCollapsedState={onChangeCollapsedState}
            onChangeColumnInvert={onChangeColumnInvert}
            onChangeColumnSorting={onChangeColumnSorting}
          />
        </div>
      )}
      {viewMode === MMPView.RadiantChart && (
        <div className={styles.chartWrap}>
          <MoosaMMPChartSingle
            centralMolecule={centralMolecule}
            getColorString={getColorString}
            invertAxes={invertAxes}
            numberOfElements={numberOfElements}
            parameterID={parameterID}
            radialMolecules={radialMolecules}
            showRadialMolecules={showRadialMolecules}
            StructureRenderer={StructureRenderer}
          />
        </div>
      )}
      {viewMode === MMPView.MultipleRadiantChart && (
        <div>
          <MoosaMMPChartMultiple
            columnLabelMap={columnLabelMap}
            getColorString={getColorString}
            globalSorting={globalSorting}
            invertAxes={invertAxes}
            multipleMolecules={multipleMolecules}
            selectedColumns={selectedColumnsM}
            sortingM={sortingM}
            StructureRenderer={StructureRenderer}
            onChangeGlobalSortingOrderM={onChangeGlobalSortingOrderM}
            onChangeSortingM={onChangeSortingM}
          />
        </div>
      )}
    </>
  );
};
