import { IAppInfo, useMoosaDataContext } from '@discngine/moosa-models';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Header.less';

const Header: React.FC = () => {
  const [appInfo, setAppInfo] = useState<IAppInfo>({
    appName: '',
    version: 0,
    commit: '',
  });

  const service = useMoosaDataContext();

  useEffect(() => {
    service.getInfoAboutApplication().then((data: any) => setAppInfo(data[0]));
  }, [service]);

  const appVersion = appInfo.version ? `Version ${appInfo.version}` : '';
  const appCommit = appInfo.commit !== 'Unknown' ? appInfo.commit : '';

  return (
    <>
      <div className="logo mh-xs">
        <Link to="/">
          <img alt="Ideation Platform" className="active" src="./img/logo.png" />
        </Link>
      </div>
      <span className="additional-info">{`${appVersion} ${appCommit}`}</span>
    </>
  );
};

export default Header;
