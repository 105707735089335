import ResizeObserver from 'rc-resize-observer';
import React, { FC, useState } from 'react';

import { SarMatrixTable, SarMatrixTableProps } from '../SarMatrixTable/SarMatrixTable';

import styles from './MoosaSarMatrix.module.less';

export interface IMoosaSarMatrixProps {
  /**
   * X axis identifier
   * */
  xAxisId: string;
  /**
   * Y axis identifier
   * */
  yAxisId: string;

  /**
   * Molfile. Smiles that passed to structureRenderer
   * */
  coreStructure: string;

  /**
   * @param {string} axisId  - X or Y axis identifier pro
   * @param {string} substituentId - Y axis identifier
   * @returns {void}
   * */
  onAxisItemClick: SarMatrixTableProps['onHeaderCellClick'];

  /**
   * @type {Record<xAxisId, substituentId>} - map of values to define if a row or a column is pinned
   * */
  lockedMap: SarMatrixTableProps['lockedMap'];
}

export const MoosaSarMatrix: FC<IMoosaSarMatrixProps> = ({
  xAxisId,
  yAxisId,
  coreStructure,
  onAxisItemClick,
  lockedMap,
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  return (
    <div className={styles.root}>
      <ResizeObserver
        onResize={({ width, height }) => {
          setWidth(width);
          setHeight(height);
        }}
      >
        <div className={styles.main}>
          <SarMatrixTable
            lockedMap={lockedMap}
            patternStructure={coreStructure}
            tableHeight={height}
            tableWidth={width}
            xAxis={xAxisId}
            yAxis={yAxisId}
            onHeaderCellClick={onAxisItemClick}
          />
        </div>
      </ResizeObserver>
    </div>
  );
};
