import { SpecialColumn } from '@discngine/moosa-models';
import { selectTableColumnTypes } from '@discngine/moosa-store/tableConfig';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useScoringColumnKey = () => {
  const columnTypes = useSelector(selectTableColumnTypes);

  return useMemo(() => {
    const columnKeys = Object.keys(columnTypes);

    return columnKeys.find((key) => columnTypes[key] === SpecialColumn.Score) ?? '';
  }, [columnTypes]);
};
