import {
  IDatasetMetaModel,
  IColumnMetaInfo,
  ITableInfoState,
} from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { toColumnMetaInfo } from './tableInfo.service';

export const DEFAULT_SIDER_WIDTH = 355;
export const MAX_SIDER_WIDTH = DEFAULT_SIDER_WIDTH * 2;

const initialState: ITableInfoState = {
  id: '',
  name: '',
  isLoading: false,
  tableDataSize: 0,
  numRows: 0,
  numColumns: 0,
  columns: [],
  columnsMap: {},
  columnIds: [],
  columnIndexes: {},
  scoringTemplateId: null,
  comparisonScoringTemplateId: null,
  isTemplatesComparisonMode: false,
  missingValues: 0,
  siderWidth: DEFAULT_SIDER_WIDTH,
};

export interface ComputedColumnsChangesColUpdated {
  from: string;
  to: string;
}

export interface ComputedColumnsChanges {
  colUpdated?: ComputedColumnsChangesColUpdated | null;
  colAdded?: string | null;
  colDeleted?: string | null;
}

export interface InitTableInfoPayload {
  data: IDatasetMetaModel;
  computedColumnsChanges?: ComputedColumnsChanges;
}

export const tableInfoSlice = createSlice({
  name: 'tableInfo',
  initialState,
  reducers: {
    initTableInfo(state, action: PayloadAction<InitTableInfoPayload>) {
      const { data } = action.payload;

      state.id = data._id;
      state.name = data.fileName;
      state.tableDataSize = data.fileSize;
      state.numRows = data.rowCount;
      state.numColumns = data.fields.length;
      state.columns = toColumnMetaInfo(data.fields);
      state.columnIds = data.fields.map((field) => field.name);
      state.comparisonScoringTemplateId = null;
      state.isTemplatesComparisonMode = false;
      state.columnsMap = state.columns.reduce<{ [id: string]: IColumnMetaInfo }>(
        (acc, col) => {
          acc[col.name] = col;

          return acc;
        },
        {}
      );
      state.columnIndexes = state.columns.reduce<{ [id: string]: number }>(
        (acc, col, index) => {
          acc[col.name] = index;

          return acc;
        },
        {}
      );
      // TODO need to discuss
      state.scoringTemplateId = {
        parentId: data.scoringTemplateId,
        id: data.scoringTemplateId,
      };
      state.missingValues = data.missingValues;
    },
    toggleTableInfoLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsTemplatesComparisonMode(state, action: PayloadAction<boolean>) {
      state.isTemplatesComparisonMode = action.payload;
    },
    setTableScoringTemplateId(
      state,
      action: PayloadAction<{ parentId?: string; id: string } | null>
    ) {
      state.scoringTemplateId = action.payload
        ? { parentId: action.payload.parentId, id: action.payload.id }
        : null;
    },
    setTableComparisonScoringTemplateId(
      state,
      action: PayloadAction<{ parentId: string; id: string } | null>
    ) {
      state.comparisonScoringTemplateId = action.payload
        ? { parentId: action.payload.parentId, id: action.payload.id }
        : null;
    },
    setSiderWidth(state, action: PayloadAction<number>) {
      let width = action.payload;

      if (width > MAX_SIDER_WIDTH) {
        width = MAX_SIDER_WIDTH;
      } else if (width < DEFAULT_SIDER_WIDTH) {
        width = DEFAULT_SIDER_WIDTH;
      }
      state.siderWidth = width;
    },
  },
});

export const {
  initTableInfo,
  toggleTableInfoLoading,
  setTableScoringTemplateId,
  setTableComparisonScoringTemplateId,
  setIsTemplatesComparisonMode,
  setSiderWidth,
} = tableInfoSlice.actions;
