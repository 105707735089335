import { IColumnLabelMap, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { FC } from 'react';

import { TagWithCaption } from '../../../DraggableTags';
import { MoosaSarTagColorSelector } from '../components/MoosaSarTagColorSelector';

export interface MoosaSarConfigTagPureProps {
  className?: string;
  columnLabelMap?: IColumnLabelMap;
  onTagDelete: () => void;
  onChange: (tag: IMoosaSarConfigTag) => void;
  tag: IMoosaSarConfigTag;
}

export const MoosaSarConfigTagPure: FC<MoosaSarConfigTagPureProps> = ({
  className,
  tag,
  onChange,
  onTagDelete,
  columnLabelMap,
}) => {
  const label = columnLabelMap?.[tag.columnId]?.label;

  return (
    <TagWithCaption
      className={className}
      tagId={tag.columnId}
      tagLabel={label}
      onRemove={onTagDelete}
    >
      <MoosaSarTagColorSelector
        columnLabelMap={columnLabelMap}
        tag={tag}
        onChange={onChange}
      />
    </TagWithCaption>
  );
};
