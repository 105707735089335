import {
  TableBarStyle,
  TableValueStyle,
  TableSorting,
} from '@discngine/moosa-sar-radiant-chart';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMoosaMMPConfigTag } from '../sarRadiantChart/ISarRadiantChartState';

import { ISarTableState } from './ISarTableState';

const initialState: ISarTableState = {
  barStyle: TableBarStyle.VERTICAL,
  valueStyle: TableValueStyle.ABSOLUTE,
  collapsedRows: [],
  columnStates: {
    sorting: { parameterId: '', sortOrder: null },
    invert: {},
  },
  columns: [],
};

export const sarTableSlice = createSlice({
  name: 'sarTable',
  initialState,
  reducers: {
    setTableBarStyle(state, action: PayloadAction<TableBarStyle>) {
      state.barStyle = action.payload;
    },
    setTableValueStyle(state, action: PayloadAction<TableValueStyle>) {
      state.valueStyle = action.payload;
    },
    setTableCollapsedRows(state, action: PayloadAction<boolean[]>) {
      state.collapsedRows = action.payload;
    },
    setTableColumnSorting(
      state,
      action: PayloadAction<{
        parameterId: string;
        value: TableSorting | null;
      }>
    ) {
      state.columnStates.sorting.parameterId = action.payload.parameterId;
      state.columnStates.sorting.sortOrder = action.payload.value;
    },
    setTableColumnInvert(
      state,
      action: PayloadAction<{
        parameterId: string;
        value: boolean;
      }>
    ) {
      state.columnStates.invert[action.payload.parameterId] = action.payload.value;
    },
    setTableMMPColumns(state, action: PayloadAction<IMoosaMMPConfigTag[][]>) {
      state.columns = action.payload;
    },
  },
});

export const {
  setTableBarStyle,
  setTableValueStyle,
  setTableCollapsedRows,
  setTableColumnSorting,
  setTableColumnInvert,
  setTableMMPColumns,
} = sarTableSlice.actions;
