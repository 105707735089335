import {
  BooleanFlag,
  ISubstituentRecord,
  JobQueryInputFormat,
  RGroupId,
  SubstituentId,
} from '@discngine/moosa-cheminformatics-data';
import {
  IJobStatus,
  IMoosaSarConfigTag,
  JobStatus,
  Molfile,
  SarMatrixCellView,
} from '@discngine/moosa-models';
import { AxisValue } from '@discngine/moosa-sar-matrix';
import { ColumnWithColor } from '@discngine/moosa-shared-components';
import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISarMatrixResult, ISarMatrixState } from './ISarMatrixState';
import { selectAxisSortProps, selectRGroups } from './sarMatrix.selector';
import { SarMatrix } from './sarMatrix.types';

const initialState: ISarMatrixState = {
  jobStatus: {
    id: '',
    info: '',
    status: JobStatus.NotStarted,
  },
  results: null,
  requestPayload: {
    library: 'rdkit',
    scaffoldData: [],
    scaffoldType: JobQueryInputFormat.MOLFile,
    onlyMatchAtRGroups: BooleanFlag.True,
    removeAllHydrogenRGroups: BooleanFlag.False,
  },

  requestConfig: JSON.stringify({
    result_ttl: 500,
    job_timeout: 1200,
    job_priority: 'default',
  }),
  tableConfig: {
    cellView: SarMatrixCellView.Circles,
    xRGroup: '',
    xAxisSortProp: '',
    xAxisSortOrder: 'asc',
    yRGroup: '',
    yAxisSortProp: '',
    yAxisSortOrder: 'asc',
    coresStructures: [],
    coreStructure: '' as Molfile,
    axisSortProps: [],
    locked: {},
    isOpenCoreSelectionModal: false,
    circle: {
      selectedColumns: [],
      isAverageMode: false,
    },
    factorsAbsolute: {
      selectedColumns: [],
      isAverageMode: false,
    },
  },

  dataByCore: {},
};

export const sarMatrixSlice = createSlice({
  name: 'sarMatrix',
  initialState,
  reducers: {
    setJobStatus(state, action: PayloadAction<IJobStatus>) {
      state.jobStatus = action.payload;
    },
    setRGroupResult(state, action: PayloadAction<ISarMatrixResult>) {
      state.results = action.payload;

      state.tableConfig.coresStructures =
        action.payload.cores.map((core) => core.molfile) || [];

      action.payload.cores.forEach((core) => {
        const coreStructure = core.molfile;

        if (!state.dataByCore[coreStructure]) {
          state.dataByCore[coreStructure] = {
            axisValuesByRGroup: {},
            matrixByRGroup: {},
          };
        }
        const rgroups = core.rgroups;

        rgroups.forEach((rgroup) => {
          const axisSubstanceIds = new Set<SubstituentId>();

          core.mappings.forEach((substance) => {
            const substanceID = Reflect.get(substance, rgroup);

            if (!substanceID) {
              console.error('incorrect data, substance[rgroup]', substance, rgroup);
            } else {
              axisSubstanceIds.add(substanceID);
            }
          });
          const axisValues: AxisValue[] = [];

          axisSubstanceIds.forEach((substanceID) => {
            const axisValueData = action.payload.substituents[substanceID];

            const axisValue: AxisValue = {
              id: substanceID,
              groupStructure: axisValueData.molfile,
              logp: axisValueData.logp,
              fraction_csp3: axisValueData.fraction_csp3,
              molecular_weight: axisValueData.molecular_weight,
              nHBA: axisValueData.nHBA,
              nHBD: axisValueData.nHBD,
              nAromaticRings: axisValueData.nAromaticRings,
              nRings: axisValueData.nRings,
              tpsa: axisValueData.tpsa,
              nRotatableBonds: axisValueData.nRotatableBonds,
            };

            axisValues.push(axisValue);
          });

          state.dataByCore[coreStructure].axisValuesByRGroup[rgroup] = axisValues;
        });

        rgroups.forEach((xRGroup) => {
          const xAxisValues: AxisValue[] =
            state.dataByCore[coreStructure].axisValuesByRGroup[xRGroup];

          if (!state.dataByCore[coreStructure].matrixByRGroup[xRGroup]) {
            state.dataByCore[coreStructure].matrixByRGroup[xRGroup] = {};
          }
          rgroups.forEach((yRGroup) => {
            const yAxisValues: AxisValue[] =
              state.dataByCore[coreStructure].axisValuesByRGroup[yRGroup];

            if (!state.dataByCore[coreStructure].matrixByRGroup[xRGroup][yRGroup]) {
              state.dataByCore[coreStructure].matrixByRGroup[xRGroup][yRGroup] = {};
            }

            const matrix: SarMatrix = {};

            xAxisValues.forEach((xAxisValue) => {
              if (!matrix[xAxisValue.id]) {
                matrix[xAxisValue.id] = {};
              }
              yAxisValues.forEach((yAxisValue) => {
                if (!matrix[xAxisValue.id][yAxisValue.id]) {
                  matrix[xAxisValue.id][yAxisValue.id] = [];
                }

                core.mappings.forEach((substance) => {
                  if (
                    substance[xRGroup] === xAxisValue.id &&
                    substance[yRGroup] === yAxisValue.id
                  ) {
                    matrix[xAxisValue.id][yAxisValue.id].push(substance);
                  }
                });
              });
            });

            state.dataByCore[coreStructure].matrixByRGroup[xRGroup][yRGroup] = matrix;
          });
        });
      });

      const substituents = action.payload.substituents;
      const substituentProps = new Set<string>();

      Object.values(substituents).forEach((substituent) => {
        Object.keys(substituent).forEach((prop: string) => {
          if (typeof substituent[prop as keyof ISubstituentRecord] === 'number') {
            substituentProps.add(prop);
          }
        });
      });

      state.tableConfig.axisSortProps = Array.from(substituentProps);

      // after new results arrived, we want to init/update table config

      state.tableConfig.coreStructure = state.tableConfig.coresStructures[0];

      const rGroups = selectRGroups({ sarMatrix: state });

      if (rGroups.length >= 2) {
        state.tableConfig.xRGroup = rGroups[0];
        state.tableConfig.yRGroup = rGroups[1];
      }

      const sortProps = selectAxisSortProps({ sarMatrix: state });

      if (!state.tableConfig.xAxisSortProp) {
        state.tableConfig.xAxisSortProp = sortProps[0];
      }

      if (!state.tableConfig.yAxisSortProp) {
        state.tableConfig.yAxisSortProp = sortProps[0];
      }
    },
    setRequestConfig(state, action: PayloadAction<string>) {
      state.requestConfig = action.payload;
    },
    setScaffoldData(state, action: PayloadAction<Molfile[]>) {
      state.requestPayload.scaffoldData = action.payload;
    },
    setLibrary(state, action: PayloadAction<string>) {
      state.requestPayload.library = action.payload;
    },
    setOnlyMatchAtRGroups(state, action: PayloadAction<boolean>) {
      state.requestPayload.onlyMatchAtRGroups = action.payload
        ? BooleanFlag.True
        : BooleanFlag.False;
    },
    setRemoveAllHydrogenRGroups(state, action: PayloadAction<boolean>) {
      state.requestPayload.removeAllHydrogenRGroups = action.payload
        ? BooleanFlag.True
        : BooleanFlag.False;
    },
    setScaffoldType(state, action: PayloadAction<JobQueryInputFormat>) {
      state.requestPayload.scaffoldType = action.payload;
    },
    setCellView(state, action: PayloadAction<SarMatrixCellView>) {
      state.tableConfig.cellView = action.payload;
    },
    setConfigXRGroup(state, action: PayloadAction<string>) {
      state.tableConfig.xRGroup = action.payload;
    },
    setConfigAxisXSortProp(state, action: PayloadAction<string>) {
      state.tableConfig.xAxisSortProp = action.payload;
    },
    setConfigAxisXSortOrder(state, action: PayloadAction<'asc' | 'desc'>) {
      state.tableConfig.xAxisSortOrder = action.payload;
    },

    setConfigYRGroup(state, action: PayloadAction<string>) {
      state.tableConfig.yRGroup = action.payload;
    },
    setConfigAxisYSortProp(state, action: PayloadAction<string>) {
      state.tableConfig.yAxisSortProp = action.payload;
    },
    setConfigAxisYSortOrder(state, action: PayloadAction<'asc' | 'desc'>) {
      state.tableConfig.yAxisSortOrder = action.payload;
    },
    setCoreStructure(state, action: PayloadAction<Molfile>) {
      state.tableConfig.coreStructure = action.payload;
    },
    setSelectedColumnsCircle(state, action: PayloadAction<IMoosaSarConfigTag[][]>) {
      state.tableConfig.circle.selectedColumns = action.payload;
    },
    setSelectedColumnsFactorsAbsolute(state, action: PayloadAction<ColumnWithColor[]>) {
      state.tableConfig.factorsAbsolute.selectedColumns = action.payload;
    },
    setIsScoringAverageMode(state, action: PayloadAction<boolean>) {
      state.tableConfig.factorsAbsolute.isAverageMode = action.payload;
    },
    setIsAverageModeCircle(state, action: PayloadAction<boolean>) {
      state.tableConfig.circle.isAverageMode = action.payload;
    },
    setLocked(
      state,
      action: PayloadAction<{ RGroupId: RGroupId; substituentId: SubstituentId }>
    ) {
      if (state.tableConfig.locked[action.payload.RGroupId]) {
        delete state.tableConfig.locked[action.payload.RGroupId];
      } else {
        state.tableConfig.locked[action.payload.RGroupId] = action.payload.substituentId;
      }
    },
    setOpenCoreSelectionModal(state, action: PayloadAction<boolean>) {
      state.tableConfig.isOpenCoreSelectionModal = action.payload;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (state) => {
      state.results = initialState.results;
      state.jobStatus = initialState.jobStatus;
      state.tableConfig = initialState.tableConfig;
    },
  },
});

export const {
  setRGroupResult,
  setScaffoldData,
  setJobStatus,
  setOnlyMatchAtRGroups,
  setRemoveAllHydrogenRGroups,
  setCellView,
  setCoreStructure,
  setConfigXRGroup,
  setConfigAxisXSortProp,
  setConfigAxisXSortOrder,
  setConfigYRGroup,
  setConfigAxisYSortProp,
  setConfigAxisYSortOrder,
  setSelectedColumnsCircle,
  setSelectedColumnsFactorsAbsolute,
  setIsScoringAverageMode,
  setIsAverageModeCircle,
  setLocked,
} = sarMatrixSlice.actions;
