import { BooleanFlag } from '@discngine/moosa-cheminformatics-data';
import { SubstituentId } from '@discngine/moosa-cheminformatics-data';
import { JobStatus } from '@discngine/moosa-models';
import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';

import { ISarMatrixSubRoot } from './ISarMatrixState';
import { SarMatrix } from './sarMatrix.types';

export const selectResults = (state: ISarMatrixSubRoot) => state.sarMatrix.results;

export const selectRequestConfig = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.requestConfig;

export const selectScaffoldData = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.requestPayload.scaffoldData;

export const selectRequestInProgress = (state: ISarMatrixSubRoot) => {
  switch (state.sarMatrix.jobStatus.status) {
    case JobStatus.Failed:
    case JobStatus.Finished:
    case JobStatus.Deleted:
    case JobStatus.NotStarted:
      return false;
    case JobStatus.Started:
      return true;
    default:
      return false;
  }
};

export const selectJobStatus = (state: ISarMatrixSubRoot) => state.sarMatrix.jobStatus;

export const selectFailedMoleculesQty = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.results?.failedMolecules?.length || 0;

export const selectUnmatchedMolIndicesQty = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.results?.unmatchedMolIndices?.length || 0;

export const selectLibrary = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.requestPayload.library;

export const selectOnlyMatchAtRGroups = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.requestPayload.onlyMatchAtRGroups;

export const selectRemoveAllHydrogenRGroups = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.requestPayload.removeAllHydrogenRGroups;

export const selectOnlyMatchAtRGroupsForCheckbox = (state: ISarMatrixSubRoot): boolean =>
  state.sarMatrix.requestPayload.onlyMatchAtRGroups === BooleanFlag.True;

export const selectRemoveAllHydrogenRGroupsForCheckbox = (
  state: ISarMatrixSubRoot
): boolean =>
  state.sarMatrix.requestPayload.removeAllHydrogenRGroups === BooleanFlag.True;

export const selectCurrentCoreStructure = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.coreStructure;
};

export const selectCores = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.results?.cores;
};

export const selectCurrentCore = createSelector(
  [selectCurrentCoreStructure, selectCores],
  (structure, cores) => {
    return cores?.find((core) => core.molfile === structure);
  }
);

export const selectRGroups = createSelector([selectCurrentCore], (currentCore) => {
  if (!currentCore) return [];

  return currentCore.rgroups;
});

export const selectXRGroup = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.xRGroup;
};

export const selectYRGroup = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.yRGroup;
};

export const selectDataByCore = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.dataByCore;
};

export const selectAxisSortProps = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.axisSortProps;
};

export const selectXAxisRGroup = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.xRGroup;
};

export const selectXAxisSortProp = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.xAxisSortProp;
};

export const selectXAxisSortOrder = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.xAxisSortOrder;
};

export const selectYAxisRGroup = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.yRGroup;
};

export const selectYAxisSortProp = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.yAxisSortProp;
};

export const selectYAxisSortOrder = (state: ISarMatrixSubRoot) => {
  return state.sarMatrix.tableConfig.yAxisSortOrder;
};

export const selectCellView = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.cellView;

export const selectCoreStructure = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.coreStructure;

export const selectCoresStructures = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.coresStructures;

export const selectSelectedColumnsCircle = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.circle.selectedColumns;

export const selectSelectedScoringColumns = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.factorsAbsolute.selectedColumns;

export const selectIsScoringAverageMode = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.factorsAbsolute.isAverageMode;

export const selectIsAverageModeCircle = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.circle.isAverageMode;

export const selectLockedItems = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.locked;

export const selectSarMatrix = createSelector(
  [selectCurrentCore, selectXRGroup, selectYRGroup, selectDataByCore, selectLockedItems],
  (currentCore, xRGroup, yRGroup, dataByCore, locked): SarMatrix => {
    if (!currentCore || !xRGroup || !yRGroup) return {};

    let matrix = dataByCore[currentCore.molfile].matrixByRGroup[xRGroup][yRGroup];

    const lockedRGroups = Object.keys(locked);

    let newMatrix: SarMatrix = {};

    if (lockedRGroups.length > 0) {
      for (const i of Object.keys(matrix)) {
        if (!newMatrix[i]) newMatrix[i] = {};
        for (const k of Object.keys(matrix[i])) {
          if (!newMatrix[i][k]) newMatrix[i][k] = [];

          if (Array.isArray(matrix[i][k])) {
            newMatrix[i][k] = matrix[i][k].filter((item) => {
              for (const lockedRGroup of lockedRGroups) {
                if (item[lockedRGroup] !== locked[lockedRGroup]) {
                  return false;
                }
              }

              return true;
            });
          }
        }
      }

      matrix = newMatrix;
    }

    /// filter out empty columns
    let rows = Object.keys(matrix);

    for (const row of rows) {
      const columns = Object.keys(matrix[row]);
      let empty = true;

      for (const column of columns) {
        if (matrix[row][column].length > 0) empty = false;
      }

      if (empty) {
        const { [row]: _, ...rest } = matrix;

        matrix = rest;
      }
    }

    /// filter out empty rows
    rows = Object.keys(matrix);
    const emptyRowsMap = new Map<SubstituentId, boolean>();

    for (const row of rows) {
      const columns = Object.keys(matrix[row]);

      for (const column of columns) {
        if (matrix[row][column].length === 0) {
          if (emptyRowsMap.get(column) !== false) {
            emptyRowsMap.set(column, true);
          }
        } else {
          emptyRowsMap.set(column, false);
        }
      }
    }

    for (const row of rows) {
      const columns = Object.keys(matrix[row]);

      for (const column of columns) {
        if (emptyRowsMap.get(column) === true) {
          const { [column]: _, ...rest } = matrix[row];

          matrix[row] = rest;
        }
      }
    }

    return matrix;
  }
);

export const selectXAxisValues = createSelector(
  [
    selectCurrentCoreStructure,
    selectDataByCore,
    selectXAxisSortProp,
    selectXAxisSortOrder,
    selectLockedItems,
    selectSarMatrix,
    selectXAxisRGroup,
  ],
  (structure, dataByCore, xAxisSortProperty, xAxisSortOrder, locked, matrix, rgroup) => {
    const xAxisValuesWithProps = dataByCore[structure]?.axisValuesByRGroup[rgroup] || [];

    let values = orderBy(xAxisValuesWithProps, [xAxisSortProperty], [xAxisSortOrder]);

    const haveLockedItems = Object.keys(locked).length;

    if (haveLockedItems) {
      const rows = new Set<SubstituentId>(Object.keys(matrix));

      values = values.filter((value) => rows.has(value.id));
    }

    return values;
  }
);

export const selectYAxisValues = createSelector(
  [
    selectCurrentCoreStructure,
    selectDataByCore,
    selectYAxisSortProp,
    selectYAxisSortOrder,
    selectLockedItems,
    selectSarMatrix,
    selectYAxisRGroup,
  ],
  (structure, dataByCore, yAxisSortProperty, yAxisSortOrder, locked, matrix, rgroup) => {
    const yAxisValuesWithProps = dataByCore[structure]?.axisValuesByRGroup[rgroup] || [];

    let values = orderBy(yAxisValuesWithProps, [yAxisSortProperty], [yAxisSortOrder]);

    const haveLockedItems = Object.keys(locked).length;

    if (haveLockedItems) {
      let columns = new Set<SubstituentId>();
      const rows = Object.keys(matrix);

      for (const row of rows) {
        const cols = Object.keys(matrix[row]);

        for (const col of cols) {
          columns.add(col);
        }
      }

      values = values.filter((value) => columns.has(value.id));
    }

    return values;
  }
);

export const selectLockedList = createSelector(
  [selectLockedItems, selectResults],
  (locked, results) => {
    return Object.keys(locked).map((rgroup) => {
      return {
        rGroupId: rgroup,
        substituentId: locked[rgroup],
        structure: results?.substituents[locked[rgroup]]?.molfile || '',
      };
    });
  }
);

export const selectIsOpenCoreSelectionModal = (state: ISarMatrixSubRoot) =>
  state.sarMatrix.tableConfig.isOpenCoreSelectionModal;

export const selectNumberOfMoleculesPerCore = (state: ISarMatrixSubRoot) => {
  const result: Record<string, number> = {};

  state.sarMatrix.results?.cores.forEach((core) => {
    result[core.molfile || core.smiles] = Object.keys(core.mappings).length;
  });

  return result;
};

export const selectSarMatrixCoreOptions = createSelector(
  selectCoresStructures,
  selectNumberOfMoleculesPerCore,
  (structures, map) => {
    return structures.map((structure) => ({ structure, moleculesCount: map[structure] }));
  }
);
