let canvas: null | HTMLCanvasElement = null;

export const getTextWidth = (text: string) => {
  // re-use canvas object for better performance
  if (!canvas) {
    canvas = document.createElement('canvas');
  }

  const context = canvas.getContext('2d');

  return context?.measureText(text)?.width ?? 0;
};
