import { RightOutlined } from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { IStructureRenderer, StructureRendererCore } from '@discngine/moosa-models';
import { Popover } from 'antd';
import React, { FC } from 'react';

import { PopupMoleculeComparison } from '../MoosaMMPChart/PopupMoleculeComparison/PopupMoleculeComparison';
import {
  MMPChartBaseMolecule,
  MMPChartCentralMolecule,
  MMPChartHoveredMolecule,
} from '../types';

import { MenuItem, MenuOverlay } from './MenuRenderer/MenuRenderer';
import styles from './MoosaMMPTable.module.less';
export interface MoosaMMPCellStructureProps {
  menu: (moleculeId: string) => MenuItem[];
  collapsed: boolean;
  radialMolecule?: MMPChartBaseMolecule;
  centralMolecule: MMPChartCentralMolecule;
  rowIndex?: number;
  StructureRenderer: IStructureRenderer;
  onChangeCollapsedState?: (rowIndex: number) => void;
}

export type CustomizedMoosaMMPCellStructureProps = Omit<
  MoosaMMPCellStructureProps,
  'menu'
>;

export const MMPTableCellStructure: FC<MoosaMMPCellStructureProps> = React.memo(
  ({
    radialMolecule,
    centralMolecule,
    rowIndex,
    StructureRenderer,
    collapsed,
    onChangeCollapsedState,
    menu,
  }: MoosaMMPCellStructureProps) => {
    let moleculeStructure = '';
    let coreSmiles: StructureRendererCore[] = [];
    let alignStructures: string[] = [];
    let hoveredMolecule: MMPChartHoveredMolecule = {
      id: '',
      core: '',
      structure: '',
      value: null,
    };

    // this is a radial molecule row
    if (radialMolecule && onChangeCollapsedState) {
      moleculeStructure = radialMolecule.structure;
      coreSmiles = [{ core: radialMolecule.core, color: 'transparent' }];
      alignStructures = [radialMolecule.core];
      hoveredMolecule = { ...radialMolecule, value: radialMolecule.initialValue };
    }

    // this is the central molecule row
    if (centralMolecule && !onChangeCollapsedState)
      moleculeStructure = centralMolecule.structureMOLFILE;

    return (
      <>
        {/* FIRST COLUMN WITH MOLECULE, BOTH HEADER AND NORMAL ROWS */}
        {moleculeStructure && (
          <div
            className={classNames(styles.cell, styles.cellMolecule, {
              [styles.cellCollapsed]: collapsed,
            })}
          >
            {onChangeCollapsedState && (
              <span
                className={`${styles.cellMoleculeControl} ${
                  collapsed && styles.cellMoleculeControlCollapsed
                }`}
                onMouseDown={() => onChangeCollapsedState(rowIndex as number)}
              >
                <RightOutlined rotate={!collapsed ? 90 : 0} />
              </span>
            )}
            <Popover
              content={
                <PopupMoleculeComparison
                  centralMolecule={centralMolecule}
                  hoveredMolecule={hoveredMolecule}
                  noValues
                  StructureRenderer={StructureRenderer}
                />
              }
              placement="leftTop"
              trigger={onChangeCollapsedState ? 'hover' : ''}
            >
              {!collapsed && centralMolecule && (
                <div>
                  <StructureRenderer
                    alignSmiles={alignStructures}
                    coreSmiles={coreSmiles}
                    smiles={moleculeStructure}
                  />
                </div>
              )}
              {collapsed && radialMolecule && centralMolecule && (
                <div className={styles.cellMoleculeId}>
                  <span className={styles.cellMoleculeValue}>{radialMolecule.cid}</span>
                </div>
              )}
            </Popover>
          </div>
        )}
        <MenuOverlay menu={menu} />
      </>
    );
  }
);
