const re = /(?<sign>[+-]{1})?(?<intPart>\d+).(?<floatPart>\d+)e?(?<exp>[+\-\d]+)?/;
const createRegexReplacer = (precision: number) => {
  return (_match: any, sign: string, intPart: string, floatPart: string, exp: string) => {
    if (/[1-9]+/.test(floatPart) && !floatPart.startsWith('0'.repeat(precision))) {
      return `${sign || ''}${intPart}.${floatPart.slice(0, precision - 1)}e${exp}`;
    }

    return `${sign || ''}${intPart}e${exp}`;
  };
};

const isExponential = (num: number): boolean => {
  return /[+-]?\d+[.]?[\d]*e[+-]?\d+/.test(num.toString());
};

export const toPrecisionString = (num: number, precision: number) => {
  if (isNaN(num)) {
    return 'NaN';
  }

  const regExReplacer = createRegexReplacer(precision);

  if (Number.isInteger(num)) {
    const numberLength = num.toString().length;

    if (numberLength <= precision) {
      return num.toString();
    }

    return num.toExponential().replace(re, regExReplacer);
  }

  if (isExponential(num)) {
    return num.toString().replace(re, regExReplacer);
  }

  const [integerPart, floatPart] = num.toString().split('.');

  if (integerPart === '0') {
    if (floatPart.length < precision) {
      return parseFloat(num.toString()).toString();
    }

    return num.toExponential().replace(re, regExReplacer);
  }

  if (integerPart.length < precision) {
    const flPart = floatPart.slice(0, precision - integerPart.length);

    return `${integerPart}.${flPart}`;
  }

  if (integerPart.length === precision) {
    return integerPart.toString();
  }

  return Number(integerPart).toExponential().replace(re, regExReplacer);
};
