export const getAverage = (arr: number[]) =>
  arr.reduce((acc, x) => acc + x, 0) / arr.length || 0;

export const getDeviation = (arr: number[]) => {
  const average = getAverage(arr);

  return (
    Math.sqrt(arr.reduce((acc, x) => acc + Math.pow(x - average, 2), 0) / arr.length) || 0
  );
};
export const formatFactor = (value: string | number | null) => {
  if (typeof value === 'number') {
    return value.toFixed(2);
  }

  if (value === null) {
    return 'N/A';
  }

  return value;
};
