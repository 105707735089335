import {
  IScoringTemplatesSubRoot,
  ScoringTemplatesContextType,
} from '@discngine/moosa-store/scoringTemplates';
import { createContext, useContext } from 'react';

export const ScoringTemplatesContext =
  createContext<ScoringTemplatesContextType<IScoringTemplatesSubRoot> | null>(null);

export function useScoringTemplatesContext() {
  const context = useContext(ScoringTemplatesContext);

  if (!context) {
    throw new Error('ScoringTemplatesContext is not defined');
  }

  return context;
}
