import { Graph } from '../uiModels/moosa-decision-tree/decisionTreeGraph';

export type DecisionTreeId = string;

/**
 * Represents a decision tree data stored in a database.
 * TODO: rename IServerDecisionTree
 */
export interface IDecisionTree {
  _id: DecisionTreeId;
  name: string;
  data: Graph;
  versionName?: string;
  description?: string;
  parentId?: string; // undefined for an initial version of a decision tree, for all others versions is equal to id of initial version
}

export type IDecisionTreeNew = Omit<IDecisionTree, '_id'> & { _id?: string };

export const EMPTY_DECISION_TREE: Omit<IDecisionTree, '_id'> = {
  name: '',
  data: {
    layout: 'Auto',
    nodes: [],
    arrows: [],
  },
};
