import { IColumnLabelMap, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { createContext, FC, useContext, useMemo } from 'react';

import { TagId, TagRenderer } from '../../../DraggableTags';

import { MoosaSarConfigTagPure } from './MoosaSarConfigTagPure';

interface IMoosaSarConfigTagContext {
  columnLabelMap?: IColumnLabelMap;
  tagsMap: Record<TagId, IMoosaSarConfigTag>;
  onTagChange: (tag: IMoosaSarConfigTag) => void;
}

const MoosaSarConfigTagContext = createContext<IMoosaSarConfigTagContext | null>(null);

const useMoosaSarConfigTagContext = (): IMoosaSarConfigTagContext => {
  const context = useContext(MoosaSarConfigTagContext);

  if (!context) {
    throw new Error('MoosaSarConfigTagContextProvider is not defined');
  }

  return context;
};

export const MoosaSarConfigTagContextProvider: FC<{
  columnLabelMap?: IColumnLabelMap;
  tagsMap: Record<TagId, IMoosaSarConfigTag>;
  onTagChange: (tag: IMoosaSarConfigTag) => void;
}> = ({ tagsMap, onTagChange, columnLabelMap, children }) => {
  const value = useMemo(() => {
    const context: IMoosaSarConfigTagContext = {
      tagsMap,
      onTagChange,
      columnLabelMap,
    };

    return context;
  }, [tagsMap, onTagChange, columnLabelMap]);

  return (
    <MoosaSarConfigTagContext.Provider value={value}>
      {children}
    </MoosaSarConfigTagContext.Provider>
  );
};

export const MoosaSarConfigTagRenderer: TagRenderer = ({
  className,
  tagId,
  onRemove,
}) => {
  const { tagsMap, onTagChange, columnLabelMap } = useMoosaSarConfigTagContext();

  const tag = tagsMap[tagId];

  if (!tag) {
    console.warn(`Tag not found: ${tagId}`);

    return null;
  }

  return (
    <MoosaSarConfigTagPure
      className={className}
      columnLabelMap={columnLabelMap}
      tag={tag}
      onChange={onTagChange}
      onTagDelete={onRemove}
    />
  );
};
