import {
  BooleanFlag,
  JobQueryInputFormat,
  RGroupDecompositionDataService,
} from '@discngine/moosa-cheminformatics-data';
import { DatasetRowId, FieldType, JobStatus, Molfile } from '@discngine/moosa-models';
import { selectTableColumnTypes } from '@discngine/moosa-store/tableConfig';
import { ITableDataRow, selectRawDataset } from '@discngine/moosa-store/tableData';
import { getErr } from 'src/utils/helpers';

import { AppThunk } from '../store';

import {
  selectLibrary,
  selectScaffoldData,
  selectRequestConfig,
  selectOnlyMatchAtRGroups,
  selectRemoveAllHydrogenRGroups,
} from './sarMatrix.selector';
import { setJobStatus, setRGroupResult } from './sarMatrix.slice';

export const getRGroupCalculationJobResults = (
  datasource: RGroupDecompositionDataService,
  jobId: string,
  maxAttempts: number,
  intervalBetweenAttempts: number = 1000
): AppThunk => {
  return async function getRGroupCalculationJobResults(dispatch) {
    const { signal } = new AbortController();

    const results = await datasource.pollJob(
      {
        job_id: jobId,
      },
      maxAttempts,
      intervalBetweenAttempts,
      signal
    );

    if (!results) {
      throw new Error('Empty results');
    }

    if (results.cores.length === 0) {
      return dispatch(
        setJobStatus({
          id: jobId,
          status: JobStatus.Finished,
          info: 'Empty cores',
        })
      );
    }

    if (Object.keys(results.substituents).length === 0) {
      throw new Error('Empty substituents');
    }

    if (results.unmatchedMolIndices.length > 0) {
      dispatch(
        setJobStatus({
          id: jobId,
          status: JobStatus.Finished,
          info: 'Unmatched MOL indices found',
        })
      );
    } else {
      dispatch(
        setJobStatus({
          id: jobId,
          status: JobStatus.Finished,
        })
      );
    }

    dispatch(setRGroupResult(results));
  };
};

export const addRGroupCalculationJob = (
  datasource: RGroupDecompositionDataService
): AppThunk => {
  return async function addRGroupCalculationJob(dispatch, getState) {
    dispatch(
      setJobStatus({
        id: '',
        status: JobStatus.Started,
      })
    );
    const state = getState();
    const dataset: Record<DatasetRowId, ITableDataRow> = selectRawDataset(state);
    const library: string = selectLibrary(state);
    const scaffold_data: Molfile[] = selectScaffoldData(state);
    const only_match_at_rgroups: BooleanFlag = selectOnlyMatchAtRGroups(state);
    const remove_all_hydrogen_rgroups: BooleanFlag =
      selectRemoveAllHydrogenRGroups(state);

    if (!dataset || !scaffold_data.length) {
      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: 'Scaffold data or dataset is empty',
        })
      );

      return;
    }

    const columnTypes = selectTableColumnTypes(state);
    const columnKeys = Object.keys(columnTypes);
    const structureColumnName =
      columnKeys.find((key) => columnTypes[key] === FieldType.Structure) ?? '';

    if (!structureColumnName) {
      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: 'Structure column name is empty',
        })
      );

      return;
    }

    const data = Object.values(dataset).map((record) => [
      record.key,
      record[structureColumnName],
    ]);

    try {
      const requestParamsString = selectRequestConfig(state);
      const requestParams = JSON.parse(requestParamsString);
      const response = await datasource.createJob(
        {
          data,
          library,
          scaffold_data,
          only_match_at_rgroups,
          remove_all_hydrogen_rgroups,
          scaffold_type: JobQueryInputFormat.MOLFile,
        },
        requestParams
      );

      dispatch(
        setJobStatus({
          id: response.job_id,
          status: JobStatus.Started,
        })
      );
      await dispatch(
        getRGroupCalculationJobResults(datasource, response.job_id, 300, 3000)
      );
    } catch (err) {
      console.error(err);
      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: getErr(err),
        })
      );
    }
  };
};

export const deleteRGroupJob = (
  datasource: RGroupDecompositionDataService,
  jobID: string
): AppThunk => {
  return async function deleteRGroupJob(dispatch) {
    try {
      await datasource.deleteJob(jobID);

      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Deleted,
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        setJobStatus({
          id: '',
          status: JobStatus.Failed,
          info: getErr(err),
        })
      );
    }
  };
};
