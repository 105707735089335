import { selectSiderWidth } from '@discngine/moosa-store/tableInfo';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// A non-visual component for mapping state to CSS vars

export const CssVariablesMapper: React.FC = React.memo(() => {
  const siderWidth = useSelector(selectSiderWidth);

  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty('--dataset-sider-width', `${siderWidth}px`);
  }, [siderWidth]);

  return <></>;
});
