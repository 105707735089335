import { InboxOutlined } from '@ant-design/icons';
import { notification, Progress, Upload } from 'antd';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import React, { useCallback, useState } from 'react';

import styles from './DatasetUpload.module.less';

const { Dragger } = Upload;

interface Props {
  onUploadSuccess: Function;
  onUploadError: Function;
}

const DatasetUpload: React.FC<Props> = ({ onUploadSuccess, onUploadError }) => {
  const [uploadProgressPercents, setUploadProgressPercents] = useState<number>(0);

  const onChange = useCallback(
    (info) => {
      const {
        status,
        response,
        percent,
      }: { status: UploadFileStatus; response: any; percent: number } = info.file;

      if (status !== 'uploading') {
        console.info(info.file, info.fileList);
        setUploadProgressPercents(0);
      }

      if (status === 'uploading') {
        setUploadProgressPercents(percent);
      }

      if (status === 'done') {
        notification.success({
          message: `${info.file.name} file uploaded successfully.`,
        });
        const id = info.file.response._id;

        onUploadSuccess(id);
      } else if (status === 'error') {
        const detailedDescription = response?.message ? response.message : '';

        notification.error({
          message: `${info.file.name} file upload failed. ${detailedDescription}.`,
        });
        onUploadError({
          errorMessage: 'error',
          fileName: info.file.name,
        });
      }
    },
    [onUploadSuccess, onUploadError]
  );

  const isUploadingNotAvailable = Boolean(uploadProgressPercents);

  return (
    <Dragger
      accept=".csv,text/csv"
      action="/api/datasets"
      className={styles.dragger}
      disabled={isUploadingNotAvailable}
      multiple={false}
      name="file"
      showUploadList={false}
      onChange={onChange}
    >
      <div className={styles.wrapper}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined className={styles.icon} />
        </p>
        <div>
          <p className="ant-upload-text">
            <span className={styles.textHighlighting}>Choose file</span> or Drop file here
          </p>
        </div>
      </div>
      {Boolean(uploadProgressPercents) && (
        <Progress
          className={styles.draggerProgress}
          percent={uploadProgressPercents}
          showInfo={false}
          size="small"
        />
      )}
    </Dragger>
  );
};

export default DatasetUpload;
