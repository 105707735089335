import { Modal } from 'antd';
import React, { FC } from 'react';

import { useModal } from '../../SarMatrixContext';

import { CoreList, ICoreListProps } from './CoreList';
import styles from './SarMatrixCoreSelectionModal.module.less';

export type ICoreSelectionModalProps = {
  currentCore: string;
} & ICoreListProps;

export const SarMatrixCoreSelectionModal: FC<ICoreSelectionModalProps> = ({
  cores,
  currentCore,
  setCore,
}: ICoreSelectionModalProps) => {
  const { toggle, isOpen } = useModal();

  return (
    <Modal
      footer={null}
      open={isOpen}
      title={<h1 className={styles.headerText}>Choose core</h1>}
      width={652}
      onCancel={() => toggle()}
    >
      <CoreList cores={cores} currentCore={currentCore} setCore={setCore} />
    </Modal>
  );
};
