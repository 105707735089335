import { ICalculationJobResponseBody } from '../JobApiDataService/ICalculationJobResponseBody';
import { JobApiDataService } from '../JobApiDataService/JobApiData.service';

import { IMMPCalculationJobPayload } from './IMMPCalculationJobPayload';
import { IMMPCalculationResultResponse } from './IMMPCalculationResultResponse';

export class MMPDataService extends JobApiDataService<
  IMMPCalculationJobPayload,
  IMMPCalculationResultResponse
> {
  constructor(host: string, apiVersion: string) {
    super(host, apiVersion, 'mmp');
    this.baseUrl = `${this.host}/api/${this.apiVersion}/async/${this.entityName}/`;
  }

  createMMPJob(
    payload: IMMPCalculationJobPayload,
    params: Record<string, unknown>
  ): Promise<ICalculationJobResponseBody> {
    return this.createJob(payload, params, 'get_pairs/');
  }
}
