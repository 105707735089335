import { Checkbox, Divider } from 'antd';
import React, { FC } from 'react';

import styles from './MoosaSarMatrixRequest.module.less';
import {
  ISarMatrixJobControlPanelProps,
  SarMatrixJobControlPanel,
} from './SarMatrixJobControlPanel/SarMatrixJobControlPanel';
import {
  ISarMatrixRequestScaffoldsProps,
  SarMatrixRequestScaffolds,
} from './SarMatrixRequestCore/SarMatrixRequestScaffolds';

export interface IMoosaSarMatrixRequestFormProps
  extends ISarMatrixJobControlPanelProps,
    ISarMatrixRequestScaffoldsProps {
  onlyMatchAtRGroups: boolean;
  removeAllHydrogenRGroups: boolean;
  onOnlyMatchAtRGroups: (onlyMatchAtRGroups: boolean) => void;
  onRemoveAllHydrogenRGroups: (removeAllHydrogenRGroups: boolean) => void;
}

export const MoosaSarMatrixRequestForm: FC<IMoosaSarMatrixRequestFormProps> = ({
  scaffoldData,
  jobStatus,
  calculateBtnDisabled,
  onlyMatchAtRGroups,
  removeAllHydrogenRGroups,
  StructureEditor,
  structureRenderer,
  onStartJob,
  onStopJob,
  onSetScaffoldData,
  onOnlyMatchAtRGroups,
  onRemoveAllHydrogenRGroups,
  failedMoleculesQty,
  unmatchedIndicesQty,
}) => {
  return (
    <div className={styles.root}>
      <SarMatrixRequestScaffolds
        scaffoldData={scaffoldData}
        StructureEditor={StructureEditor}
        structureRenderer={structureRenderer}
        onSetScaffoldData={onSetScaffoldData}
      />
      <h3>Options</h3>
      <div className={styles.checkboxWrapper}>
        <div>
          <Checkbox
            checked={onlyMatchAtRGroups}
            onChange={(event) => {
              onOnlyMatchAtRGroups(event.target.checked);
            }}
          >
            Only match at RGroups
          </Checkbox>
        </div>
        <div>
          <Checkbox
            checked={removeAllHydrogenRGroups}
            onChange={(event) => {
              onRemoveAllHydrogenRGroups(event.target.checked);
            }}
          >
            Remove all hydrogen group
          </Checkbox>
        </div>
      </div>
      <Divider />

      <Divider />
      <SarMatrixJobControlPanel
        calculateBtnDisabled={calculateBtnDisabled}
        failedMoleculesQty={failedMoleculesQty}
        jobStatus={jobStatus}
        unmatchedIndicesQty={unmatchedIndicesQty}
        onStartJob={onStartJob}
        onStopJob={onStopJob}
      />
    </div>
  );
};
