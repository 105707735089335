import { classNames } from '@discngine/moosa-common';
import React from 'react';

import { useSarMatrix } from '../SarMatrixContext';

import styles from './SarMatrixTable.module.less';

export interface ISarMatrixGridCellProps {
  rowIndex: number;
  columnIndex: number;
  style: any;
}

export const SarMatrixGridCell = (props: ISarMatrixGridCellProps) => {
  const { style, rowIndex, columnIndex } = props;

  const { cellRenderer: CellRenderer } = useSarMatrix();

  return (
    <div
      className={classNames(styles.gridCell, {
        [styles.firstRow]: rowIndex === 0,
        [styles.firstCol]: columnIndex === 0,
      })}
      style={style}
    >
      <CellRenderer
        key={`${rowIndex}-${columnIndex}`}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
      />
    </div>
  );
};
