import { ICoreSelectorProps } from './CoreSelector/CoreSelector';
import { IJopControlPanelProps } from './JobControlPanel/JobControlPanel';
import { IRequestConfigEditorProps } from './RequestConfigEditor/RequestConfigEditor';

export enum Sorting {
  ASC,
  DESC,
}

export enum GlobalSorting {
  INDEPENDENT,
  PARAMETER,
}

export enum TableSorting {
  ASC = 'ascend',
  DESC = 'descend',
}

export enum TableBarStyle {
  VERTICAL,
  VERTICAL_SEPARATE,
  VERTICAL_AXIS,
  HORIZONTAL_AXIS,
}

export enum TableValueStyle {
  ABSOLUTE,
  DELTA,
}

export interface ITableColumnStates {
  sorting: {
    parameterId: string;
    sortOrder: TableSorting | null;
  };
  invert: Record<string, boolean>;
}

export interface IGlobalSorting {
  type: GlobalSorting;
  mainParameter: string;
  order: string[];
}

export interface Core {
  id: string;
  cid: string;
  SMILES: string;
  index: number;
}

export interface IMMPChartConfig
  extends IRequestConfigEditorProps,
    ICoreSelectorProps,
    IJopControlPanelProps {}
