import Icon from '@ant-design/icons';
import { classNames } from '@discngine/moosa-common';
import { IColumnLabelMap, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { useCallback, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import styles from '../MultiplePropertiesSelector.module.less';
import { ReactComponent as dndIcon } from '../resources/drag-n-drop.svg';

import { MoosaSarConfigTag } from './MoosaSarConfigTag/MoosaSarConfigTag';

interface IMoosaSarConfigTagsRowProps {
  rowIndex: number;
  tagsRow: IMoosaSarConfigTag[];
  onRowChange: (rowId: number, tags: IMoosaSarConfigTag[]) => void;
  draggingItem: { row: number; index: number };
  maxTagsInRow: number;
  columnLabelMap?: IColumnLabelMap;
  isSimpleColorSelector?: boolean;
}

export const MoosaSarConfigTagsRow: React.FC<IMoosaSarConfigTagsRowProps> = (props) => {
  const handleChange = useCallback(
    (newTag: IMoosaSarConfigTag) => {
      const newTags = [...props.tagsRow];
      const index = newTags.findIndex((tag) => tag.columnId === newTag.columnId);

      newTags[index] = newTag;

      props.onRowChange(props.rowIndex, newTags);
    },
    [props]
  );

  const handleTagDelete = useCallback(
    (columnId: string) => {
      const newTags = props.tagsRow.filter((tag) => tag.columnId !== columnId);

      props.onRowChange(props.rowIndex, newTags);
    },
    [props]
  );

  const canBeDropped = useMemo(() => {
    return (
      props.tagsRow.length < props.maxTagsInRow ||
      props.rowIndex === props.draggingItem.row ||
      props.draggingItem.row === -1
    );
  }, [props]);

  return (
    <Draggable draggableId={String(props.rowIndex)} index={props.rowIndex}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            className={classNames(styles.tagsRow)}
            {...provided.draggableProps}
          >
            <div className={styles.tagsRowHandle} {...provided.dragHandleProps}>
              <Icon className={styles.calculateIcon} component={dndIcon} />
            </div>
            <Droppable direction={'horizontal'} droppableId={String(props.rowIndex)}>
              {({ innerRef, droppableProps, placeholder }) => {
                return (
                  <div
                    ref={innerRef}
                    className={classNames(
                      styles.tagsRowBody,
                      !canBeDropped && styles.isDropDisabled,
                      styles['perRow' + props.maxTagsInRow]
                    )}
                    data-count={props.tagsRow.length}
                    {...droppableProps}
                  >
                    {props.tagsRow.map((tag, index) => {
                      return (
                        <MoosaSarConfigTag
                          key={tag.columnId}
                          columnLabelMap={props.columnLabelMap}
                          draggingItem={props.draggingItem}
                          hideReordering={!canBeDropped}
                          isSimpleColorSelector={props.isSimpleColorSelector}
                          itemIndex={index}
                          rowIndex={props.rowIndex}
                          tag={tag}
                          onChange={handleChange}
                          onTagDelete={handleTagDelete}
                        />
                      );
                    })}
                    <span
                      style={
                        // https://github.com/atlassian/react-beautiful-dnd/issues/374#issuecomment-569817782
                        canBeDropped ? undefined : { display: 'none' }
                      }
                    >
                      {placeholder}
                    </span>
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      }}
    </Draggable>
  );
};
