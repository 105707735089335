import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React from 'react';
import { v4 as uuid } from 'uuid';

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const showEntityDeleteNotification = (params: {
  entityName: string;
  entity: {
    name: string;
    parentId?: string;
    versionName?: string;
  };
  onRestore: () => void;
}) => {
  const key = uuid();

  notification.info({
    icon: <CheckCircleOutlined style={{ color: '#01579b' }} />,
    message: (
      <div>
        {params.entity.parentId ? (
          <span>
            Version <b>{params.entity.versionName}</b> of <b>{params.entity.name}</b>{' '}
            {params.entityName} is deleted.
          </span>
        ) : (
          <span>
            {capitalizeFirstLetter(params.entityName)} <b>{params.entity.name}</b> is
            deleted.
          </span>
        )}
        <br />
        You can restore it.
      </div>
    ),
    btn: (
      <Button
        size="small"
        type="primary"
        onClick={() => {
          params.onRestore();
          notification.destroy(key);
        }}
      >
        Restore template
      </Button>
    ),
    key,
    duration: 10, // 10 seconds - business requirement
  });
};
