import { IMoosaSarConfigTag } from '@discngine/moosa-models';
import {
  buildTag,
  MultiplePropertiesSelectorPure,
} from '@discngine/moosa-shared-components';
import { Radio, Divider } from 'antd';
import React, { useCallback } from 'react';

import { MoosaSarConfigImageSize } from './ImageSize/MoosaSarConfigImageSize';
import { MoosaSarConfigCore } from './MoosaSarConfigCore/MoosaSarConfigCore';
import { MoosaSarConfigImportExport } from './MoosaSarConfigImportExport';
import styles from './MoosaSarTableConfig.module.less';
import { MoosaSarTableConfigProps } from './MoosaSarTableConfigProps';

export const MoosaSarTableConfig: React.FC<MoosaSarTableConfigProps> = ({
  coreStructures,
  StructureEditor,
  structureRenderer,
  onStructureChange,
  availableColumns,
  columns,
  tableColumnsMap,
  templateColumnsIds,
  onColumnsChange,
  isColorizingTextMode,
  toggleColorizingTableMode,
  imageSize,
  onImageSizeChange,
  setSettings,
  showConfigImportExport = false,
  columnLabelMap,
}) => {
  const tagBuilder = useCallback(
    (columnId: string): IMoosaSarConfigTag => {
      return buildTag(columnId, tableColumnsMap, templateColumnsIds);
    },
    [tableColumnsMap, templateColumnsIds]
  );

  return (
    <div className={styles.container}>
      <MoosaSarConfigCore
        structure={coreStructures}
        StructureEditor={StructureEditor}
        structureRenderer={structureRenderer}
        onStructureChange={onStructureChange}
      />

      <MultiplePropertiesSelectorPure
        availableColumns={availableColumns}
        columnLabelMap={columnLabelMap}
        columns={columns}
        tagBuilder={tagBuilder}
        onColumnsChange={onColumnsChange}
      />
      <h3 className={styles.settingTitle}>Colorizing</h3>
      <div>
        <Radio.Group value={isColorizingTextMode} onChange={toggleColorizingTableMode}>
          <Radio value={true}>Text</Radio>
          <Radio value={false}>Background</Radio>
        </Radio.Group>
      </div>

      <h3 className={styles.settingTitle}>Image size</h3>
      <MoosaSarConfigImageSize currentSize={imageSize} onChange={onImageSizeChange} />

      {showConfigImportExport && (
        <>
          <Divider />
          <MoosaSarConfigImportExport
            columns={columns}
            imageSize={imageSize}
            isColorizingTextMode={isColorizingTextMode}
            setSettings={setSettings}
            structure={coreStructures}
          />
        </>
      )}
    </div>
  );
};
