// adapted from https://github.com/bgrins/TinyColor
import { IGradientColor } from '@discngine/moosa-models';
import isEqual from 'lodash/isEqual';
import tinyColor from 'tinycolor2';

import { DEFAULT_COLORS } from '../constants';

// TODO replace everything here with tinyColor2

export interface IRGBColor {
  r: number; // [0, 255]
  g: number; // [0, 255]
  b: number; // [0, 255]
}

export interface IHSVColor {
  hue: number; // [0, 360]
  sat: number; // [0, 1]
  val: number; // [0, 1]
}

export interface IColorPalette {
  startAngle: number;
  endAngle: number;
}

// Converts an HSV color value to RGB.
// hue [0, 360] and sat and val are in [0, 1]
// Returns { r, g, b } in [0, 255]
export function hsvToRgb(hue: number, sat: number, val: number): IRGBColor {
  const _h = (6 * hue) / 360,
    _i = Math.floor(_h),
    _f = _h - _i,
    _p = val * (1 - sat),
    _q = val * (1 - _f * sat),
    _t = val * (1 - (1 - _f) * sat),
    mod = _i % 6,
    _r = [val, _q, _p, _p, _t, val][mod],
    _g = [_t, val, val, _q, _p, _p][mod],
    _b = [_p, _p, _t, val, val, _q][mod];

  return { r: Math.round(_r * 255), g: Math.round(_g * 255), b: Math.round(_b * 255) };
}

export function rgbToString(color: IRGBColor): string {
  return `rgb(${color.r},${color.g},${color.b})`;
}

// expect val is in  [0, 1]
export function valueToRGB(val: number, palette: IColorPalette): string {
  const start = palette.startAngle;
  const end =
    palette.endAngle > palette.startAngle ? palette.endAngle : palette.endAngle + 360;
  const angle = start + val * (end - start);
  const simpleAngle = angle > 360 ? angle - 360 : angle;
  const color = hsvToRgb(simpleAngle, 1, 1);

  return rgbToString(color);
}

export const mixTwoColors = (
  firstColor: IRGBColor,
  secondColor: IRGBColor,
  distance: number // distance of the first color from 0 to 1
): IRGBColor => {
  const w2 = distance;
  const w1 = 1 - w2;
  const rgb = {
    r: Math.round(firstColor.r * w1 + secondColor.r * w2),
    g: Math.round(firstColor.g * w1 + secondColor.g * w2),
    b: Math.round(firstColor.b * w1 + secondColor.b * w2),
  };

  return rgb;
};

export const mixColors = (colors: string[]): string => {
  const averagedColor = colors.reduce((previousColor, currentColor, index) => {
    const amount = 100 / (index + 1); // amount of the currentColor

    return tinyColor.mix(previousColor, currentColor, amount).toString('hex');
  });

  return averagedColor;
};

const comparePercentsFunc = (item: IGradientColor, nextItem: IGradientColor) => {
  if (item.percent > nextItem.percent) {
    return 1;
  }

  if (item.percent < nextItem.percent) {
    return -1;
  }

  return 0;
};

// expect val is in  [0, 1]
export const getColorFromGradient = (
  value: number,
  gradient: IGradientColor[]
): IRGBColor => {
  const percentageValue = value * 100;

  const sortedGradient = [...gradient].sort(comparePercentsFunc);

  let colorRange: number[] = [];

  for (let i = 0; i <= sortedGradient.length; i++) {
    const gradientPercent = sortedGradient[i]?.percent ?? 0;

    if (percentageValue <= gradientPercent) {
      colorRange = [i, i];
      break;
    }

    if (percentageValue >= gradientPercent && !sortedGradient[i + 1]) {
      colorRange = [i, i];
      break;
    }

    if (percentageValue < sortedGradient[i + 1]?.percent) {
      colorRange = [i, i + 1];
      break;
    }
  }

  //Get the two closest colors
  const firstItem = sortedGradient[colorRange[0]];
  const secondItem = sortedGradient[colorRange[1]];

  const firstColor = firstItem?.color ?? '';
  const secondColor = secondItem?.color ?? '';

  const firstItemPercent = firstItem?.percent ?? 0;
  const secondItemPercent = secondItem?.percent ?? 0;

  //Calculate distance of the first color from 0 to 1
  const distance =
    colorRange[0] === colorRange[1]
      ? 1
      : (value - firstItemPercent / 100) /
        (secondItemPercent / 100 - firstItemPercent / 100);

  return mixTwoColors(firstColor, secondColor, distance);
};

export const generateGradientString = (
  gradientType: 'linear' | 'radial',
  angle: number,
  gradient: IGradientColor[]
): string => {
  const sortedGradient = [...gradient].sort(comparePercentsFunc);

  const colors = sortedGradient.map((currentColor) => {
    return `rgb(${currentColor.color.r},${currentColor.color.g},${currentColor.color.b}) ${currentColor.percent}%`;
  });

  return `${gradientType}-gradient(${angle}deg, ${colors})`;
};

export const isEqualColor = (color1: IRGBColor, color2: IRGBColor): boolean =>
  isEqual(color1, color2);

export const rgbToHex = ({ r, g, b }: IRGBColor): string => {
  const rgb = (r << 16) | (g << 8) | (b << 0);

  return '#' + (0x1000000 + rgb).toString(16).slice(1);
};

export const hexToRgb = (color: string): IRGBColor => {
  const bigint = parseInt(color.substring(1), 16);
  const redValue = (bigint >> 16) & 255;
  const greenValue = (bigint >> 8) & 255;
  const blueValue = bigint & 255;

  return { r: redValue, g: greenValue, b: blueValue };
};

export function* getColor(): Generator<string> {
  let i = 0;

  while (true) {
    yield DEFAULT_COLORS[i++];

    if (i === DEFAULT_COLORS.length) {
      i = 0;
    }
  }
}

export const colorGenerator = getColor();
