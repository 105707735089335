import { JobApiDataService } from '../JobApiDataService/JobApiData.service';

import { IRGroupDecompositionCalculationJobPayload } from './IRGroupDecompositionCalculationJobPayload';
import { IRGroupDecompositionCalculationResultResponse } from './IRGroupDecompositionCalculationResultResponse';

export class RGroupDecompositionDataService extends JobApiDataService<
  IRGroupDecompositionCalculationJobPayload,
  IRGroupDecompositionCalculationResultResponse
> {
  constructor(host: string, apiVersion: string) {
    super(host, apiVersion, 'r_group_decomposition');
    this.baseUrl = `${this.host}/api/${this.apiVersion}/async/${this.entityName}/`;
  }
}
