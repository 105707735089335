import { classNames } from '@discngine/moosa-common';
import { Molfile } from '@discngine/moosa-models';
import React, { useCallback } from 'react';

import { useSarMatrix } from '../../SarMatrixContext';

import styles from './CoreListItem.module.less';

export type ICoreListItem = {
  structure: string;
  moleculesCount: number;
  selected: boolean;
};

export type ICoreListItemProps = ICoreListItem & {
  setCore: (structure: Molfile) => void;
};

const CoreListItem = ({
  selected,
  structure,
  moleculesCount,
  setCore,
}: ICoreListItemProps) => {
  const onStructureClick = useCallback(() => {
    setCore(structure as Molfile);
  }, [setCore, structure]);

  const { structureRenderer: StructureRenderer } = useSarMatrix();

  return (
    <div
      className={classNames(styles.coreCard, { [styles.coreCard__selected]: selected })}
      onClick={onStructureClick}
    >
      <div className={styles.structure}>
        <StructureRenderer smiles={structure} />
      </div>
      <span className={styles.structureCaption}>{moleculesCount} molecules</span>
    </div>
  );
};

export const MemoCoreListItem = React.memo(CoreListItem);
