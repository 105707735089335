import { StopOutlined } from '@ant-design/icons';
import { IScoringTemplateItem } from '@discngine/moosa-models';
import { selectTableColumnsMap } from '@discngine/moosa-store/tableInfo';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './TemplateDetails.module.less';

interface Props {
  color: string;
  templateItem: IScoringTemplateItem;
}

export const TemplateDetailsColumn: React.FC<Props> = ({ color, templateItem }) => {
  const { columnName, weight } = templateItem;
  const datasetColumns = useSelector(selectTableColumnsMap);

  const columnInDataset = useMemo(
    () => !!datasetColumns[templateItem.columnName],
    [templateItem, datasetColumns]
  );

  return (
    <div key={columnName} className={styles.column}>
      {columnInDataset && (
        <>
          <span className={styles.importance}>{weight}</span>
          <span
            className={styles.color}
            style={{
              backgroundColor: color,
            }}
          />
          {columnName}
        </>
      )}
      {!columnInDataset && (
        <span
          className={styles.columnNotInDataset}
          title="Field does not exist in the dataset"
        >
          <StopOutlined className={styles.columnNotInDatasetIcon} />
          <span className={styles.columnNameNotInDataset}>{columnName}</span>
        </span>
      )}
    </div>
  );
};
