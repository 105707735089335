import { FieldType } from '@discngine/moosa-models';
import { StructureRenderer } from '@discngine/moosa-structure-renderer';
import React, { PropsWithChildren, useMemo } from 'react';

import { useCellRenderer } from '../../SarMatrixContext';

interface Props {
  id: string;
  width?: number;
  height?: number;
}

export const TooltipContent = ({
  id,
  children,
  height = 250,
  width = 250,
}: PropsWithChildren<Props>) => {
  const { tableColumns, coreStructure, rawDataset } = useCellRenderer();

  const structureColumnId = useMemo(() => {
    return tableColumns.find((col) => col.type === FieldType.Structure)?.name ?? null;
  }, [tableColumns]);

  if (!structureColumnId) {
    return null;
  }

  const smile = String(rawDataset[id][structureColumnId]);
  const coreSmiles = [{ core: coreStructure, color: 'transparent' }];
  const alignStructures = [coreStructure];

  return (
    <div style={{ width, height }}>
      <StructureRenderer
        alignSmiles={alignStructures}
        coreSmiles={coreSmiles}
        smiles={smile}
      />

      {children}
    </div>
  );
};
