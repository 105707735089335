import { ITableDataSubRoot } from '@discngine/moosa-models';
import { createSelector } from '@reduxjs/toolkit';

export const selectRawDataset = (state: ITableDataSubRoot) => state.tableData.rawDataset;

export const selectTableData = (state: ITableDataSubRoot) => state.tableData;

export const selectTablePageIds = (state: ITableDataSubRoot) => state.tableData.pageIds;

export const selectTableChunkIsLoading = (state: ITableDataSubRoot) =>
  state.tableData.isChunkLoading;

export const selectTableIsChunkLoading = (state: ITableDataSubRoot) =>
  state.tableData.isChunkLoading;

export const selectTablePage = (state: ITableDataSubRoot) => state.tableData.page;

export const selectTablePageSize = (state: ITableDataSubRoot) => state.tableData.pageSize;

export const selectTableSort = (state: ITableDataSubRoot) => state.tableData.sort;

export const selectedTableRows = (state: ITableDataSubRoot) =>
  state.tableData.selectedRows;

export const selectTableSelectedRowKeys = createSelector(
  selectedTableRows,
  (selectedRows) => Object.keys(selectedRows)
);

export const selectIsCompareMode = (state: ITableDataSubRoot) =>
  state.tableData.compareMode;

export const selectShowErrors = (state: ITableDataSubRoot) => state.tableData.showErrors;

export const selectHighlightedRows = (state: ITableDataSubRoot) =>
  state.tableData.highlightedRows;
