import { FC } from 'react';

import styles from './DataSubCell.module.less';

interface Props {
  children: React.ReactChild;
  compact: boolean;
  showErrors: boolean;
  showVariance: boolean;
  errorPositive: React.ReactChild;
  errorNegative: React.ReactChild;
  variance: React.ReactChild;
}

export const DataSubCell: FC<Props> = ({
  compact,
  showErrors,
  showVariance,
  errorNegative,
  errorPositive,
  variance,
  children,
}) => {
  if (compact) {
    return (
      <div className={styles.dataSubCellCompact}>
        {children}
        <div className={styles.dataCompact}>
          {showErrors && errorPositive}
          {showVariance && variance}
          {showErrors && errorNegative}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.dataSubCell}>
      {showErrors && errorPositive}
      <div className={styles.scoreWithVariance}>
        {children}
        {showVariance && variance}
      </div>
      {showErrors && errorNegative}
    </div>
  );
};
