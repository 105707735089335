import React, { useMemo } from 'react';

import { useCellRenderer } from '../../SarMatrixContext';
import { SarMatrixCellRendererProps } from '../../SarMatrixTable/types';
import { TableCellPopover } from '../TableCellPopover/TableCellPopover';
import { TooltipContent } from '../TableCellPopover/TooltipContent';
import { useStructurePopover } from '../TableCellPopover/useStructurePopover';
import { CellFactor, IFactorPie } from '../types';

import { PieChart } from './PieChart';
import { pieChartBuilder } from './pieChartBuilder';

export const PieCell = (props: SarMatrixCellRendererProps) => {
  const {
    getSubstances,
    scoringOrder,
    columnIds,
    scoringFunction,
    scoredDataset,
    rawDataset,
    structureColumnName,
    columnIndices,
  } = useCellRenderer();

  const cellPieFactors: CellFactor<IFactorPie>[] = useMemo(() => {
    const sortedOrder = scoringOrder
      .filter((col) => columnIds.includes(col))
      .filter((col) => scoringFunction[col].isInUse)
      .map((col) => columnIndices[col]);

    return getSubstances(props).map(({ substanceId }) => {
      const score = scoredDataset[substanceId];

      if (!score?.factors) {
        return {
          substanceId,
          charts: pieChartBuilder(columnIds, sortedOrder, score, scoringFunction),
          smiles: String(rawDataset[substanceId]?.[structureColumnName] || ''),
        };
      }

      return {
        substanceId,
        smiles: String(rawDataset[substanceId]?.[structureColumnName] || ''),
        charts: pieChartBuilder(columnIds, sortedOrder, score, scoringFunction),
      };
    });
  }, [
    columnIds,
    columnIndices,
    getSubstances,
    props,
    rawDataset,
    scoredDataset,
    scoringFunction,
    scoringOrder,
    structureColumnName,
  ]);

  const length = Math.ceil(Math.sqrt(cellPieFactors.length));
  const { hoveredId, onMouseOver, onMouseLeave } = useStructurePopover();

  return (
    <TableCellPopover hoveredSubstanceId={hoveredId} popoverContent={TooltipContent}>
      <div
        style={{
          display: 'grid',
          gridTemplateRows: `1fr `.repeat(length),
          gridTemplateColumns: `1fr `.repeat(length),
          width: '100%',
          height: '100%',
        }}
        onMouseLeave={onMouseLeave}
      >
        {cellPieFactors.map((cellFactor) => {
          return (
            <PieChart
              key={cellFactor.substanceId}
              data={cellFactor}
              onMouseOver={onMouseOver}
            />
          );
        })}
      </div>
    </TableCellPopover>
  );
};
