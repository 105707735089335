import Icon from '@ant-design/icons';
import { classNames, IRGBColor } from '@discngine/moosa-common';
import { IColumnLabelMap, IGradient, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { Popover, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { FC, useCallback, useState } from 'react';

import { ReactComponent as ColorPallet } from '../../resources/ColorPallet.svg';
import { ReactComponent as WithoutColor } from '../../resources/WithoutColor.svg';
import { ColorizingPanel } from '../ColorizingPanel/ColorizingPanel';

import styles from './MoosaSarTagColorSelector.module.less';

export interface MoosaSarTagColorSelectorProps {
  className?: string;
  onChange: (tag: IMoosaSarConfigTag) => void;
  tag: IMoosaSarConfigTag;
  columnLabelMap?: IColumnLabelMap;
}

export const MoosaSarTagColorSelector: FC<MoosaSarTagColorSelectorProps> = ({
  className,
  onChange,
  tag,
  columnLabelMap,
}) => {
  const [isColorizingPanelVisible, setIsColorizingPanelVisible] = useState(false);
  const [isColorizingTooltipVisible, setIsColorizingTooltipVisible] = useState(false);
  const isWithoutColor =
    !tag.isColored || (!tag.isScoreAvailable && !tag.isDatasetAvailable);

  const handleColorizingPanelVisibleChange = useCallback(
    (newVisible: boolean) => {
      if (!tag.isScoreAvailable && !tag.isDatasetAvailable) {
        return;
      }
      setIsColorizingPanelVisible(newVisible);

      if (newVisible) {
        onChange({ ...tag, isColored: true });
      }
    },
    [onChange, tag]
  );

  const onColorizingModeChange = useCallback(
    (isScoredColorizingMode: boolean) => {
      onChange({ ...tag, isScoredColorizingMode });
    },
    [onChange, tag]
  );

  const onToggleColorizing = useCallback(
    (isColored: boolean) => {
      onChange({ ...tag, isColored });
    },
    [onChange, tag]
  );

  const onDiscreteValueColorChange = useCallback(
    (indexOfSelectedColor: number, color: IRGBColor | null) => {
      const newDiscretePropertyValues = cloneDeep(tag.discretePropertyValues);

      newDiscretePropertyValues[indexOfSelectedColor].color = color;

      onChange({ ...tag, discretePropertyValues: newDiscretePropertyValues });
    },
    [onChange, tag]
  );

  const onGradientChange = useCallback(
    (gradient: IGradient) => {
      onChange({ ...tag, gradient });
    },
    [onChange, tag]
  );

  const onColorizingTooltipVisibleChange = useCallback(
    (newVisible) => {
      if (!tag.isScoreAvailable && !tag.isDatasetAvailable) {
        setIsColorizingTooltipVisible(newVisible);
      }
    },
    [tag.isDatasetAvailable, tag.isScoreAvailable]
  );

  const onSwapColors = useCallback(() => {
    const copyTag = structuredClone(tag);
    const firstColor = copyTag.gradient.datasetGradient[0].color;
    const gradLength = copyTag.gradient.datasetGradient.length;
    const lastColor = copyTag.gradient.datasetGradient[gradLength - 1].color;

    copyTag.gradient.datasetGradient[0].color = lastColor;
    copyTag.gradient.datasetGradient[gradLength - 1].color = firstColor;

    onChange({ ...copyTag });
  }, [onChange, tag]);

  return (
    <Popover
      content={
        <ColorizingPanel
          columnLabelMap={columnLabelMap}
          discretePropertyValues={tag.discretePropertyValues}
          handleColorizingPanelVisibleChange={handleColorizingPanelVisibleChange}
          tag={tag}
          onColorizingModeChange={onColorizingModeChange}
          onDiscreteValueColorChange={onDiscreteValueColorChange}
          onGradientChange={onGradientChange}
          onSwapClick={onSwapColors}
          onToggleColorizing={onToggleColorizing}
        />
      }
      open={isColorizingPanelVisible}
      placement="bottom"
      trigger="click"
      zIndex={10000}
      onOpenChange={handleColorizingPanelVisibleChange}
    >
      <Tooltip
        mouseEnterDelay={0.4}
        mouseLeaveDelay={0}
        open={isColorizingTooltipVisible}
        style={{ pointerEvents: 'none' }}
        title={
          'This property is absent in the scoring template and has only one value in the dataset'
        }
        trigger={'click'}
        onOpenChange={onColorizingTooltipVisibleChange}
      >
        <Icon
          className={classNames(
            className,
            styles.colorPalletIcon,
            isWithoutColor && styles.withoutColor
          )}
          component={isWithoutColor ? WithoutColor : ColorPallet}
        />
      </Tooltip>
    </Popover>
  );
};
