import {
  IDatasetRowWithScore,
  ITableDataRow,
  ITableDataRowMap,
} from '@discngine/moosa-models';

export function datasetRowsToInternal(
  columns: string[],
  offset: number,
  data: IDatasetRowWithScore[]
): ITableDataRowMap {
  const map: ITableDataRowMap = {};

  let i = 1;

  data.forEach(({ row, id }) => {
    const item: ITableDataRow = {
      'system.rank': offset + i++,
      key: id,
    };

    columns.forEach((col, colIndex) => {
      item[col] = row[colIndex];
    });

    map[id] = item;
  });

  return map;
}
