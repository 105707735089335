import React from 'react';

import { CellViewMode, ICellViewModeProps } from './components/CellViewMode';
import { Columns, IColumnsProps } from './components/Columns';
import { Header, IHeaderProps } from './components/Header';
import { IRowSizeSelectorProps, RowSizeSelector } from './components/SizeSelector';
import styles from './TablePropertiesPanel.module.less';

export interface ITablePropertiesPanelProps
  extends IHeaderProps,
    ICellViewModeProps,
    IRowSizeSelectorProps,
    IColumnsProps {}
export const TablePropertiesPanel: React.FC<ITablePropertiesPanelProps> = ({
  willSynchronizeColumnsWithTemplate,
  onChangeTemplateColumns,
  columns,
  backgroundMode,
  onChangeBackgroundMode,
  rowSize,
  onChangeRowSize,
}) => {
  return (
    <div className={styles.container}>
      <Header
        willSynchronizeColumnsWithTemplate={willSynchronizeColumnsWithTemplate}
        onChangeTemplateColumns={onChangeTemplateColumns}
      />

      <hr className={styles.hr} />

      <Columns columns={columns} />

      <hr className={styles.hr} />

      <p className={styles.titleVisualization}>Table Visualization</p>
      <CellViewMode
        backgroundMode={backgroundMode}
        onChangeBackgroundMode={onChangeBackgroundMode}
      />

      <p className={styles.titleSize}>Row Height</p>
      <RowSizeSelector rowSize={rowSize} onChangeRowSize={onChangeRowSize} />
    </div>
  );
};
