import React, { FC } from 'react';

import styles from './DatasetDetails.module.less';

type DatasetDetailsProps = {
  columnsCount: number;
  rowsCount: number;
  missingValuesCount: number;
};

export const DatasetDetails: FC<DatasetDetailsProps> = React.memo(
  function DatasetDetails({ columnsCount, rowsCount, missingValuesCount }) {
    return (
      <div className={styles.details}>
        {`${columnsCount} Columns / ${rowsCount} Rows /`}
        <span> {`${missingValuesCount} Missing Values`} </span>
      </div>
    );
  }
);
