import { IMoosaDataService } from '@discngine/moosa-models';
import {
  initFromTableMetadata,
  selectScoringTemplate,
} from '@discngine/moosa-store/scoringTemplate';
import { fetchDataWithScores } from '@discngine/moosa-store/tableData';
import {
  ComputedColumnsChanges,
  initTableInfo,
  toggleTableInfoLoading,
} from '@discngine/moosa-store/tableInfo';
import { batch } from 'react-redux';

import { AppThunk } from './store';

export function fetchTableInfo(
  service: {
    getDatasetMetadata: IMoosaDataService['getDatasetMetadata'];
    getDataWithScore: IMoosaDataService['getDataWithScore'];
  },
  tableId: string,
  computedColumnsChanges?: ComputedColumnsChanges
): AppThunk {
  return async function fetchTableInfoThunk(dispatch, getState) {
    dispatch(toggleTableInfoLoading(true));
    try {
      const metadata = await service.getDatasetMetadata(tableId);

      /**
       * Note that initTableInfo triggers reset state in all reducers
       * it affects: tableInfo,  tableData, tableConfig, scoringTemplate and scores
       *
       */

      batch(() => {
        dispatch(initTableInfo({ data: metadata, computedColumnsChanges }));
        dispatch(initFromTableMetadata(metadata));
      });

      const template = selectScoringTemplate(getState());

      await dispatch(fetchDataWithScores(template, service.getDataWithScore));
    } finally {
      dispatch(toggleTableInfoLoading(false));
    }
  };
}
