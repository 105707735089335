import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface State {
  isOpen: boolean;
  toggle: (isOpen?: boolean) => void;
}

const ModalWindowContext = createContext<State | null>(null);

export const ModalWindowProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback((newValue?: boolean) => {
    setIsOpen((x) => newValue ?? !x);
  }, []);

  const value = useMemo(() => ({ isOpen, toggle }), [isOpen, toggle]);

  return (
    <ModalWindowContext.Provider value={value}>{children}</ModalWindowContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalWindowContext);

  if (context === null) {
    throw new Error('modal context cannot be used outside context');
  }

  return context;
};
