import { IColumnLabelMap, IMoosaSarConfigTag } from '@discngine/moosa-models';
import { ColumnId } from '@discngine/moosa-models';
import { Select } from 'antd';
import { useCallback, useMemo, useState } from 'react';

import { DraggableTags, TagId, TagsPanel } from '../DraggableTags';

import { MoosaSarConfigTagPure } from './MoosaSarConfigTagsRow/MoosaSarConfigTag/MoosaSarConfigTagPure';
import {
  MoosaSarConfigTagContextProvider,
  MoosaSarConfigTagRenderer,
} from './MoosaSarConfigTagsRow/MoosaSarConfigTag/MoosaSarConfigTagRenderer';
import styles from './MultiplePropertiesSelectorPure.module.less';
import { addTagToPanel } from './utils/addTag';

interface IMultiplePropertiesSelectorProps {
  title?: string;
  availableColumns: ColumnId[];
  tagBuilder: (columnId: ColumnId) => IMoosaSarConfigTag;
  columns: IMoosaSarConfigTag[][];
  onColumnsChange: (columns: IMoosaSarConfigTag[][]) => void;
  maxTagsInRow?: number;
  columnLabelMap?: IColumnLabelMap;
  allowOneProperty?: boolean;
}

export const MultiplePropertiesSelectorPure: React.FC<
  IMultiplePropertiesSelectorProps
> = ({
  title,
  availableColumns,
  columns,
  columnLabelMap,
  onColumnsChange,
  maxTagsInRow = 3,
  tagBuilder,
  allowOneProperty,
}) => {
  const [inputValue, setInputValue] = useState<string | null>(null);

  const tagsMap = useMemo(() => {
    return columns.flat().reduce<Record<TagId, IMoosaSarConfigTag>>((acc, tag) => {
      acc[tag.columnId] = tag;

      return acc;
    }, {});
  }, [columns]);

  const options = useMemo(() => {
    return availableColumns
      .filter((column) => !tagsMap[column])
      .map((columnId, index) => {
        const label = columnLabelMap?.[columnId]?.label || columnId;

        return (
          <Select.Option key={index} value={columnId}>
            {label}
          </Select.Option>
        );
      });
  }, [availableColumns, columnLabelMap, tagsMap]);

  const addNewTag = useCallback(
    (value: string) => {
      const newTag = tagBuilder(value);
      const clonedTags = addTagToPanel(
        allowOneProperty ? [] : columns,
        maxTagsInRow,
        newTag
      );

      setInputValue(null);
      onColumnsChange(clonedTags);
    },
    [allowOneProperty, columns, maxTagsInRow, onColumnsChange, tagBuilder]
  );

  const tagIds = useMemo(() => {
    return columns.map((row) => row.map((tag) => tag.columnId));
  }, [columns]);

  const onTagReorder = useCallback(
    (tagsPanel: TagsPanel) => {
      const newTags: IMoosaSarConfigTag[][] = tagsPanel.map((row) =>
        row.map((tagId) => {
          const tag = tagsMap[tagId];

          if (!tag) {
            throw new Error(`tag not found: ${tagId}`);
          }

          return tag;
        })
      );

      onColumnsChange(newTags);
    },
    [onColumnsChange, tagsMap]
  );

  const onTagChange = useCallback(
    (tag: IMoosaSarConfigTag) => {
      const newTags = columns.map((row) => {
        const tagIndex = row.findIndex((el) => el.columnId === tag.columnId);

        if (tagIndex === -1) {
          return row;
        }
        const newRow = [...row];

        newRow[tagIndex] = tag;

        return newRow;
      });

      onColumnsChange(newTags);
    },
    [onColumnsChange, columns]
  );

  const singleTag = columns[0]?.[0];

  const deleteSingleTag = useCallback(() => {
    onColumnsChange([]);
  }, [onColumnsChange]);

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{title ?? 'Parameters'}</h3>
      <Select
        className={styles.tagSelect}
        disabled={!options.length}
        filterOption={true}
        placeholder={options?.length ? 'Add a field' : 'All fields added'}
        showArrow={true}
        showSearch
        value={inputValue}
        onChange={setInputValue}
        onSelect={addNewTag}
      >
        {options}
      </Select>
      {!allowOneProperty && (
        <MoosaSarConfigTagContextProvider
          columnLabelMap={columnLabelMap}
          tagsMap={tagsMap}
          onTagChange={onTagChange}
        >
          <DraggableTags
            className={styles.tagsContainer}
            maxTagsInRow={maxTagsInRow}
            tagRenderer={MoosaSarConfigTagRenderer}
            tags={tagIds}
            onChange={onTagReorder}
          />
        </MoosaSarConfigTagContextProvider>
      )}
      {allowOneProperty && singleTag && (
        <MoosaSarConfigTagPure
          className={styles.tagsContainer}
          columnLabelMap={columnLabelMap}
          tag={singleTag}
          onChange={onTagChange}
          onTagDelete={deleteSingleTag}
        />
      )}
    </div>
  );
};
