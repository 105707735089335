import { SignalFilled, WarningFilled } from '@ant-design/icons';
import { IStructureRenderer, IColumnLabelMap } from '@discngine/moosa-models';
import { Card, Tag, Alert } from 'antd';
import React, { FC, useState } from 'react';

import { MMPChartBase } from '../../MoosaMMPChart/MMPChartBase/MMPChartBase';
import { Sorting, IGlobalSorting, GlobalSorting } from '../../MoosaMMPChartConfig';
import {
  MMPChartCentralMolecule,
  MMPChartBaseMolecule,
  MMPChartHoveredMolecule,
} from '../../types';

import styles from './ChartMolecule.module.less';

export interface ChartMoleculeProps {
  StructureRenderer: IStructureRenderer;
  centralMolecule: MMPChartCentralMolecule;
  columnLabelMap: IColumnLabelMap;
  numberOfElements: number;
  radialMolecules: Array<MMPChartBaseMolecule>;
  sorting: Sorting;
  globalSorting: IGlobalSorting;
  parameter: string;
  invertAxes?: boolean;
  hoveredMoleculeId: string;
  getColorString: (affection: number) => string;
  onChangeSortingM: (parameterId: string, sorting: Sorting) => void;
  onHoverSector: (hoveredMolecule: MMPChartHoveredMolecule) => void;
}

const { Meta } = Card;

export const ChartMolecule: FC<ChartMoleculeProps> = ({
  StructureRenderer,
  centralMolecule,
  columnLabelMap,
  numberOfElements,
  radialMolecules,
  sorting,
  globalSorting,
  parameter,
  invertAxes,
  hoveredMoleculeId,
  getColorString,
  onChangeSortingM,
  onHoverSector,
}) => {
  const [showSorting, setShowSorting] = useState(false);

  const parameterSortingMainParameter =
    globalSorting.type === GlobalSorting.PARAMETER &&
    globalSorting.mainParameter === parameter;

  const alertMessage = centralMolecule.structure
    ? 'Molecule has no value for this parameter'
    : 'Please add molecule to compare with';

  const alertType = centralMolecule.structure ? 'error' : 'info';

  return (
    <div>
      <Card
        className={styles.customCard}
        size="small"
        onMouseEnter={() => setShowSorting(true)}
        onMouseLeave={() => setShowSorting(false)}
      >
        <Meta className={styles.customTitle} title={columnLabelMap[parameter].label} />
        {centralMolecule.initialValue && (
          <>
            {(showSorting ||
              parameterSortingMainParameter ||
              globalSorting.type === GlobalSorting.INDEPENDENT) && (
              <div className={styles.sortingIcon}>
                {parameterSortingMainParameter && <Tag color="warning">sorted by</Tag>}
                <span
                  className={styles.icons}
                  onMouseDown={() => onChangeSortingM(parameter, sorting ? 0 : 1)}
                >
                  {!parameterSortingMainParameter &&
                    globalSorting.type === GlobalSorting.PARAMETER && (
                      <SignalFilled
                        className={styles.notSorted}
                        rotate={sorting ? 90 : 270}
                      />
                    )}
                  {(globalSorting.type === GlobalSorting.INDEPENDENT ||
                    parameterSortingMainParameter) &&
                    sorting === Sorting.ASC && (
                      <SignalFilled className={styles.sorted} rotate={90} />
                    )}
                  {(globalSorting.type === GlobalSorting.INDEPENDENT ||
                    parameterSortingMainParameter) &&
                    sorting === Sorting.DESC && (
                      <SignalFilled className={styles.sorted} rotate={270} />
                    )}
                </span>
              </div>
            )}
            <MMPChartBase
              centralMolecule={centralMolecule}
              chartMolecules={radialMolecules}
              disablePopup
              getColorString={getColorString}
              hoveredMoleculeId={hoveredMoleculeId}
              invertAxes={invertAxes}
              numberOfElements={numberOfElements}
              parameter={parameter}
              StructureRenderer={StructureRenderer}
              onHoverSector={onHoverSector}
            />
          </>
        )}
        {!centralMolecule.initialValue && (
          <Alert
            className={styles.customAlert}
            icon={<WarningFilled />}
            message={alertMessage}
            showIcon
            type={alertType}
          />
        )}
      </Card>
    </div>
  );
};
