import { Molfile, SarMatrixCellView } from '@discngine/moosa-models';
import { Space } from 'antd';
import React from 'react';

import styles from './MoosaSarMatrixConfig.module.less';
import { SarMatrixAxisSorter } from './SarMatrixAxisSorter/SarMatrixAxisSorter';
import { SarMatrixCellViewModeSelector } from './SarMatrixCellViewModeSelector/SarMatrixCellViewModeSelector';
import {
  CircleControlProps,
  SarMatrixCircleControls,
  SarMatrixScoringControls,
  ScoringControlProps,
} from './SarMatrixControls';
import { SarMatrixCoreSelectionModal } from './SarMatrixCoreSelectionModal/SarMatrixCoreSelectionModal';
import {
  PinnedListProps,
  SarMatrixPinnedList,
} from './SarMatrixPinnedList/SarMatrixPinnedList';

interface AxisProps {
  /**
   * Order in which the axis values are sorted
   * */
  sortOrder: 'asc' | 'desc';

  /**
   * List of possible axis values
   * */
  options: string[];

  /**
   * Selected option
   * */
  value: string;

  /**
   * @param {string} option that is selected. To update current value
   * */
  onChange: (value: string) => void;

  /**
   * @param {'asc'|'desc'} order to update sortOrder value
   * */
  onOrderChange: (order: 'asc' | 'desc') => void;
}

export interface MoosaSarMatrixConfigProps {
  /**
   * @see PinnedListProps
   * */
  lockedListProps: PinnedListProps;
  /**
   * @see AxisProps
   * */
  xAxisProps: AxisProps;

  /**
   * @see AxisProps
   * */
  yAxisProps: AxisProps;
  cellViewProps: {
    /**
     * Defines current cell renderer type
     * */
    value: SarMatrixCellView;

    /**
     * @param {SarMatrixCellView} cellView - new cell renderer type value
     * */
    onChange: (cellView: SarMatrixCellView) => void;
  };

  /**
   * @see CircleControlProps
   * */
  circleControlProps: CircleControlProps;

  /**
   * @see ScoringControlProps
   * */
  scoringFieldsControlProps: ScoringControlProps;
  coreSelectorModalProps: {
    /**
     * Available cores list
     * */
    options: { structure: Molfile; moleculesCount: number }[];

    /**
     * Selected core
     * */
    value: Molfile;

    /**
     * Handler to update selected core
     * */
    onChange: (core: Molfile) => void;
  };
}

export const MoosaSarMatrixConfig = ({
  lockedListProps,
  xAxisProps,
  yAxisProps,
  cellViewProps,
  circleControlProps,
  scoringFieldsControlProps,
  coreSelectorModalProps,
}: MoosaSarMatrixConfigProps) => {
  return (
    <div className={styles.root}>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        {lockedListProps.list.length > 0 && (
          <div className={styles.selectorGroup}>
            <h3 className={styles.title}>Pinned</h3>
            <SarMatrixPinnedList {...lockedListProps} />
          </div>
        )}

        <div className={styles.selectorGroup}>
          <h3 className={styles.title}>Axis X:</h3>
          <SarMatrixAxisSorter
            caption={'order by'}
            order={xAxisProps.sortOrder}
            properties={xAxisProps.options}
            property={xAxisProps.value}
            onSelectOrder={xAxisProps.onOrderChange}
            onSelectProperty={xAxisProps.onChange}
          />
        </div>

        <div className={styles.selectorGroup}>
          <h3 className={styles.title}>Axis Y:</h3>
          <SarMatrixAxisSorter
            caption={'order by'}
            order={yAxisProps.sortOrder}
            properties={yAxisProps.options}
            property={yAxisProps.value}
            onSelectOrder={yAxisProps.onOrderChange}
            onSelectProperty={yAxisProps.onChange}
          />
        </div>

        <div className={styles.viewMode}>
          <SarMatrixCellViewModeSelector
            cellView={cellViewProps.value}
            onChangeCellView={cellViewProps.onChange}
          />
        </div>

        {cellViewProps.value === SarMatrixCellView.Circles && (
          <SarMatrixCircleControls {...circleControlProps} />
        )}

        {cellViewProps.value === SarMatrixCellView.FactorsAbsolute && (
          <SarMatrixScoringControls {...scoringFieldsControlProps} />
        )}
      </Space>

      <SarMatrixCoreSelectionModal
        cores={coreSelectorModalProps.options}
        currentCore={coreSelectorModalProps.value}
        setCore={coreSelectorModalProps.onChange}
      />
    </div>
  );
};
