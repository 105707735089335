function generateAxe(maxValue: number, step: number): number[] {
  const ticks = [];

  for (let i = step; i <= maxValue || i - maxValue < step; i += step) {
    ticks.push(i);
  }

  return ticks;
}

export function calculateHistogramTicks(maxValue: number): number[] {
  if (maxValue <= 5) {
    return generateAxe(maxValue, 1);
  } else if (maxValue <= 10) {
    return generateAxe(maxValue, 2);
  } else if (maxValue <= 25) {
    return generateAxe(maxValue, 5);
  } else if (maxValue <= 50) {
    return generateAxe(maxValue, 10);
  } else if (maxValue <= 100) {
    return generateAxe(maxValue, 20);
  } else if (maxValue <= 250) {
    return generateAxe(maxValue, 50);
  } else if (maxValue <= 500) {
    return generateAxe(maxValue, 100);
  } else if (maxValue <= 1000) {
    return generateAxe(maxValue, 200);
  } else if (maxValue <= 2500) {
    return generateAxe(maxValue, 500);
  } else if (maxValue <= 5000) {
    return generateAxe(maxValue, 1000);
  } else if (maxValue <= 30000) {
    return generateAxe(maxValue, 5000);
  }

  return generateAxe(maxValue, 10000);
}
