import { IColumnMetaInfo, IScoreMap, ITableDataRowMap } from '@discngine/moosa-models';

import { getAverage, getDeviation } from '../helpers';
import { ScoringItem } from '../types';

const NO_VALUE = 'N/A';

interface AbsoluteValuesBarChartBuilderParams {
  columnsFactorsAbsolute: ScoringItem[];
  rawDataset: ITableDataRowMap;
  substanceId: string;
  columnsMap: Record<string, IColumnMetaInfo>;
  substances: string[];
  isAverageMode?: boolean;
  scoredDataset: IScoreMap;
  columnIndices: Record<string, number>;
}

export const absoluteValuesBarChartBuilder = ({
  columnsFactorsAbsolute,
  rawDataset,
  substanceId,
  columnsMap,
  substances,
  isAverageMode,
  scoredDataset,
  columnIndices,
}: AbsoluteValuesBarChartBuilderParams) => {
  return columnsFactorsAbsolute.map((row) => {
    const colId = row.columnId;
    const factorMax = columnsMap[colId]?.statistics?.max || 0;
    const factorMin = columnsMap[colId]?.statistics?.min || 0;

    if (isAverageMode) {
      const values = substances
        .map((id) => {
          return (
            rawDataset[id][colId] ??
            scoredDataset[id]?.rawValues[columnIndices[colId]] ??
            scoredDataset[substanceId].value
          );
        })
        .filter((value): value is number => typeof value === 'number');

      const average = getAverage(values);

      return {
        colId,
        color: row.color,
        factor: average,
        factorMin,
        factorMax,
        deviation: getDeviation(values),
        label: values.length > 0 ? average : NO_VALUE,
      };
    }

    const rawValue = scoredDataset[substanceId]?.rawValues[columnIndices[colId]];
    const scoringValue = scoredDataset[substanceId].value;
    const value = rawDataset[substanceId]?.[colId] ?? rawValue ?? scoringValue;

    const rawFactor = Number(value);

    const factor = Number(rawFactor || 0);

    return {
      colId,
      color: row.color,
      factor: factor,
      factorMin,
      factorMax,
      deviation: 0,
      label: value ?? NO_VALUE,
    };
  });
};
