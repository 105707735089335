import { ChemoinformaticsJobError } from '../types';
import { poll } from '../utils/utils';

import { ICalculationJobResponseBody } from './ICalculationJobResponseBody';
import { ICalculationResultParams } from './ICalculationResultParams';
import { IJobApiDataService } from './IJobApiDataService';
import { IJobResponse } from './IJobResponse';

/**
 * @class JobApiDataService<P, R>
 * @implements IJobApiDataService<P, R>
 * @template P interface used for Job creation payload
 * @template R interface used for Job response data
 */
export class JobApiDataService<P, R> implements IJobApiDataService<P, R> {
  protected baseUrl: string;

  /**
   * @constructs
   * @param host server url
   * @param apiVersion API version
   * @param entityName
   * path name of the entity (should be defined in the child class)
   * @throws Error! Please specify entityName
   */
  constructor(
    protected host: string,
    protected apiVersion: string,
    protected entityName?: string
  ) {
    if (!this.entityName) {
      throw new Error('[JobApiDataService class]: Error! Please specify entityName');
    }
    this.apiVersion = apiVersion || 'v1';
    this.baseUrl = `${this.host}/api/${this.apiVersion}/async/${this.entityName}/`;
  }

  /**
   * Get Calculation Job result
   * @param params ICalculationResultParams
   * @returns Promise<IJobResponse<R>>
   */
  getJob(params: ICalculationResultParams): Promise<IJobResponse<R>> {
    const url = `${this.baseUrl}${params.job_id}/results`;

    return fetch(url).then((response) => response.json());
  }

  /**
   * Polling Calculation Job result until it has meaningful result
   * @param params ICalculationResultParams
   * @param maxAttempts number
   * @param interval number
   * @param signal AbortSignal
   * @returns Promise<R>
   */
  pollJob(
    params: ICalculationResultParams,
    maxAttempts: number = 0,
    interval: number = 1000,
    signal: AbortSignal
  ): Promise<R> {
    const pollingResult = poll<IJobResponse<R>>({
      cb: () => {
        return this.getJob(params);
      },
      interval,
      maxAttempts,
      predicate: (result) => {
        return !['started', 'queued'].includes(result.job_status);
      },
      signal: signal,
    });

    return new Promise<R>((resolve, reject) => {
      pollingResult
        .then((response) => {
          if (response && response.job_status === 'finished') {
            resolve(response.results);
          } else {
            reject(ChemoinformaticsJobError.JobFailed);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Adds Calculation JOB into the queue
   * @param payload JOB calculation payload
   * @param params JOB calculation params
   * @param entityPostfix
   * URL part. example 'regression/' (please wrap this method in the child class)
   * @example
   * createMMPJob(payload, params) {
   *   return this.createJob(payload, params, 'mmp_job/');
   * }
   * @returns `Promise<ICalculationJobResponseBody>`
   */
  createJob(
    payload: P,
    params: Record<string, unknown>,
    entityPostfix: string = ''
  ): Promise<ICalculationJobResponseBody> {
    let url = this.baseUrl;

    if (entityPostfix) {
      const postfix = entityPostfix.startsWith('/')
        ? entityPostfix.slice(1)
        : entityPostfix;

      url = `${this.baseUrl}${postfix}`;
    }

    return fetch(url + '?' + new URLSearchParams(JSON.stringify(params)), {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then((response) => response.json());
  }

  /**
   * Delete Calculation Job from the queue
   * @param jobId string
   * @returns string
   */
  deleteJob(jobId: string): Promise<string> {
    if (!jobId) {
      return Promise.reject('Empty jobId passed');
    }

    const url = `${this.baseUrl}${jobId}`;

    return fetch(url, {
      method: 'DELETE',
    }).then((response) => response.json());
  }
}
