import { ILineDiscretePoint } from '../uiModels/moosa-store/scoringTemplate';

export interface DesirabilityFunctionMissingValue {
  replaceWith?: number | null;
  replaceWithY?: number | null;
  replaceWithColumn?: string;
}

export interface DesirabilityFunctionFilter {
  from?: number;
  to?: number;
}

export type NumericMeasurementErrorType = 'absolute' | 'relative';
export type RefernceMeasurementErrorType = 'column';
export type NoneMeasurementErrorType = 'none';
export interface NumericMeasurementError {
  type: NumericMeasurementErrorType;
  value: number;
}
export interface ReferenceMeasurementError {
  type: RefernceMeasurementErrorType;
  value: string;
}
export interface NoneMeasurementError {
  type: NoneMeasurementErrorType;
}
export type MeasurementError =
  | NumericMeasurementError
  | ReferenceMeasurementError
  | NoneMeasurementError;

export interface IScoringTemplateItem {
  columnName: string;
  color: string;
  isVisible: boolean;
  weight: number;
  desirability: Omit<IDesirabilityFunction, '_id'>;
  filter?: DesirabilityFunctionFilter;
  missingValue?: DesirabilityFunctionMissingValue;
  measurementError?: MeasurementError;
  isDiscreteStringFunction?: boolean;
}

export interface IScoringTemplateRequest {
  name?: string;
  columns?: IScoringTemplateItem[];
  parentId?: string;
  description?: string;
  versionName?: string;
  deletedAt?: Date | null;
}

export interface IScoringTemplate extends IScoringTemplateRequest {
  name: string;
  _id: string;
  columns: IScoringTemplateItem[];
}

export type IScoringTemplateNew = Omit<IScoringTemplate, '_id'> & { _id?: string };

export interface IScoringTemplates {
  mainTemplate: IScoringTemplateRequest;
  templateForComparison?: IScoringTemplateRequest;
}

export interface IScoringCalculationRequest {
  limit?: number;
  skip?: number;
  sort?: Record<string, 1 | -1>;
  select?: string[]; // list of columns to return, if null - return all
  templates: IScoringTemplates;
  includeRows: string[];
}

export enum DesirabilityFunctionType {
  linear = 'linear',
  unitStep = 'unitStep',
  rectangular = 'rectangular',
  triangular = 'triangular',
  logarithmic = 'logarithmic',
  sigmoid = 'sigmoid',
  custom = 'custom',
  discrete = 'discrete',
}

export interface IPoint2D {
  x: number | string;
  y: number;
}

export interface IDesirabilityFunction {
  _id: string;
  points: IPoint2D[] | ILineDiscretePoint[];
  type: DesirabilityFunctionType;
  name?: string;
  isDiscreteStringFunction?: boolean;
}

export interface ScoringRequestItem {
  weight: number;
  desirability: string; // (number[]).join(',')
  missingValue?: DesirabilityFunctionMissingValue;
}

export type ScoringRequest = Record<string, ScoringRequestItem>;
