import React, { FC } from 'react';

import styles from './sector.module.less';

const { cos, sin } = Math;

export interface ISectorSVGProps {
  order: number;
  smallRadius: number;
  bigRadius: number;
  angle: number;
  fillColor: string;
  fillOpacity: number;
  onEnterSector: () => void;
  onLeaveSector: () => void;
}

export const SectorSVG: FC<ISectorSVGProps> = ({
  order,
  smallRadius,
  bigRadius,
  angle,
  fillColor,
  fillOpacity,
  onEnterSector,
  onLeaveSector,
}: ISectorSVGProps) => {
  const angleDelta = 0;
  const angleStart = order * angle + angleDelta;
  const angleEnd = (order + 1) * angle - angleDelta;

  let smallR = smallRadius;
  let bigR = bigRadius;

  return (
    <path
      key={order}
      className={styles.sector}
      d={`M${smallR * cos(angleStart)} ${smallR * sin(angleStart)}
        L${bigR * cos(angleStart)} ${bigR * sin(angleStart)}
        A ${bigR} ${bigR} 0 0 1 ${bigR * cos(angleEnd)} ${bigR * sin(angleEnd)}
        L ${bigR * cos(angleEnd)} ${bigR * sin(angleEnd)} ${
        smallR * cos(angleEnd + angleDelta)
      } ${smallR * sin(angleEnd + angleDelta)}
        A ${smallR} ${smallR} 0 0 0 ${smallR * cos(angleStart)} ${
        smallR * sin(angleStart)
      }
        Z`}
      fill={`${fillColor}`}
      fillOpacity={fillOpacity}
      stroke={`white`}
      strokeWidth={2}
      onMouseEnter={onEnterSector}
      onMouseLeave={onLeaveSector}
    ></path>
  );
};
