import { IColumnLabelMap } from '@discngine/moosa-models';
import { createContext, useCallback, useContext } from 'react';

import { ColorPickerPure } from '../../ColorPickerPure/ColorPickerPure';
import { TagId, TagRenderer, TagWithCaption } from '../../DraggableTags';

export interface IMoosaColorTagContext {
  columnLabelMap?: IColumnLabelMap;
  onColorChange: (tag: TagId, color: string) => void;
  tagColorMap: Record<TagId, string>;
}

export const MoosaColorTagContext = createContext<IMoosaColorTagContext | null>(null);

const useMoosaColorTagContext = (): IMoosaColorTagContext => {
  const context = useContext(MoosaColorTagContext);

  if (!context) {
    throw new Error('MoosaSarConfigTagContextProvider is not defined');
  }

  return context;
};

export const MoosaColorTagRenderer: TagRenderer = ({ className, tagId, onRemove }) => {
  const { tagColorMap, onColorChange, columnLabelMap } = useMoosaColorTagContext();

  const color = tagColorMap[tagId];

  const label = columnLabelMap?.[tagId]?.label;

  const changeColor = useCallback(
    (colorCode: string) => {
      onColorChange(tagId, colorCode);
    },
    [onColorChange, tagId]
  );

  return (
    <TagWithCaption
      className={className}
      tagId={tagId}
      tagLabel={label}
      onRemove={onRemove}
    >
      <div style={{ marginRight: '10px' }}>
        <ColorPickerPure color={color} onColorChange={changeColor} />
      </div>
    </TagWithCaption>
  );
};
