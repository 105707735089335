import { MMPView } from '@discngine/moosa-models';
import { Select } from 'antd';
import React, { FC } from 'react';

import styles from './ViewModeSelector.module.less';

export interface IViewModeSelectorProps {
  viewMode: MMPView;
  onSelectViewMode: (mode: MMPView) => void;
}

export const ViewModeSelector: FC<IViewModeSelectorProps> = ({
  viewMode,
  onSelectViewMode,
}) => {
  return (
    <>
      <h3 className={styles.title}>View mode</h3>
      <Select
        defaultValue={MMPView.RadiantChart}
        value={viewMode}
        onSelect={onSelectViewMode}
      >
        <Select.Option key={MMPView.Table} value={MMPView.Table}>
          Table
        </Select.Option>
        <Select.Option key={MMPView.RadiantChart} value={MMPView.RadiantChart}>
          Radiant Chart
        </Select.Option>
        <Select.Option
          key={MMPView.MultipleRadiantChart}
          value={MMPView.MultipleRadiantChart}
        >
          Multiple Radiant Chart
        </Select.Option>
      </Select>
    </>
  );
};
