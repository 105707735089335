import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback } from 'react';

import styles from './Header.module.less';

export type IHeaderProps = {
  willSynchronizeColumnsWithTemplate: boolean;
  onChangeTemplateColumns: (checked: boolean) => void;
};

export const Header: React.FC<IHeaderProps> = ({
  willSynchronizeColumnsWithTemplate,
  onChangeTemplateColumns,
}) => {
  const onChange = useCallback(
    (event: CheckboxChangeEvent) => {
      onChangeTemplateColumns(event.target.checked);
    },
    [onChangeTemplateColumns]
  );

  return (
    <div className={styles.switch}>
      <Checkbox checked={willSynchronizeColumnsWithTemplate} onChange={onChange}>
        Synchronize columns
      </Checkbox>
    </div>
  );
};
